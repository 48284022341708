import React, { useState,useRef} from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";

import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import {
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    WhatsappIcon
  } from "react-share";

  function ShareComponent(props) {
    const [show, setShow] = useState(false);
    const [copied, setCopied] = useState(false);

    const target = useRef(null);

    const s = window.location.href;
    const shareUrl = s.replace("/session/","/s/session/").replace("/league/","/s/league/");
    const relatedtw = ["emln_22","racemapper"];
    const hashtags = ["#F122game ","racemapper"];
    const fbhashtags = "#F122game  #racemapper";

    return (
        <>
        <Button key="share-dl" variant={props.variant || "outline-dark" } ref={target} onClick={() => setShow(!show)}>
            <img alt="fs" src="/static/share.svg" style={{width: 20}} />
        </Button>
        <Overlay target={target.current} show={show} placement={props.placement || 'right'}>
            <Tooltip id="overlay-share" target={target.current} show={show} placement={props.placement || 'right'}>
                <CopyToClipboard text={shareUrl} onCopy={() => {setCopied(true); setShow(false);}} title="Copy link"  style={{height: 30,width: 30,backgroundColor: '#fff',borderRadius: '25px'}} >
                    <Button size='sm' variant='outline-dark'>
                        <img alt="copy" src={!copied ? '/static/icons/clipboard.svg' : '/static/icons/clipboard-check.svg'} style={{width: 18,  marginLeft: '-3px', marginTop:'-2px'}} />
                    </Button>
                </CopyToClipboard>
                &nbsp;
                <TwitterShareButton url={shareUrl} title={props.title} via={props.twitterHandler} hashtags={hashtags} related={relatedtw}><TwitterIcon size={30} round /></TwitterShareButton>
                &nbsp;
                <FacebookShareButton url={shareUrl} quote={props.title} hashtags={fbhashtags}><FacebookIcon size={30} round /></FacebookShareButton>
                &nbsp;
                <RedditShareButton url={shareUrl} title={props.title}><RedditIcon size={30} round /></RedditShareButton>
                &nbsp;
                <TelegramShareButton url={shareUrl} title={props.title}><TelegramIcon size={30} round /></TelegramShareButton>
                &nbsp;
                <WhatsappShareButton url={shareUrl} title={props.title}><WhatsappIcon size={30} round /></WhatsappShareButton>
            </Tooltip>
        </Overlay>
        </>
    );
  }

  export default ShareComponent;