import React, { Component } from 'react';
import {lapTimeFormat, timeDistanceFormat, driverFormat, teamFormat} from '../helpers/format';
import {RESULT_STATUS,TYRE_VISUAL_IMG,TYRE_VISUAL} from 'racemapper_shared/enums';
import {pointsForRace} from "racemapper_shared/data";

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';


function lblPenalities(pen) {
  if( pen === 0 || !pen) {
    return "";
  }
  if( pen < 0) {
    return  pen + " s.";
  }
  return  "+" + pen + " s.";
}

function lblResult(resultStatus) {
  if( resultStatus === 3) {
    return "";
  }
  if( resultStatus === 4) {
    return "RT";
  }
  return RESULT_STATUS[resultStatus];
}

function lblDelta(item , winner ) {
    const racetime = item.totalRaceTime + (item.penaltiesTime || 0) + (item.stewardsPenaltiesTime || 0);
    const winnertime = winner.totalRaceTime + (winner.penaltiesTime || 0)  + (winner.stewardsPenaltiesTime || 0);

    if( item.position === 1) {
        return lapTimeFormat(racetime * 1000);
    }

    if( item.numLaps === winner.numLaps) {
      return "+" + timeDistanceFormat( racetime - winnertime);
    } else {
        if( item.resultStatus === 4 ) {
          return "DNF";
        } else if( item.resultStatus === 5 ) {
          return "DSQ";
        } else if( item.resultStatus === 6 ) {
          return "NC";
        } else if( item.resultStatus === 7 ) {
          return "RT"
        } 
        return "+"+ (winner.numLaps - item.numLaps) + " laps";
    }  
  }


class RaceResult extends Component {
    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
      this.fastest = this.props.result.filter( r => r.bestLapTime > 0).reduce( (a, b) => Math.min(a, b.bestLapTime),this.props.result[0].bestLapTime);    
      this.session = this.props.session;
      this.isSprint =  this.props.result[0].points === 8;
    }

    hasStwd = () => {
      const topen =  this.props.result.reduce( (accum, el) => accum + (el.stewardsPenaltiesTime || 0),0);
      return topen > 0;
    }

    winner = () => {
      return this.props.result.filter( el => el.position === 1)[0];
    }
  
    lblPoints = (item) => {
      let points = 0;
      if( this.session.points && !this.isSprint) {
        if( this.props.sessiontype > 10) { // if it' a race2  I've already added pole points to sprint race
          this.session.points.poleposition = 0;
        }
        points = pointsForRace( item, this.session.points, this.fastest, null);
      } else {
        points = item.points;
      }
      if( points === 0) return '';
      return points;
    }

    render() {
      return(
<Table striped hover size="sm" className="racetab">
  <thead>
    <tr key={`lp-driver-header`}>
      <th className="positionCell"></th>
      <th>DRIVER</th>
      <th className="d-none d-lg-table-cell">TEAM</th>
      <th className="d-none d-lg-table-cell">LAPS</th>
      <th className="right-align">TIME</th>
      <th className="right-align">PEN.</th>
      {this.hasStwd() && 
        <th className="right-align"><Button variant="link" onClick={this.props.openSteward}>STW.</Button></th>
      }
      <th>BEST</th>
      <th>Q</th>
      <th>STINT</th>
      <th>POINTS</th>
    </tr>
  </thead>
  <tbody>
{ this.props.result && 
      this.props.result
      .filter( r => r.resultStatus > 1)
      .map((item,driver) => { item.driver = driver; return item;})
      .sort((a, b) => a.position - b.position)
      .map( item => (
    <tr key={`driver-${item.driver}`}>
      <td key={`driver-${item.driver}-pos`}>{item.position} {lblResult(item.resultStatus)}</td>
      <td key={`driver-${item.driver}-name`}>
        <Button key={`driver-${item.driver}-namebtn`} variant="link" onClick={this.props.onPress(item.driver)}>{driverFormat(this.props.partecipants[item.driver])}</Button>
      </td>
      <td key={`driver-${item.driver}-team`} className="d-none d-lg-table-cell">{teamFormat(this.props.partecipants[item.driver].teamId)}</td>
      <td key={`driver-${item.driver}-laps`} className="d-none d-lg-table-cell centerTxt">{item.numLaps}</td>
      <td key={`driver-${item.driver}-rt`} className="right-align">{lblDelta(item, this.winner())}</td>
      <td key={`driver-${item.driver}-pen`} className="right-align cell-small">{lblPenalities(item.penaltiesTime)}</td>
      {this.hasStwd() && 
        <td key={`driver-${item.driver}-stw`} className="right-align cell-small">{lblPenalities(item.stewardsPenaltiesTime)}</td>
      }
      <td key={`driver-${item.driver}-lt`} className={`${item.bestLapTime === this.fastest ? "fastest" : ""} lp-bestlap centerTxt`}>{lapTimeFormat(item.bestLapTime)}</td>
      <td key={`driver-${item.driver}-q`} className="centerTxt">{item.gridPosition}</td>
      <td key={`driver-${item.driver}-tyres`}>{item.tyreStintsVisual.filter(t => t !== 0).map( (t,i) => ( <img className="tyre" key={`${item.position}-${i}`} src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[t]}.png`} alt={TYRE_VISUAL[t]} width="20" height="20" />))}</td>
      <td key={`driver-${item.driver}-points`} className="centerTxt">{this.lblPoints(item)}</td>
    </tr>
    ))
}

  </tbody>
</Table>
);
    }
  }
  
  export default RaceResult;