import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Header from '../components/Header';
import Footer from '../components/Footer';
import RaceHeader from '../components/RaceHeader';
import RaceLog from '../components/RaceLog';
import RaceQResult from '../components/RaceQResult';
import LapModal from '../components/LapModal';
import RaceQLapData from '../components/RaceQLapData';
import SpeedTrap from '../components/SpeedTrap';
import QperformanceChart from '../components/QperformanceChart';

import { TRACK , SESSION_TYPE } from 'racemapper_shared/enums'
import { isLeagueAdmin } from '../helpers/auth';

import { db } from '../services/firebase';
import { COLLECTION_ID, SESSIONS_COLLECTION,LOGS_COLLECTION,SPEEDTRAP_COLLECTION, getDBLaps, sessionFastestData, lapsForModal, mapDriversLabel , deleteRaceData } from 'racemapper_shared/data';
import { canDeleteSession } from '../helpers/raceUtils';

import ShareComponent from '../components/ShareComponent';

let partecipantsMapper = [];
let lapsForDriver = {};

const Qualification = props => {

    const [session, setSession] = React.useState([]);
    const [race, setRace] = React.useState([]);
    const [logs, setLogs] = React.useState([]);
    const [laps, setLaps] = React.useState([]);
    const [speedtrapdata, setSpeedtrapdata] = React.useState([]);
    const [speedtrapAlldata, setSpeedtrapAlldata] = React.useState([]);

    
    const [modalLogs, setModalLogs] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);

    const sessionRaceID = props.match.params.sessionID;
    const raceID = props.match.params.raceID;

    function formatRace( doc, sessionPartecipants) {
        doc.fastest = sessionFastestData( doc);
        const entries = Array.isArray( doc.lapData) ? doc.lapData : Object.values(doc.lapData);

        entries.map( (lap, driver) => {
            // or box
            if( !lapsForDriver[driver] || lap.driverStatus === 0 || lap.driverStatus === 2) {
                lapsForDriver[driver] = [];
            }
            if( !lapsForDriver[driver][lap.currentLapNum]) {
                lapsForDriver[driver][lap.currentLapNum] = {};
            }

            lap.sector3TimeInMS = Number.MAX_SAFE_INTEGER;

            let s1class = "";
            let s2class = "";
            let s3class = "";
    
            let s1offset = "";
            let s2offset = "";
            let s3offset = "";
    
            lap.lapOffset = "";
            // we need to calculate s3

            if( !lap.sector1TimeInMS && 
                !lap.sector2TimeInMS && 
                lapsForDriver[driver][lap.currentLapNum-1] &&
                lap.lastLapTimeInMS &&
                (lap.driverStatus === 1 || lap.driverStatus === 4)
                ) {
                const k = lap.currentLapNum-1;
                lap.sector1TimeInMS = lapsForDriver[driver][k]['s1'];
                lap.sector2TimeInMS = lapsForDriver[driver][k]['s2'];
                s1offset = lapsForDriver[driver][k]['s1offset'];
                s2offset = lapsForDriver[driver][k]['s2offset'];
                
                lap.sector3TimeInMS = lap.lastLapTimeInMS - (lap.sector1TimeInMS + lap.sector2TimeInMS);
                
                s1class = lapsForDriver[driver][k]['s1class'];
                s2class = lapsForDriver[driver][k]['s2class'];

                s3offset =  lap.sector3TimeInMS - doc.fastest.s3;
                if( lap.currentLapInvalid) {
                    s3class = "invalidated";
                } else if( s3offset < 0) {
                    s3class = "fastest";
                } else  if( lap.sector3TimeInMS < lap.bestLapSector3TimeInMS || lap.bestLapSector3TimeInMS) {
                    s3class = "faster";
                } else {
                    s3class = "normal";
                }
            } else if( lap.driverStatus === 1 ||  lap.driverStatus === 4) {
                if( lap.sector1TimeInMS ) {
                    s1offset =  lap.sector1TimeInMS - doc.fastest.s1;
                    if( lap.currentLapInvalid) {
                        s1class = "invalidated";
                    } else if( s1offset <= 0) {
                        s1class = "fastest";
                        if( s1offset === 0) {
                            // let's pick second fastets as reference
                            let reftime = Number.MAX_SAFE_INTEGER;
                            entries.forEach( (lap,vehicleIdx) => {
                                if( vehicleIdx !== driver 
                                    && lap.bestLapSector1TimeInMS > 0
                                    && reftime >= lap.bestLapSector1TimeInMS) {
                                    reftime = lap.bestLapSector1TimeInMS;
                                }
                            });
                            if(reftime !== Number.MAX_SAFE_INTEGER) {
                                s1offset = lap.sector1TimeInMS - reftime;
                            } else {
                                s1offset = 0;
                            }
                        }
                    } else if( lap.sector1TimeInMS <= lap.bestLapSector1TimeInMS || !lap.bestLapSector1TimeInMS) {
                        s1class = "faster";
                    } else {
                        s1class = "normal";
                    }
                    lapsForDriver[driver][lap.currentLapNum]['s1'] = lap.sector1TimeInMS;
                    lapsForDriver[driver][lap.currentLapNum]['s1offset'] = s1offset;
                    lapsForDriver[driver][lap.currentLapNum]['s1class'] = s1class;
                }

                if( lap.sector2TimeInMS ) {
                    s2offset =  lap.sector2TimeInMS - doc.fastest.s2;
                    if( lap.currentLapInvalid) {
                        s2class = "invalidated";
                    } else if( s2offset <= 0) {
                        s2class = "fastest";
                        if( s2offset === 0) {
                            let reftime = Number.MAX_SAFE_INTEGER;
                            entries.forEach( (lap,vehicleIdx) => {
                                if( vehicleIdx !== driver 
                                    && lap.bestLapSector2TimeInMS > 0
                                    && reftime >= lap.bestLapSector2TimeInMS) {
                                    reftime = lap.bestLapSector2TimeInMS;
                                }
                            });
                            if(reftime !== Number.MAX_SAFE_INTEGER) {
                                s2offset = lap.sector2TimeInMS - reftime;
                            } else {
                                s2offset = 0;
                            }
                        }
                    } else  if( lap.sector2TimeInMS <= lap.bestLapSector2TimeInMS || !lap.bestLapSector2TimeInMS) {
                        s2class = "faster";
                    } else {
                        s2class = "normal";
                    }
                    lapsForDriver[driver][lap.currentLapNum]['s2'] = lap.sector2TimeInMS;
                    lapsForDriver[driver][lap.currentLapNum]['s2offset'] = s2offset;
                    lapsForDriver[driver][lap.currentLapNum]['s2class'] = s2class;
                    lapsForDriver[driver][lap.currentLapNum]['position'] = lap.carPosition;
                }
            }

            lap.driver = driver;
            lap.s1class = s1class;
            lap.s2class = s2class;
            lap.s1offset = s1offset;
            lap.s2offset = s2offset;
            
            lap.s3class = s3class;
            lap.s3offset = s3offset;

            return lap;
        });
    
        if( sessionPartecipants) {
            doc.partecipant = mapDriversLabel( doc.partecipants, sessionPartecipants);
        }

        return doc;
    }

    const deleteRaceSession = () => {
        deleteRaceData( db, sessionRaceID, raceID)
        .then( (_)=> {
            props.history.push('../');
        });
    }

    const openLapHistory = driver => e => {
        setModalShow(true);
        setLaps( []);
        setModalLogs( []);
        const getLaps = async() => {
            try {
                const data = await getDBLaps( db, sessionRaceID, raceID, [driver]);
                const speed = speedtrapdata.find(i => i.vehicleIdx === driver);
                const objLaps = lapsForModal( driver, data, speedtrapAlldata, speed);
                objLaps["fastestOverall"] = sessionFastestData(race);
                objLaps["partecipant"] = race.partecipants[driver];
                objLaps["idx"] = driver;
                setLaps( objLaps);

                const mlogs = logs.filter(i => i.data.vehicleIdx === driver);
                setModalLogs(mlogs);
            } catch( error) {
                console.log(error);
            }
        }
        getLaps();
    }

    useEffect(() => {
        const getSpeedTrap = async( sessionRaceID, raceID) => {
            try {
                let speedref = db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).doc(raceID).collection(SPEEDTRAP_COLLECTION).orderBy("speed","desc");
                speedref.onSnapshot(docSnapshot => {
                    var driverId = {};
                    const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}))
                    const onefordriver = arrayData.filter(function(entry) {
                        if (driverId[entry.vehicleIdx] && driverId[entry.vehicleIdx] > entry.speed) {
                            return false;
                        }
                        driverId[entry.vehicleIdx] = entry.speed;
                        return true;
                    });
                    setSpeedtrapdata( onefordriver);
                    setSpeedtrapAlldata( arrayData);
                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });
            } catch( error) {
                console.log(error);
            }
        }
        const getLogs = async(sessionRaceID, raceID) => {
            try {
                let logsref = db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).doc(raceID).collection(LOGS_COLLECTION).orderBy("sessionTime","desc");
                logsref.onSnapshot(docSnapshot => {
                    const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}));
                    setLogs( arrayData);
                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });
            } catch( error) {
                console.log(error);
            }
        }
        const getRaceData = async(sessionRaceID, raceID) => {
            try {
                let firstLoad = true     
                let raceRef = db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).doc(raceID);
                raceRef.onSnapshot(docSnapshot => {
                    let data = docSnapshot.data();
                    data.lapData = (Array.isArray( data.lapData) ? data.lapData : Object.values(data.lapData));
                    data.partecipants = Array.isArray( data.partecipants) ? data.partecipants : Object.values(data.partecipants);
                    const formattedData = formatRace( data, partecipantsMapper);      
                    setRace( formattedData);
                    if( firstLoad) {
                        getLogs( sessionRaceID, raceID);
                        getSpeedTrap( sessionRaceID, raceID);
                        firstLoad = false;
                    }
                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });   
    
            } catch( error) {
                console.log(error);
            }
        }
        const getSessionData = async(sessionRaceID) => {
            try {
                let firstLoad = true
                let ref = db.collection(COLLECTION_ID).doc(sessionRaceID);
                ref.onSnapshot(docSnapshot => {
                    const d =  docSnapshot.data();
                    partecipantsMapper = d.partecipants;
                    setSession( d);
    
                    if( firstLoad) {
                        getRaceData(sessionRaceID, raceID);
                        firstLoad = false;
                    }
                  }, err => {
                    console.log(`Encountered error: ${err}`);
                }); 
            } catch( error) {
                console.log(error);
            }
        }    
        getSessionData( sessionRaceID);
    }, [sessionRaceID, raceID]);

    return (
        <>
        <Header></Header>
        <main className="bg-light">
        
        <Container fluid className='py-3'>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/session/${sessionRaceID}` }}>{session.label || sessionRaceID}</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {TRACK[race.trackId]} - {SESSION_TYPE[race.sessionType]}
                </Breadcrumb.Item>
            </Breadcrumb>
        </Container>

        <Container>
            <RaceHeader session={session} race={race} weatherForecastSamples={race.weatherForecastSamples} forecast={true} />
            <br />
        </Container>

        <Container fluid>
            { race.classificationData &&
                <Row>
                <Col>
                <h3>Results  &nbsp;&nbsp;
                    { race.banner &&
                    <ButtonGroup size="sm">
                        <Button key="banner-dl" variant="outline-dark" href={race.banner} target="_blank">
                            <img alt="fs" src="/static/file-image.svg" style={{width: 20}} />
                        </Button>
                        <ShareComponent title={session.label || `${TRACK[race.trackId]} - ${SESSION_TYPE[race.sessionType]}`}  twitterHandler={session.twitter || ""} />
                    </ButtonGroup>
                    }
                </h3>
                    <RaceQResult raceID={raceID} result={race.classificationData} blaps={race.blap_stats} lapData={race.lapData} partecipants={race.partecipants} fastestLap={sessionFastestData(race)} onPress={openLapHistory} />
                </Col>
                </Row>
            }
            { race.classificationData &&
                <>
                    <Row>
                        <Col sm={7}>
                            <h3>Performance</h3>
                            <QperformanceChart result={race.classificationData} partecipants={race.partecipants} />
                        </Col>
                        <Col sm={3}>
                        <h3>Speed Trap</h3>
                            <SpeedTrap speedtrap={speedtrapdata} partecipants={race.partecipants} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={7}>
                            <h3>Logs</h3>
                            <RaceLog logs={logs} partecipants={race.partecipants} />
                        </Col>
                    </Row>
                </>
            }

            { race.lapData && !race.classificationData &&
                <Row>
                <Col sm={7}>
                    <h3>Remaining: {Math.trunc(race.sessionTimeLeft/60)}/{race.sessionDuration/60}min</h3>
                    <RaceQLapData lapData={race.lapData} partecipants={race.partecipants} overallFastestLap={race.fastest} onPress={openLapHistory} />
                </Col>
                <Col sm={5}>
                <h3>Logs</h3>
                <RaceLog logs={logs} partecipants={race.partecipants} />
                </Col>
                </Row>
            }
            { speedtrapdata && !race.classificationData &&
                <Row>
                <Col sm={3}>
                <h3>Speed Trap</h3>
                <SpeedTrap speedtrap={speedtrapdata} partecipants={race.partecipants} />
                </Col>
                </Row>
            }
           
           {isLeagueAdmin( session) && race.created && canDeleteSession( race.created) &&
                <Button variant="danger" size='sm' onClick={() => { window.confirm( 'Are you sure you want to delete this RaceSession? There is no come back', ) && deleteRaceSession() }}>Delete Race Session</Button>
            }
            </Container>
            </main>
            <Footer></Footer>
        <LapModal
            show={modalShow}
            onHide={() => {
                setModalShow(false);
            }}
            laps={laps}
            logs={modalLogs}
            partecipants={race.partecipants}
            isleagueadmin={isLeagueAdmin( session)? 1 : 0}
        />
    </>
    );
};
export default Qualification;