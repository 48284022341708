import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import {Helmet} from "react-helmet-async";

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { getOrganizationDetailData , getChampionshipDetailData , getCalendarData , CALENDAR_COLLECTION , calculateClassificationFromCalendar } from "racemapper_shared/data";
import { isLeagueAdmin } from '../helpers/auth'
import { db } from '../services/firebase';

import RaceDetail from '../components/RaceDetail';
import ChampionshipCalendar from '../components/ChampionshipCalendar';
import ChampionshipStandingDriver from '../components/ChampionshipStandingDriver';
import ChampionshipStandingConstructors from '../components/ChampionshipStandingConstructors';

const Championship = props => {

    const championshipID = props.match.params.championshipID;
    const leagueID = props.match.params.leagueID;

    const location = useLocation()

    const [selectedRace, setSelectedRace] = React.useState(null);
    const [championship, setChampionship] = React.useState([]);
    const [league, setLeague] = React.useState([]);
    const [calendar, setCalendar] = React.useState([]);
    const [defaultTab, setDefaultTab] = React.useState( window.location.hash || '#calendar');

    const [driverStandings, setDriverStandings] = React.useState([]);
    const [constructorClassification, setConstructorClassification] = React.useState([]);
    const [hasConstructorChamp, setHasConstructorChamp] = React.useState(true);

    const calculateClassification = (calendar) => {
        const ret = calculateClassificationFromCalendar( calendar);

        setConstructorClassification( ret[1].filter(i => i.teamId !== 255));
        setDriverStandings( ret[0]);
        const myteam = ret[1].filter( i => i.teamId === 255);
        let hasc = myteam && myteam[0].tot === 0;
        setHasConstructorChamp( hasc);
    }

    useEffect(() => {
        setDefaultTab('driver');
        const getData = async(leagueID, championshipID) => {
            try {
                const dataModel = location.champ ?? await getChampionshipDetailData( db, leagueID, championshipID);
                setChampionship( dataModel);
            } catch( error) {
                console.log(error);
            }
        }
        const getLeague = async(leagueID) => {
            try {
                const data = location.league ?? await getOrganizationDetailData(db, leagueID);
                setLeague( data);
                getCalendar(championshipID, data);
            } catch( error) {
                console.log(error);
            }
        }

        const getCalendar = async(championshipID, league) => {
            try {
                if( isLeagueAdmin( league)) {
                    let ref = db.collection(CALENDAR_COLLECTION).where( "championshipId" , "==", championshipID).orderBy("date");
                    ref.onSnapshot(docSnapshot => {
                        const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}));
                        setCalendar( arrayData);
                        calculateClassification( arrayData);
                    }, err => {
                        console.log(`FB Encountered error: ${err}`);
                    });
                } else {
                    const dataModel = await getCalendarData( db, championshipID);
                    setCalendar( dataModel);
                    calculateClassification( dataModel);
                }
               
            } catch( error) {
                console.log(error);
            }
        }
        getData( leagueID, championshipID);
        getLeague( leagueID);
    },[leagueID, championshipID, location.league, location.champ]);

    return (
    <>
         <Helmet>
                <title>{championship.label || championshipID}</title>
        </Helmet>
        <Header></Header>
        <main className="bg-light">
            <Container fluid className='py-3'>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: { pathname:`/league/${leagueID}`, league:league }}}>{league.label || leagueID}</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {championship.label || championshipID}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <h4>{league.label} {championship.label}</h4>
                    </Col>
                    <Tabs defaultActiveKey={defaultTab} onSelect={(k) => {window.location.hash = k}}>
                        <Tab eventKey="#calendar" title="Calendar">
                            <ChampionshipCalendar calendar={calendar} league={league} championship={championship} onSelectedRace={(race) => {setSelectedRace(race)}} />
                        </Tab>
                        <Tab eventKey="#driver" title="Driver Standings">
                            <ChampionshipStandingDriver championship={championship} league={league} driverStandings={driverStandings} calendar={calendar} onSelectedRace={(race) => {setSelectedRace(race)}} />
                        </Tab>
                        {hasConstructorChamp &&
                        <Tab eventKey="#constructor" title="Constructor Standings">
                            <ChampionshipStandingConstructors championship={championship} league={league} constructorStandings={constructorClassification} calendar={calendar} onSelectedRace={(race) => {setSelectedRace(race)}} />
                        </Tab>
                        }
                    </Tabs>
                </Row>
            </Container>
        </main>
        <Footer></Footer>
        <RaceDetail race={selectedRace} league={league} show={selectedRace !== null} onHide={()=>{ setSelectedRace(null); }}  />
    </>
    );
}

export default Championship;