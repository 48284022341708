import { auth } from "../services/firebase";

export function signup(email, password) {
  return auth().createUserWithEmailAndPassword(email, password);
}

export function signin(email, password) {
  return auth().signInWithEmailAndPassword(email, password);
}

export function signInWithGoogle() {
  const provider = new auth.GoogleAuthProvider();
  return auth().signInWithPopup(provider);
}

export function signInWithTwitter() {
  const provider = new auth.TwitterAuthProvider();
  return auth().signInWithPopup(provider);
}

export function signInWithApple() {
  const provider = new auth.OAuthProvider("apple.com");
  return auth().signInWithPopup(provider);
}

export function logout() {
  return auth().signOut();
}

export function isLeagueAdmin( race) {
  if(typeof race.admins === 'undefined') {
    return isSuperAdmin();
  }
  const user = auth().currentUser;
  return user && ( isSuperAdmin() || race.admins.includes( user.uid));
}

export function isSuperAdmin() {
  const user = auth().currentUser;
  return user && user.uid === 'JxeTLQt1yXeJ9NhSfF3Ca7tpCiv1';
}