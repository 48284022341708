import React, {useEffect} from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

import { supportedFonts , topBannertemplates } from "racemapper_shared/customization";
import { db } from '../services/firebase';
import { ORG_COLLECTION , CHAMP_COLLECTION , COLLECTION_ID } from "racemapper_shared/data";
const fetch = require('node-fetch');

export default function StandingTopBanner ( props) {  
    
    const [isLoading, setLoading] = React.useState(false);
    const [morefields, setMorefields] = React.useState(false);

    const onFormChange = () => {
        const font = document.getElementById("font").value.trim();
        let data = {"fontFamily":font};
        data["title"] =  document.getElementById("title").value.trim();
        data["background"] = document.getElementById("background").value.trim();
        data["pic"] = document.getElementById("pic").value.trim();
        data["nextLabel"] = document.getElementById("nextLabel").value.trim();
        data["leaderLabel"] = document.getElementById("leaderLabel").value.trim();
        data["fontColor"] = document.getElementById("fontColor").value;
        data["secondaryColor"] = document.getElementById("secondaryColor").value;
        data["hilightColor"] = document.getElementById("hilightColor").value;
        data["maxDrivers"] = +document.getElementById("maxDrivers").value;

        const ref = db.collection(ORG_COLLECTION).doc(props.leagueid).collection(CHAMP_COLLECTION).doc( props.championship.id);
        setLoading(true);
        ref.set( {topbanner_config:data}, { merge: true }).then( res =>{
            loadImage();
        }).catch( e => console.log(e));
    }

    const hideModal = () => {
        props.onHide();
    }

    const loadImage = async () => {
        setLoading(true);

        //const url = "http://localhost:5001/helpmybusiness-752eb/europe-west6/standingsTopBanner?champ="+ props.championship.id +"&game="+COLLECTION_ID;
        const url = "/workers/standingstopbanner?champ="+ props.championship.id +"&game="+COLLECTION_ID;
        const res = await fetch(url, { method: "Get" });
        const txt = await res.text();
        document.getElementById('bannerImage').src = txt;

        setLoading(false);
    }

    const downloadFile = async () => {
        const fblob = await fetch( document.getElementById('bannerImage').src);
        const blob = await fblob.blob();
        const blobUrl = URL.createObjectURL(blob);

        document.getElementById('bannerData').href =  blobUrl;        
        document.getElementById('bannerData').click();
    }

    useEffect(() => {
        if( props.show) {
            loadImage();
        }
    },[props.show]);

    /*
           <FloatingLabel controlId="template" label="Template" className="mb-3">
                    <Form.Select aria-label="Template" name="template" defaultValue={props.config &&  props.config.template ? props.config.template : 0} >
                        { Object.keys(topBannertemplates).map( (item,i) => {
                            return <option key={`tpl:${i}`}>{item}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
    */
    return (        
    <Modal onHide={hideModal} fullscreen={true} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Standing Top Banner</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="g-2">
            <Col sm={6}>
                <Form>
                <FloatingLabel
                    controlId="pic"
                    label="Driver Picture (https://..)"
                    className="mb-3"
                    >
                    <Form.Control
                    type="url"
                    placeholder="150x150 jpg/png"
                    defaultValue={props.config &&  props.config.pic ? props.config.pic : ""}
                    />
                    <Form.Text className="text-muted">Insert the URL of the driver image. For best results it should be jpg/png image</Form.Text>
                </FloatingLabel>
                <FloatingLabel
                    controlId="title"
                    label="Title"
                    className="mb-3"
                >
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        defaultValue={props.config && props.config.title ? props.config.title : props.championship.label}
                    />
                </FloatingLabel>
                <Button variant='link' onClick={()=>{ setMorefields(!morefields)}}>{morefields ? "less settings" : "more settings"}</Button>
                <div hidden={!morefields}>
                <FloatingLabel controlId="maxDrivers" label="Drivers" className="mb-3">
                    <Form.Select aria-label="Drivers" name="maxDrivers" defaultValue={props.config &&  props.config.maxDrivers ? props.config.maxDrivers : 10} >
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId="font" label="Font" className="mb-3">
                    <Form.Select aria-label="Font" name="font" defaultValue={props.config &&  props.config.fontFamily ? props.config.fontFamily : "Formula1"} >
                        { Object.keys(supportedFonts).map( (item,i) => {
                            return <option key={`font:${i}`}>{item}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                    controlId="background"
                    label="Background Image (https://..)"
                    className="mb-3"
                    >
                    <Form.Control
                    type="url"
                    placeholder="1200x566 jpg/png"
                    defaultValue={props.config && props.config.background ? props.config.background : ""}
                    />
                    <Form.Text className="text-muted">Insert the URL of the background image. For best results it should be a jpg/png 1200x566 pixels large</Form.Text>
                </FloatingLabel>
                    <Row>
                        <Col sm="2">
                            <Form.Label htmlFor="hilightColor">Theme</Form.Label>
                            <Form.Control id="hilightColor" type="color" defaultValue={props.config && props.config.hilightColor ? props.config.hilightColor : "#cccccc"} />
                        </Col>
                        <Col sm="2">
                            <Form.Label htmlFor="fontColor">Primary</Form.Label>
                            <Form.Control id="fontColor" type="color" defaultValue={props.config && props.config.fontColor ? props.config.fontColor : "#ffffff"} />
                        </Col>
                        <Col sm="2">
                            <Form.Label htmlFor="secondaryColor">Secondary</Form.Label>
                            <Form.Control id="secondaryColor" type="color" defaultValue={props.config && props.config.secondaryColor ? props.config.secondaryColor : "#00000"} />
                        </Col>
                        <Form.Text className="text-muted">Font Color</Form.Text>
                    </Row>
                    <br />
                    <FloatingLabel
                        controlId="leaderLabel"
                        label="Leader Label"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            placeholder="Leader Label"
                            defaultValue={props.config &&  (props.config.leaderLabel || props.config.leaderLabel === "") ? props.config.leaderLabel : "CHAMPIONSHIP LEADER"}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="nextLabel"
                        label="Next Round Label"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            placeholder="Next Round Label"
                            defaultValue={props.config &&  (props.config.nextLabel || props.config.nextLabel === "") ? props.config.nextLabel : "NEXT RACE >>"}
                        />
                    </FloatingLabel>
                    </div>
                </Form>
            </Col>
            <Col sm={6}>
                    <a id="bannerData" href=''  download={`${props.championship.label}-topstanding.png`}><img hidden={isLoading} id="bannerImage" alt="top standings" style={{width:"100%"}} src="" /></a>
                    {isLoading &&  
                    <div className="d-flex justify-content-center">
                        <Spinner animation="grow" variant="secondary" />
                    </div>
                    }
            </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => hideModal()}>Close</Button>
        <Button variant="primary" disabled={isLoading}  onClick={() => { if(!isLoading) onFormChange()}}> 
        {isLoading ?  
            <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            />
        :'Refresh Image'
        }
        </Button>
        <Button variant="primary" disabled={isLoading}  onClick={() => { if(!isLoading) downloadFile()}}> 
        Download
        </Button>
      </Modal.Footer>
    </Modal>
    );
}
