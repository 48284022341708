exports.isRace = function(typeId) {
    return [10,11,12].includes(typeId);
}

exports.qualificationTypeIDS = function() {
    return [7,8,9];
}

exports.resultStatusDNF = function() {
    return [4,5,6,7];
}

exports.pointsSystem = function() {
    return [25,18,15,12,10,8,6,4,2,1];
}

exports.sprintRacePoints = function() {
    return [8,7,6,5,4,3,2,1];
}

exports.fastestLapPoints = function() {
    return 1;
}

exports.fastestLapPosition = function() {
    return 10;
}

exports.polePositionPoints = function() {
    return 0;
}