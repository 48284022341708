import {TEAM,TEAM_COLOR, TRACK, TRACK_COUNTRY} from 'racemapper_shared/enums';
import { hasDefaultPlayerName } from "racemapper_shared/data";

  export function zeroPad(num, size = 2) {
        return num.toString().padStart(size, '0');
  }
  
  // Convert time from miliseconds int to hh:mm:ss.S string
  export function lapTimeFormat(milliseconds) {
  
    if( !milliseconds || milliseconds <= 0) {
      return '-';
    }
    const seconds = milliseconds / 1000;

    const mm = Math.floor(seconds / 60).toString();
    const ss = Math.floor(seconds % 60).toString();
    const S  = (seconds % 1).toFixed(3).substring(2);
  
    return `${ zeroPad( mm ) }:${ zeroPad( ss ) }.${ S }`;
  }

  export function timeDistanceFormat(seconds) {
     if( !seconds || seconds <= 0) {
       return '-';
     } 
     const mm = Math.floor(seconds / 60).toString();
     const ss = Math.floor(seconds % 60).toString();
     const S  = (seconds % 1).toFixed(3).substring(2);
   
     if( mm === "0") {
      return `${ ss }.${ S }`; 
     }
     return `${ mm }:${ zeroPad( ss ) }.${ S }`;
  }

  export function sectorTimeFormat(milliseconds) {

    if( milliseconds <= 0 || !milliseconds || milliseconds === Number.MAX_SAFE_INTEGER) {
      return '';
    }
    const seconds = milliseconds / 1000;
    const mm = Math.floor(seconds / 60);
    const ss = Math.floor(seconds % 60).toString();
    const S  = (seconds % 1).toFixed(3).substring(2);

    let str = '';
    if( mm > 0) {
      str =  mm.toString()+'.';
    }
    return `${ str }${ zeroPad( ss ) }.${ S }`;
  }

  export function timeFormat(seconds) {
  
    const mm = Math.floor(seconds / 60).toString();
    const ss = Math.floor(seconds % 60).toString();
  
    return `${ zeroPad( mm ) }:${ zeroPad( ss ) }`;
  }

  export function timeOffset(laptime, fastest,  position) {
    if ( laptime === 0) {
      return "";
    }
    if( position === 1) {
      return "";
    }
    const delta = (laptime - fastest)/1000;
    return delta < 0 ? delta.toFixed(3) : "+" +  delta.toFixed(3);
  }

  export function plainTimeDelta(delta) {
    return  (( delta > 0) ? "+" : "") +  (delta/1000).toFixed(3);
  }

  export function driverFormat(driver, plain) {
    let label = "";
    if( !driver) {
      return label;
    }

    if( driver.label) {
      label = driver.label;
    } else {
      if( driver.name) {
        label = driver.name
      }
      if( hasDefaultPlayerName(label) && driver.raceNumber) {
        label += " #"+driver.raceNumber;
      }
    }
   /* if( driver.aiControlled) {
      label = 'AI - ' + label;
    }*/
    let returnComponents = []
    if( plain) {
      returnComponents = label;
    } else {
      const cname = "team-cell team-"+TEAM_COLOR[driver.teamId];
      returnComponents = [(<div key={driver.driverId||label} className={cname}></div>),label];
    }
    return returnComponents;
  }

  export function teamFormat(teamId) {
    return TEAM[teamId];
  }

  export function lblAssistants(item) {
    let lbl = "TCS: ";
    if( item.tractionControl === -1) {
      lbl += "?";
    } else if( item.tractionControl === 0) {
      lbl += "OFF";
    } else if( item.tractionControl === 1) {
      lbl += "M";
    } else {
      lbl += "FULL";
    }
  
    lbl += " ABS: ";
    if( item.antiLockBrakes === -1) {
      lbl += "?";
    } else if( item.antiLockBrakes === 0) {
      lbl += "OFF";
    } else {
      lbl += "ON";
    }
    return lbl;
  }

  export function percValue(item) {
    if( item) {
      return item.toFixed(2).toString() + "%";
    }
    return "0%";
  }

  export function speedValue(item) {
    if( item) {
      return item.toString() + "km/h";
    }
    return "";
  }

  export function trackFormat( trackId, bold) {
    const CustomTag = (bold) ? 'strong' : 'span';

    return <CustomTag key={trackId}><img className='flag-icon-small' src={`${process.env.PUBLIC_URL}/static/flags/${trackId}.png`} alt={TRACK[trackId]} /> {TRACK[trackId]} - {TRACK_COUNTRY[trackId]}</CustomTag>
  }