import React, { Component } from 'react';
import {driverFormat} from '../helpers/format';

import Table from 'react-bootstrap/Table';

class SpeedTrap extends Component {

    render() {
      return(
        <div>
<Table striped hover size="sm">
  <tbody>
{ this.props.speedtrap && this.props.speedtrap.map( (item, i) => (
    <tr key={`speedtrap-${i}`}>
        <td key={`speedtrap-${i}-num`}>{driverFormat(this.props.partecipants[item.vehicleIdx])}</td>
        <td key={`speedtrap-${i}-lbl`}>{item.speed.toFixed(1)}km/h</td>
    </tr>
    ))
}
  </tbody>
</Table>
        </div>);
    }
  }
  
  export default SpeedTrap;