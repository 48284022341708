import React, {useEffect} from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { db } from "../services/firebase";
import {getChampionshipsData, getCalendarData, addCalendarData} from "racemapper_shared/data";
//import { db } from '../services/firebase';

export default function CalendarImportModal ( props) {  
    const [championships, setChampionships] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);

    const onFormSave = (e) => {
        e.preventDefault();

        if( isLoading) {
            return;
        }
        const importFrom =  e.target.championship.value;
        const adjstime = e.target.adjstime.checked;
        const newtime = e.target.newtime.value;
        const days = e.target.days.value;

        if( importFrom === "-1") {
            alert("Please select a Championship from where import races");
            return;
        }
        if( adjstime && !newtime) {
            alert("If checked, insert new race time for all races");
            return;
        }

        setLoading( true);
        
        getCalendarData( db, importFrom).then( calendarArray => {
            if(calendarArray.length === 0 ) {
                setLoading( false);
                alert("Selected Championship has no races");
                return;
            }
            calendarArray.forEach( item => {
                let newdate = item.date.toDate();
                if( adjstime) {
                    const hhmm = newtime.split(":");
                    newdate.setHours(parseInt(hhmm[0]), parseInt(hhmm[1]));
                }
                if( days !== "0") {
                    newdate.setDate(newdate.getDate() + parseInt(days));
                }
                // batch doesn't support yet insert, only set but I don't have an ID
                addCalendarData( db, newdate, item.trackId, item.organisationId, item.organisationLabel, props.championship.id, props.championship.label, "", item.format);
            });
            setLoading( false);
            hideModal();  
        }).catch( e => {
            console.log(e);
            setLoading( false);
        });
       
        /*const label = sessionName.trim();
        if(label.length) {
            setLoading(true);
            if( props.calendar) { // edit
              const ref = db.collection(ORG_COLLECTION).doc(props.leagueid).collection(CALENDAR_COLLECTION).doc( props.calendar.id);
              ref.set( {label}, { merge: true }).then( res =>{
                  setLoading(false);
                  props.onHide();
              }).catch( e => console.log(e));
            } else { // new insert
              const ref = await db.collection(ORG_COLLECTION).doc(props.leagueid).collection(CALENDAR_COLLECTION).add({label});
              setLoading(false);
              props.onHide(ref.id, label);
            }
        }*/
    }

    const hideModal = () => {
        props.onHide();
    }

    useEffect(() => {
        if( props.show) {
            getChampionshipsData( db, props.leagueid).then( res => {
                setChampionships(res.filter( item => item.id !== props.championship.id));
            });
        }
    },[ props.show, props.leagueid, props.championship]);

    return (        
        <Modal onHide={hideModal} size="sm" centered {...props} >
          <Modal.Header closeButton>
            <Modal.Title>Calendar Import</Modal.Title>
          </Modal.Header>
          <Form onSubmit={onFormSave}>
            <Modal.Body>
                <FloatingLabel controlId="championship" label="Championship" className="mb-3">
                    <Form.Select aria-label="Championship" name="championship" >
                        <option key={`ch:-1`} value="-1"></option>
                        {championships.map( (item) => {
                            return <option key={`ch:${item.id}`} value={item.id}>{item.label}</option>
                        })}
                    </Form.Select>
                </FloatingLabel>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Adjust Race time</InputGroup.Text>
                    <InputGroup.Checkbox  id="adjstime" aria-label="Adjust Race time" />
                    <FormControl id="newtime" type="time" />
                </InputGroup>
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>Adjust Race day</InputGroup.Text>
                    <FormControl id="days" type="number" defaultValue={0} />
                    <InputGroup.Text>Days</InputGroup.Text>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" 
                    disabled={isLoading}
                    type="submit"
                >
                {isLoading ? 'Importing…' : 'Import'}
                </Button>
            </Modal.Footer>
          </Form>
        </Modal>
    );
  }
