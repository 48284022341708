import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Alert from 'react-bootstrap/Alert'

import Header from '../components/Header';
import Footer from '../components/Footer';
import RaceHeader from '../components/RaceHeader';
import RaceLog from '../components/RaceLog';
import RaceResult from '../components/RaceResult';
import LapModal from '../components/LapModal';
import RaceLapData from '../components/RaceLapData';
import SpeedTrap from '../components/SpeedTrap';
import PitStopTimes from '../components/PitStopTimes';
import PitLaneTimes from '../components/PitLaneTimes';
import ShareComponent from '../components/ShareComponent';
import StewardModal from '../components/StewardModal';
import RacePitChart from '../charts/RacePitChart';

import {SC_STATUS,TRACK,SESSION_TYPE} from 'racemapper_shared/enums'
import Badge from 'react-bootstrap/Badge';

import { db } from '../services/firebase';
import { isLeagueAdmin } from '../helpers/auth';
import { COLLECTION_ID, mapDriversLabel } from "racemapper_shared/data";
import { SPEEDTRAP_COLLECTION, SESSIONS_COLLECTION,LOGS_COLLECTION,getDBLaps,sessionFastestData,lapsForModal , deleteRaceData } from "racemapper_shared/data";
import { canDeleteSession } from '../helpers/raceUtils';
const fetch = require('node-fetch');

let partecipantsMapper = [];
let lapsForDriver = {};

const Sessionrace = props => {

    const [session, setSession] = React.useState([]);
    const [race, setRace] = React.useState([]);
    const [logs, setLogs] = React.useState([]);
    const [laps, setLaps] = React.useState([]);
    const [speedtrapdata, setSpeedtrapdata] = React.useState([]);
    const [speedtrapAlldata, setSpeedtrapAlldata] = React.useState([]);
    const [pitstoptimes, setPitstoptimes] = React.useState([]);
    const [pitlanetimes, setPitlanetimes] = React.useState([]);

    const [modalLogs, setModalLogs] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalChartShow, setModalChartShow] = React.useState(false);
    const [modalStewardShow, setModalStewardShow] = React.useState(false);

    const sessionRaceID = props.match.params.sessionID;
    const raceID = props.match.params.raceID;

    function formatRace( doc, sessionPartecipants) {
        doc.fastest = sessionFastestData( doc);
        doc.lapData.map( (lap, driver) => {
            // or box
            if( !lapsForDriver[driver] || lap.driverStatus === 0 || lap.driverStatus === 2) {
                lapsForDriver[driver] = [];
            }
            if( !lapsForDriver[driver][lap.currentLapNum]) {
                lapsForDriver[driver][lap.currentLapNum] = {};
            }

            lap.sector3TimeInMS = Number.MAX_SAFE_INTEGER;

            let s1class = "";
            let s2class = "";
            let s3class = "";
    
            let s1offset = "";
            let s2offset = "";
            let s3offset = "";
    
            lap.lapOffset = "";
            // we need to calculate s3

            if( !lap.sector1TimeInMS && 
                !lap.sector2TimeInMS && 
                lapsForDriver[driver][lap.currentLapNum-1] &&
                lap.lastLapTime &&
                (lap.driverStatus === 1 ||  lap.driverStatus === 4)
                ) {
                const k = lap.currentLapNum-1;
                lap.sector1TimeInMS = lapsForDriver[driver][k]['s1'];
                lap.sector2TimeInMS = lapsForDriver[driver][k]['s2'];
                s1offset = lapsForDriver[driver][k]['s1offset'];
                s2offset = lapsForDriver[driver][k]['s2offset'];
                
                lap.sector3TimeInMS = (lap.lastLapTime * 1000) - (lap.sector1TimeInMS + lap.sector2TimeInMS);
                
                s1class = lapsForDriver[driver][k]['s1class'];
                s2class = lapsForDriver[driver][k]['s2class'];

                s3offset =  lap.sector3TimeInMS - doc.fastest.s3;
                if( lap.currentLapInvalid) {
                    s3class = "invalidated";
                } else if( s3offset < 0) {
                    s3class = "fastest";
                } else  if( lap.sector3TimeInMS < lap.bestLapSector3TimeInMS || lap.bestLapSector3TimeInMS) {
                    s3class = "faster";
                } else {
                    s3class = "normal";
                }
               // const now = new Date();
               // lapsForDriver[driver][k]['ts'] = now.getTime();
            } else {
                if( lap.sector1TimeInMS ) {
                    s1offset =  lap.sector1TimeInMS - doc.fastest.s1;
                    if( lap.currentLapInvalid) {
                        s1class = "invalidated";
                    } else if( s1offset <= 0) {
                        s1class = "fastest";
                    } else  if( lap.sector1TimeInMS <= lap.bestLapSector1TimeInMS || !lap.bestLapSector1TimeInMS) {
                        s1class = "faster";
                    } else {
                        s1class = "normal";
                    }
                    lapsForDriver[driver][lap.currentLapNum]['s1'] = lap.sector1TimeInMS;
                    lapsForDriver[driver][lap.currentLapNum]['s1offset'] = s1offset;
                    lapsForDriver[driver][lap.currentLapNum]['s1class'] = s1class;
                }

                if( lap.sector2TimeInMS ) {
                    s2offset =  lap.sector2TimeInMS - doc.fastest.s2;
                    if( lap.currentLapInvalid) {
                        s2class = "invalidated";
                    } else if( s2offset <= 0) {
                        s2class = "fastest";
                    } else  if( lap.sector2TimeInMS <= lap.bestLapSector2TimeInMS || !lap.bestLapSector2TimeInMS) {
                        s2class = "faster";
                    } else {
                        s2class = "normal";
                    }
                    lapsForDriver[driver][lap.currentLapNum]['s2'] = lap.sector2TimeInMS;
                    lapsForDriver[driver][lap.currentLapNum]['s2offset'] = s2offset;
                    lapsForDriver[driver][lap.currentLapNum]['s2class'] = s2class;
                    lapsForDriver[driver][lap.currentLapNum]['position'] = lap.carPosition;
                }
            }

            lap.driver = driver;
            lap.s1class = s1class;
            lap.s2class = s2class;
            lap.s1offset = s1offset;
            lap.s2offset = s2offset;
            
            lap.s3class = s3class;
            lap.s3offset = s3offset;

            return lap;
        });
    
        if( sessionPartecipants) {
            doc.partecipant = mapDriversLabel( doc.partecipants, sessionPartecipants);
        }

        return doc;
    }

    const deleteRaceSession = () => {
        deleteRaceData( db, sessionRaceID, raceID)
        .then( (_)=> {
            props.history.push('../');
        });
    }

    const openLapHistory = driver => e => {
        setModalShow(true);
        setLaps( []);
        setModalLogs( []);
        const getLaps = async() => {
            try {
                const data = await getDBLaps( db, sessionRaceID, raceID, [driver]);
                const speed = speedtrapdata.find(i => i.vehicleIdx === driver);
                const objLaps = lapsForModal( driver, data, speedtrapAlldata, speed);
                objLaps["fastestOverall"] = sessionFastestData(race);
                objLaps["partecipant"] = race.partecipants[driver];
                objLaps["idx"] = driver;
                setLaps( objLaps);
                const mlogs = logs.filter(i => i.data.vehicleIdx === driver);
                setModalLogs(mlogs);
            } catch( error) {
                console.log(error);
            }
        }
        getLaps();
    }

    const openChartFs  =  e => {
        setModalChartShow(true);
    }

    const openStewardModal  =  e => {
        setModalStewardShow(true);
    }

    const regenerateClassification = () => {
        const settings = { method: "Get" };
        fetch("/workers/refreshclassification?game=" + COLLECTION_ID + "&session=" + sessionRaceID + "&race=" + raceID, settings);
    }

    useEffect(() => {
        const getLogs = async() => {
            try {
                let logsref = db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).doc(raceID).collection(LOGS_COLLECTION).orderBy("sessionTime","desc");
                logsref.onSnapshot(docSnapshot => {
                    const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}));
                    setLogs( arrayData);
    
                    const pitlogs = arrayData.filter(function(entry) {
                        return entry.type === "_PIT";
                    });
                    var driverId = {};
                    const pits = pitlogs.sort(function (a, b) {
                            return (a.data.pitStopTime < b.data.pitStopTime) ? -1 : 1;
                    }).filter(function(entry) {
                        if (driverId[entry.data.vehicleIdx]) {
                            return false;
                        }
                        driverId[entry.data.vehicleIdx] = entry.data.pitStopTime;
                        return true;
                    });
                    setPitstoptimes( pits);
    
                    driverId = {};
                    const pitLaneTime = pitlogs.sort(function (a, b) {
                        return (a.data.pitLaneTime < b.data.pitLaneTime) ? -1 : 1;
                    }).filter(function(entry) {
                        if (driverId[entry.data.vehicleIdx]) {
                            return false;
                        }
                        driverId[entry.data.vehicleIdx] = entry.data.pitLaneTime;
                        return true;
                    });
                    setPitlanetimes( pitLaneTime);
                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });
            } catch( error) {
                console.log(error);
            }
        }
    
        const getSpeedTrap = async() => {
            try {
                let speedref = db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).doc(raceID).collection(SPEEDTRAP_COLLECTION).orderBy("speed","desc");
                speedref.onSnapshot(docSnapshot => {
                    var driverId = {};
                    const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}));
                    const oneperdriver = arrayData.filter(function(entry) {
                        if (driverId[entry.vehicleIdx] && driverId[entry.vehicleIdx] > entry.speed) {
                            return false;
                        }
                        driverId[entry.vehicleIdx] = entry.speed;
                        return true;
                    });
                    setSpeedtrapdata( oneperdriver);
                    setSpeedtrapAlldata( arrayData)
                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });
            } catch( error) {
                console.log(error);
            }
        }
        
        const getSessionData = async() => {
            try {
                let firstLoad = true
                let ref = db.collection(COLLECTION_ID).doc(sessionRaceID);
                ref.onSnapshot(docSnapshot => {
                    const d =  docSnapshot.data();
                    partecipantsMapper = d.partecipants;
                    setSession( d);
    
                    if( firstLoad) {
                        // need to check if firstload is kept between calls
                        getRaceData();
                        firstLoad = false;
                    }
                  }, err => {
                    console.log(`Encountered error: ${err}`);
                }); 
            } catch( error) {
                console.log(error);
            }
        }
    
        const getRaceData = async() => {
            try {
                let firstLoad = true;
                let raceRef = db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).doc(raceID);
                raceRef.onSnapshot(docSnapshot => {
                    let data = docSnapshot.data();
                    data.lapData = Array.isArray( data.lapData) ? data.lapData : Object.values(data.lapData);
                   // console.log( data.lapData)
                    data.partecipants = Array.isArray( data.partecipants) ? data.partecipants : Object.values(data.partecipants);
    
                    const formattedData = formatRace( data, partecipantsMapper);      
                    setRace( formattedData);
                    if( firstLoad) {
                        getLogs();
                        getSpeedTrap();
                        firstLoad = false;
                    }

                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });   
    
            } catch( error) {
                console.log(error);
            }
        }
        getSessionData();        
    }, [sessionRaceID,raceID]);


    return (
        <>
            <Header></Header>
            <main className="bg-light">
           
            <Container fluid className='py-3'>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/session/${sessionRaceID}` }}>{session.label || sessionRaceID}</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {TRACK[race.trackId]} - {SESSION_TYPE[race.sessionType]}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <Container>
                <RaceHeader session={session} race={race} weatherForecastSamples={race.weatherForecastSamples} forecast={!race.classificationData} />
            </Container>
            <br />

        <Container fluid>
            { race.classificationData &&
                <div>
                    <Row>
                    <Col>
                    {race.classificationRefresh && isLeagueAdmin( session) &&
                    <Alert key="success" variant="success">
                        Classification needs to be recalculated. Please proceed when you have finished with stewards decisions.<br />
                        <Button variant='warning' size='sm' onClick={() => {regenerateClassification()}}>Recalculate</Button>
                    </Alert>
                    }
                        <h3>Race Results  &nbsp;&nbsp;
                        {
                           <><Button variant="outline-dark" size="sm" onClick={openStewardModal}>Stewards</Button>&nbsp;</>
                        }
                            <ButtonGroup size="sm">
                                { race.banner &&
                                <Button key="banner-dl" variant="outline-dark" href={race.banner} target="_blank">
                                    <img alt="fs" src="/static/file-image.svg" style={{width: 20}} />
                                </Button>
                                }
                                { race.csv &&
                                <OverlayTrigger
                                key={`exportcsv`}
                                placement="top"
                                overlay={
                                <Tooltip>
                                    export as CSV
                                </Tooltip>
                                }
                                >
                                <Button key="csv-dl" variant="outline-dark" href={`${race.csv}`} target="_blank">
                                    <img alt="fs" src="/static/filetype-csv.svg" style={{width: 20}} />
                                </Button>
                                </OverlayTrigger>
                                }
                                { race.csv_eu &&
                                <OverlayTrigger
                                key={`exportcsveu`}
                                placement="top"
                                overlay={
                                <Tooltip>
                                    export as CSV, EU format
                                </Tooltip>
                                }
                                >
                                <Button key="csv-dl" variant="outline-dark" href={`${race.csv_eu}`} target="_blank">
                                    <img alt="fs" src="/static/filetype-csv.svg" style={{width: 20}} />
                                </Button>
                                </OverlayTrigger>
                                }
                                <ShareComponent title={session.label || `${TRACK[race.trackId]} - ${SESSION_TYPE[race.sessionType]}`} twitterHandler={session.twitter || ""} />
                                
                            </ButtonGroup>
                        </h3>
                        <RaceResult result={race.classificationData} session={session} lapData={race.lapData} partecipants={race.partecipants} sessiontype={race.sessionType} onPress={openLapHistory} openSteward={openStewardModal} />
                    </Col>
                    </Row>

                    <Row>
                    <Col sm={7}>
                        {race.chart && 
                            <Row>
                                <Col>
                                    <h3>Chart <Button key="chart-full" variant="link" onClick={openChartFs}>
                                        <img alt="fs" src="/static/arrows-fullscreen.svg" style={{width: 20}} /></Button>
                                    </h3>
                                    <iframe id="raceGraph" itemID="raceGraph" title="graph" width="100%" height="330" frameBorder="0" src={`/static/racechart/?session=${sessionRaceID}&race=${raceID}`}></iframe>
                                </Col>
                            </Row>
                        }
                        {race.classificationData && 'tyreStintsEndLaps' in race.classificationData[0] &&
                        <Row style={{marginBottom:"20px", marginTop:"20px"}}>
                            <Col>
                                <h3>Tyre Strategy</h3>
                                <RacePitChart results={race.classificationData} partecipants={race.partecipants} />
                            </Col>
                        </Row>
                        }
                        <Row>
                        <Col sm={6}>
                        {speedtrapdata && speedtrapdata.length &&
                            <>
                            <h3>Speed Trap</h3>
                            <SpeedTrap speedtrap={speedtrapdata} partecipants={race.partecipants} />
                            </>
                        }
                        </Col>
                        <Col sm={6}>
                        {pitstoptimes && pitstoptimes.length &&
                            <>
                            <Tabs defaultActiveKey="pitstop" id="pitstop-tab" className="mb-3 font-face-f1" variant='pills'>
                                <Tab eventKey="pitstop" title="PIT Stop Time">
                                    <PitStopTimes pitstoptimes={pitstoptimes} partecipants={race.partecipants} />
                                </Tab>
                                <Tab eventKey="pitlane" title="PIT Lane Time">
                                    <PitLaneTimes pitlanetimes={pitlanetimes} partecipants={race.partecipants} />
                                </Tab>
                            </Tabs>
                            </>
                        }
                        </Col>
                        </Row>
                    </Col>
                    <Col sm={5}>
                    <h3>Logs</h3>
                    <RaceLog logs={logs} partecipants={race.partecipants} />
                    </Col>
                    </Row>
                </div>
            }
        

            { race.lapData && !race.classificationData &&
                <Row>
                <Col sm={8}>
                    <h3>
                    Lap {race.lapData.sort((a, b) =>  b.currentLapNum - a.currentLapNum)[0].currentLapNum}/{race.totalLaps}
                        <Badge bg="warning">{SC_STATUS[race.safetyCarStatus]}</Badge>
                    </h3>
                    <RaceLapData lapData={race.lapData.filter( r => r.resultStatus > 1).sort((a, b) =>  a.carPosition - b.carPosition)} partecipants={race.partecipants} overallFastestLap={race.fastest} onPress={openLapHistory} />
                </Col>
                <Col sm={4}>
                <h3>Logs</h3>
                <RaceLog logs={logs} partecipants={race.partecipants} />
                </Col>
                </Row>
            }

            { speedtrapdata && !race.classificationData &&
                <Row>
                    <Col sm={3}>
                    <h3>Speed Trap</h3>
                    <SpeedTrap speedtrap={speedtrapdata} partecipants={race.partecipants} />
                    </Col>
                    <Col sm={6}>
                        <Tabs defaultActiveKey="pitstop" id="pitstop-tab" className="mb-3" variant='pills'>
                            <Tab eventKey="pitstop" title="PIT Stop Time">
                                <PitStopTimes pitstoptimes={pitstoptimes} partecipants={race.partecipants} />
                            </Tab>
                            <Tab eventKey="pitlane" title="PIT Lane Time">
                                <PitLaneTimes pitlanetimes={pitlanetimes} partecipants={race.partecipants} />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            }

            {isLeagueAdmin( session) && race.created && canDeleteSession( race.created) &&
                <Button variant="danger" size='sm' onClick={() => { window.confirm( 'Are you sure you want to delete this RaceSession? There is no come back', ) && deleteRaceSession() }}>Delete Race Session</Button>
            }
            </Container>
            </main>
            <Footer></Footer>

        <LapModal
            show={modalShow}
            onHide={() => {
                setModalShow(false);
            }}
            laps={laps}
            logs={modalLogs}
            partecipants={race.partecipants}
            isleagueadmin={isLeagueAdmin( session)? 1 : 0}
        />

      <StewardModal raceID={raceID} isRefreshNeeded={race.classificationRefresh} isLeagueAdmin={isLeagueAdmin( session)} sessionID={sessionRaceID} show={modalStewardShow} partecipants={race.partecipants} onHide={() => setModalStewardShow(false)} />

      <Modal size="xl" centered
        show={modalChartShow}
        onHide={() => setModalChartShow(false)}
        dialogClassName="modal-full"
        backdrop="static"
        fullscreen={true}
      >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Race Chart</Modal.Title>
        </Modal.Header>
        <Modal.Body id="chartFullScreen"><iframe id="raceGraph" itemID="raceGraph" title="graph" width="100%" height="400" frameborder="0" src={`/static/racechart/?session=${sessionRaceID}&race=${race.sessionUID}`}></iframe></Modal.Body>
      </Modal>

        </>
    );
};
export default Sessionrace;

