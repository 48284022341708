import React from 'react';
import { Link } from 'react-router-dom';

import { auth } from '../services/firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'

var crypto = require('crypto');

function Header() {
  function getProfileImage() {
    if( auth().currentUser.photoURL) {
      return  auth().currentUser.photoURL;
    }
    if( auth().currentUser.email) {
      const md5 = crypto.createHash('md5').update( auth().currentUser.email).digest('hex').toLowerCase();
      return 'https://www.gravatar.com/avatar/'+md5+"?d=retro&s=50";
    }
  }
  return (
    <header className="site-header sticky-top">
      <Navbar bg="white" variant="light" expand="lg">
        <Container fluid>
          <Link to="/"><Navbar.Brand>RaceMapper</Navbar.Brand></Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            <Nav.Link as={Link} to="/">Telemetry</Nav.Link>
            <Nav.Link as={Link} to="/league">Leagues</Nav.Link>
            <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
          </Nav>
            {auth().currentUser
              ?<>
                <Image style={{width:'50px'}} src={getProfileImage()} roundedCircle thumbnail />
                <ButtonGroup>
                  <DropdownButton variant="light" as={ButtonGroup} title={auth().currentUser.displayName|| "Profile"} id="bg-nested-dropdown">
                      <Dropdown.Item eventKey="1" onClick={() => auth().signOut()}>Logout</Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </>
              :
              <>
              <Link to="/login">
                <Button variant="link">Login</Button>
              </Link>or<Link to="/signup">
                <Button variant="link">Sign up</Button>
              </Link>
              </>
              }              
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;