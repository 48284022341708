import React, {useEffect, Fragment} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'

import firebase from "firebase/app";
import { db } from "../services/firebase";
import { saveStewardData , getStewardData , delteStewardData , setRaceRefreshNeeded } from "racemapper_shared/data";
import { driverFormat } from '../helpers/format';
const url = require('url');
const path = require('path');

export default function StewardModal( props) {  
    const [showError, setShowError] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);

    const [stewardsData, setStewardsData] = React.useState([]);

    const onFormSave = (e) => {
        e.preventDefault();
 
        if( e.target.driver.value === "-1") {
            return;
        }
    
        const s = Number(e.target.seconds.value);
        const lp = Number(e.target.license.value);

        saveStewardData( db, props.sessionID, props.raceID, Number(e.target.driver.value), Number(e.target.reportedby.value), s, lp, e.target.url.value, e.target.descr.value, firebase.firestore.FieldValue.serverTimestamp())
        .then( res => {
            refresheData();
            setEditMode(false);
            if(!props.isRefreshNeeded) {
                console.log("refresh needed");
                setRaceRefreshNeeded( db, props.sessionID, props.raceID );
            }
        }).catch(err => {
            console.error(err);
            setShowError(true);
        });
       // props.onHide();
    }

    const refresheData = () => {
       getStewardData( db, props.sessionID, props.raceID).then( res => {
        setStewardsData(res);
       });
        
    }

    const formatDescription = (desc) => {
        return desc.split('\n').map((item, key) => {
            return <Fragment key={key}>{item}<br/></Fragment>
        });
    }
    
    const deleteReport = (id) => {
        delteStewardData( db, props.sessionID, props.raceID, id).then( res => {
            if(!props.isRefreshNeeded) {
                console.log("refresh needed");
                setRaceRefreshNeeded( db, props.sessionID, props.raceID);
            }
            refresheData();
        }).catch(err => {
            console.error(err);
            setShowError(true);
        });
    }

    const videoEmbed = (item) => {
        if( !item.url) {
            return
        }
        const w = 280;
        const h = 160;
        const parsedurl =  url.parse( item.url, true);
        if( parsedurl.hostname === "clips.twitch.tv") {
            const bn = path.basename( parsedurl.pathname);
            const parent = url.parse( window.location.href, false).hostname;
            return  <iframe
                        title={`clip-${item.id}`}
                        src={`https://clips.twitch.tv/embed?clip=${bn}&parent=${parent}`}
                        height={h}
                        width={w}
                        preload="none"
                        allowFullScreen>
                    </iframe>
        } else if( parsedurl.hostname.includes("youtube")) {
            const q = parsedurl.query;
            const bn = q['v'];
            return <iframe marginHeight={0} marginWidth={0} width={w} height={h} src={`https://www.youtube-nocookie.com/embed/${bn}?controls=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        } else if( parsedurl.hostname === "youtu.be") {
            const bn = path.basename( parsedurl.pathname);
            return <iframe marginHeight={0} marginWidth={0} width={w} height={h} src={`https://www.youtube-nocookie.com/embed/${bn}?controls=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        }
        return "";
        
    }
    useEffect(() => {
        if( props.show) {
            getStewardData( db, props.sessionID, props.raceID).then( res => {
                setStewardsData(res);
            });
        }
    },[ props.show,props.sessionID, props.raceID]);

    return (  
        <Modal show={props.show} onHide={props.onHide} size="lg" centered>
        <Alert variant="danger" show={showError} onClose={() => setShowError(false)} dismissible>
            <Alert.Heading>Ops, an error occurred</Alert.Heading>
            <p>
                Please retry later or contact us
            </p>
        </Alert>
          <Modal.Header closeButton>
            <Modal.Title>Stewards Decisions
                <Button variant="outline-dark" hidden={!props.isLeagueAdmin || editMode} onClick={()=>setEditMode(true)} size='sm'>+</Button>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={onFormSave}>
          <Modal.Body>
              {editMode &&
              <>
                <Row className="g-2">
                    <Col md>
                        <Form.Select aria-label="Select a driver" name="driver">
                            <option key="d:-1" value="-1">Driver</option>
                            {props.partecipants && props.partecipants.filter( el => el.label).map( (i,idx) => {
                                return <option key={`d:${idx}`} value={idx}>{i.label}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col md>
                        <Form.Select aria-label="Select a driver" name="reportedby">
                            <option key="r:-1" value="-1">Reported by</option>
                            {props.partecipants && props.partecipants.filter( el => el.label).map( (i,idx) => {
                                return <option key={`r:${idx}`} value={idx}>{i.label}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col md>
                        <InputGroup className="mb-3">
                            <FormControl  name="seconds" type="number" aria-label="Seconds to add/remove" defaultValue={3} />
                            <InputGroup.Text>Seconds</InputGroup.Text>
                        </InputGroup>
                    </Col>
                                      
                    <Col md>
                        <InputGroup className="mb-3">
                            <FormControl name="license" type="number" aria-label="License points to remove" max={0}  defaultValue={0} />
                            <InputGroup.Text>License pts</InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <FloatingLabel controlId="floatingTextarea" label="Link (optional)" className="mb-3">
                            <Form.Control  name="url" type="url" placeholder="https://" />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                <Form.Control
                                name="descr"
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                />
                        </FloatingLabel>
                    </Col>
                </Row>
                </>
                }
                <Row>
                    <Col md>
    <Table responsive  borderless striped hidden={editMode}>
        <tbody>
      {stewardsData && stewardsData.map( item => (
          <>
            <tr>
                <td style={{whiteSpace: "nowrap", width:90}}>
                        <strong>{driverFormat(props.partecipants[item.vehicleId], false)}</strong>
                </td>
                <td className='centerTxt' style={{whiteSpace: "nowrap", width:70}}>{item.seconds} s.</td>
                <td rowspan="2">{formatDescription(item.description)}
                    <span hidden={item.reportedbyVehicleId ===-1}>Reported by <strong>{driverFormat(props.partecipants[item.reportedbyVehicleId], true)}</strong></span>
                    <a hidden={item.url===""} href={item.url} target="_blank" rel="noreferrer" size="sm" variant="link">&nbsp; Link</a>
                    {props.isLeagueAdmin &&
                    <>&nbsp;<Button variant="outline-danger" size='sm' onClick={() => deleteReport(item.id)}>delete</Button></>
                }
                </td>
                </tr>
                <tr>
                    <td colspan="2">{videoEmbed(item)}</td>
            </tr>
            <tr><td colspan="3"></td></tr>
          </>
        ))
      }
        </tbody>
      </Table>
      {stewardsData && !editMode && stewardsData.length === 0 &&
         <Alert key="nodata" variant="light">
         No stewards decisions
        </Alert>
      }
                    </Col>
                </Row>
          </Modal.Body>
          <Modal.Footer>
                <Button hidden={!editMode} variant="secondary" onClick={() => setEditMode(false)}>Cancel</Button>
                <Button hidden={!editMode} variant="primary" type="submit">Save</Button>
          </Modal.Footer>
          </Form>
        </Modal>
    );
  }
