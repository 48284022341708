import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Alert from 'react-bootstrap/Alert';

import ShareComponent from '../components/ShareComponent';
import { deleteCalendarData , CALENDAR_COLLECTION } from "racemapper_shared/data";
import { dateForEvent , raceFormat } from "racemapper_shared/formats";

import { trackFormat } from "../helpers/format";
import { isLeagueAdmin } from '../helpers/auth'
import { db } from '../services/firebase';

import ChampionshipNewModal from '../components/ChampionshipNewModal';
import CalendarModal from '../components/CalendarModal';
import CalendarImportModal from '../components/CalendarImportModal';

const fetch = require('node-fetch');

export default function ChampionshipCalendar ( props) {  
    
    const [modalCalendarShow, setModalCalendarShow] = React.useState(false);
    const [modalCalendarImportShow, setModalCalendarImportShow] = React.useState(false);
    const [showChampModal, setShowChampModal] = React.useState(false);

    const [prepopulateCalendarDate, setPrepopulateCalendarDate] = React.useState(null);
    const [editRace, setEditRace] = React.useState(null);

    const deleteCalendar = item => {
        deleteCalendarData( db, item);
    }

    const editCalendar = item => {
        setEditRace(item);
        setModalCalendarShow(true);
    }

    const renameChampionship = () => {
        setShowChampModal(true);
    }

    const importCalendar = () => {
        setModalCalendarImportShow(true);
    }

    const refreshImage = () => {
        const settings = { method: "Get" };
        fetch("/workers/calendarbanner?champ=" + props.championship.id , settings);
    }

    const onChampEdit = (champId, newLabel) => {
        if( newLabel) {
            props.calendar.forEach( item => {
                if( item.championshipLabel !== newLabel) {
                    const ref = db.collection(CALENDAR_COLLECTION).doc(item.id);
                    return ref.set({"championshipLabel": newLabel},{ merge: true }
                    );
                }
            });
            props.championship.label = newLabel;
        }
        setShowChampModal(false);
    }

    const openAddRace = () => {
        if(props.calendar.length > 0) {
            let tmp = props.calendar.slice();
            const last = tmp.pop();
            let newdate = last.date.toDate();
            newdate.setDate(newdate.getDate() + 7);
            setPrepopulateCalendarDate(newdate);
        } else {
            let newdate = new Date();
            newdate.setHours(21,0);
            setPrepopulateCalendarDate( newdate);
        }
        setModalCalendarShow(true);
    }

    const onCalendarModalHide = () => {
        setModalCalendarShow(false);
        if( editRace) {
            setEditRace(null);
        }
    }

    const lblNoTelemetry = (item) => {
        return item.results ? "Results":"Info";
    }

    return (        
        <>
       <ButtonGroup size="sm" style={{float:"right"}}>
            {props.championship && props.championship.banner_calendar &&
            <Button key="banner-dl" variant="outline-dark" href={props.championship.banner_calendar} target="_blank">
                <img alt="fs" src="/static/file-image.svg" style={{width: 20}} />
            </Button>
            }
            <ShareComponent title={props.league.label + " " + props.championship + " Calendar"} twitterHandler={props.league.twitter || ""} placement={'left'} />
        </ButtonGroup>
        <Table striped hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Date</th>
                <th>Circuit</th>
                <th>Format</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            {props.calendar && props.calendar.length > 0 ? props.calendar.map( (item,i) => {
                return <tr key={item.id}>
                    <td>{i+1}</td>
                    <td>{dateForEvent(item.date.toDate())}</td>
                    <td>{trackFormat(item.trackId)}</td>
                    <td>{raceFormat(item.format)}</td>
                    <td>
                        {item.telemetry ?
                        <Button size="sm" variant='outline-primary' as={Link} to={`/session/${item.telemetry}`}>Results</Button>
                        :
                        <Button size="sm" variant='outline-primary' onClick={()=>{props.onSelectedRace(item)}}>{lblNoTelemetry(item)}</Button>
                        }
                    </td>
                    <td>
                    {isLeagueAdmin( props.league) &&
                        <DropdownButton style={{float:"right"}} as={ButtonGroup} variant='link' size='sm' title="..." id={`dropdown-${item.id}`}>
                            <Dropdown.Item as={Button} onClick={() => editCalendar(item)}>Edit</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as={Button} onClick={() => deleteCalendar(item)}>Delete</Dropdown.Item>
                        </DropdownButton>
                    }
                    </td>
                </tr>
            }):
            <tr key={"nodata"}><td colSpan={6}>
            <Alert key="nodata" variant="light">
                    No Calendar yet
            </Alert>
            </td></tr>
            }
            </tbody>
        </Table>
        {isLeagueAdmin( props.league) &&
            <ButtonGroup>
                <Button variant='outline-primary' onClick={()=> openAddRace() }>Add Race</Button>
                <Button variant='outline-primary' onClick={()=> importCalendar() }>Import</Button>
                <Button variant='outline-primary' onClick={()=> renameChampionship() }>Rename Championship</Button>
                <Button variant='outline-primary' onClick={()=> refreshImage() }>Refresh Image</Button>
            </ButtonGroup>
        }
        {props.championship && isLeagueAdmin( props.league) &&
        <>
            <CalendarModal editRace={editRace} calendarDate={prepopulateCalendarDate}  league={props.league} championship={props.championship} show={modalCalendarShow}  onHide={() => onCalendarModalHide()} />
            <CalendarImportModal show={modalCalendarImportShow} leagueid={props.league.id}  championship={props.championship}  onHide={() => setModalCalendarImportShow(false)} />
            <ChampionshipNewModal leagueid={props.league.id} championship={props.championship} show={showChampModal} onHide={(newID, label)=>{onChampEdit( newID, label)}} />
        </>
        }
        </>
    );
}
