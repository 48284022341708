import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image'

const Faq = props => {
    return (<>
        <Header></Header>
        <main className="bg-light">
            <div
            className="bg-image p-5 text-center shadow-1-strong mb-4 text-white"
            style={{backgroundImage: 'url(/static/images/p1.jpg)'}}
            >
                <h1 className='font-face-f1'>FAQ</h1>
            </div>
        <Container>
            <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header>I want to use this tool</Accordion.Header>
                <Accordion.Body>
                RaceMapper is still under development, it's not yet fully automated. If you are a league owner and you want to use it for your league or 
                if you are a driver of one of the major leagues, please contact me on <a href="https://discord.gg/egyErhzUqB">Discord</a><br /><br />
                If you feel brave, you can set your telemetry to 140.238.211.120:20763 and check the results <a href="https://f122.racemapper.app/league/test">here</a>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>How do I set up my game?</Accordion.Header>
                <Accordion.Body>
                RaceMapper needs one of the drivers or one of the spectators to set in game Telemetry option accordly:
                <br /><br />
                <Image src='/static/faq/telemetry.jpg' />
                <br /><br />
                <ul>
                    <li>UDP Telemetry: <b>ON</b></li>
                    <li>UDP IP Address: <i>RaceMapper IP address you received</i></li>
                    <li>UDP Port: <i>RaceMapper PORT you received</i></li>
                </ul>
                You should ask all partecipants to set  "Show player names" <b>ON</b> in order to have their names displayed.
                Otherwise you will see all of them as "Player"
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Do the other players have to set up their game?</Accordion.Header>
                <Accordion.Body>
                No, but it helps if they set in their Telemetry game option "Show player names" to <b>ON</b>.
                Otherwise game will send <i>Player</i> as the player name.
                "Your Telemetry" can be restricted, but if the player set it to public RaceMapper will display more informations like 
                ERS and FUEL consumption.<br /><br />
                <Image src='/static/faq/player.jpg' />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>How much does it cost?</Accordion.Header>
                <Accordion.Body>
                    It's Free and it always will be. If the costs to run the servers will raise too much, we might add some features for premium users.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>What platform does it support?</Accordion.Header>
                <Accordion.Body>
                All platform, you just have to set your telemetry data accordly.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>I don't want to set the telemetry, may I use RaceMapper?</Accordion.Header>
                <Accordion.Body>
                Of course! you can use RaceMapper to manage your league, although you'll have to insert races results manually.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>What Games does it support?</Accordion.Header>
                <Accordion.Body>
                Currently only F1-22. We have older version for <a href="https://racing-f12021.web.app" target="_blank" rel="noreferrer">F1-2021</a> and F1-2020 but they are not supported anymore.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>I want to help</Accordion.Header>
                <Accordion.Body>
                As you can see, we might need someone to help with graphics and/or UI/UX.<br />
                Or you can simple <a href="https://discord.gg/egyErhzUqB">join our Discord server</a> to help others using the tool.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>I have another question</Accordion.Header>
                <Accordion.Body>
                Please <a href="https://discord.gg/egyErhzUqB">join our Discord server</a>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <br /><br />
        </Container>
        </main>
        <Footer></Footer>
        </>
    );
};


export default Faq;
