import React, { Component } from 'react';
import {driverFormat, lapTimeFormat} from '../helpers/format';

import Table from 'react-bootstrap/Table';

class PitLaneTimes extends Component {

    render() {
      return(
        <div>
<Table striped hover size="sm">
  <tbody>
{ this.props.pitlanetimes && this.props.pitlanetimes.map( (item, i) => (
    <tr key={`pitstoptime-${i}`}>
        <td key={`pitstoptime-${i}-num`}>{driverFormat(this.props.partecipants[item.data.vehicleIdx])}</td>
        <td key={`pitstoptime-${i}-lane`}>{lapTimeFormat(item.data.pitLaneTime)}</td>
        <td key={`pitstoptime-${i}-lap`}>lap {item.data.lapNum}</td>
    </tr>
    ))
}
  </tbody>
</Table>
        </div>);
    }
  }
  
  export default PitLaneTimes;