import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
//import ButtonGroup from 'react-bootstrap/ButtonGroup'
//import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
//import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/DropdownButton';

import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {lapTimeFormat,sectorTimeFormat,driverFormat} from '../helpers/format';
import {TYRE_VISUAL_IMG,TYRE_VISUAL} from 'racemapper_shared/enums';

import RaceLog from '../components/RaceLog';
import DriverHelps from '../components/DriverHelps';


export default function LapModal(props) {

  function theretically( tlap, flap) {
      if( tlap.toFixed(3) === flap.toFixed(3)) {
        return "";
      }
      let lbl = "Theoretically fastest lap:  " + lapTimeFormat(tlap);

      const delta = ((tlap.toFixed(3) - flap) / 1000).toFixed(3);
      if( delta < 0) {
        lbl += " (" + delta + ")";
      }
      return lbl;
  }

  const LAPSTATUS = {
      0:"",
      1:"Inv."
  }

  function lapSpeed(item) {
    if( item.speed) {
      return item.speed.toFixed(1) + 'km/h';
    }
    return '';
  }

  function hasExtra() {
    return props.laps.laps && props.laps.laps[0] && props.laps.laps[0].fuelRemainingLaps;
  }

  function lapBadge(item) {
    let badges = [];

    if( item.sc === 1) {
      badges.push(<Badge key="sc" bg="warning">SC</Badge>);
    } else if( item.sc === 2) {
      badges.push(<Badge key="vsc" bg="warning">VSC</Badge>);
    }
    if( item.pit) {
      badges.push(<Badge key="pit" bg="info">PIT</Badge>);
    }

    if( item.invalid) {
      badges.push(<Badge key="inv" bg="danger">{LAPSTATUS[item.invalid]}</Badge>);
    }
  
    return badges;
  }

  function normalFasterOrFastest( time, faster, fastest) {
    if( time === fastest) {
      return "fastest";
    }
    if( time === faster) {
      return "faster";
    }
    return "";
  }


  const damage = (item) => {
    let lbl = [];
    if( item.frontLeftWingDamage || item.frontRightWingDamage) {
      lbl.push('FW ' + item.frontLeftWingDamage + "-" + item.frontRightWingDamage);
    }
    if( item.rearWingDamage) {
      lbl.push('RW ' + item.rearWingDamage);
    }
    if( item.diffuserDamage) {
      lbl.push('DI ' + item.diffuserDamage);
    }
    if( item.sidepodDamage) {
      lbl.push('SP ' + item.sidepodDamage);
    }
    if( item.floor) {
      lbl.push('FL ' + item.floor);
    }
    if( item.drsFault) {
      lbl.push('DRS');
    }
    return lbl.join("|");
  }

  return (
    <>
    <Modal
      {...props}
      fullscreen={false}
      size="lg"
      key="modal-laps"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.laps.partecipant && driverFormat(props.laps.partecipant)}
          <span style={{display:"none"}}>{props.laps.idx && props.laps.idx}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.laps.speed &&
          <h6>Speed Trap: {props.laps.speed.toFixed(1)} km/h</h6>
        }
<Tabs defaultActiveKey="time" id="race-lab" className="mb-3">
  <Tab eventKey="time" title="Time">
    <Table striped bordered hover size="sm" className="racetab">
      <thead>
        <tr key={`lapmodal-header`}>
          <th key={`lapmodal-header-lap`}>Lap</th>
          <th key={`lapmodal-header-s1`}>S1</th>
          <th key={`lapmodal-header-s2`}>S2</th>
          <th key={`lapmodal-header-s3`}>S3</th>
          <th key={`lapmodal-header-time`}>Time</th>
          <th key={`lapmodal-header-item`}></th>
          <th key={`lapmodal-header-speed`}></th>
          <th key={`lapmodal-header-pos`}>
              <OverlayTrigger
              key={`tooltip-p`}
              placement='auto'
              overlay={
                <Tooltip id={`tooltip-p`}>
                Position at the end of the lap.
                </Tooltip>
              }
            >
              <Button variant="link">P</Button>
            </OverlayTrigger>
          </th>
          <th key={`lapmodal-header-tyre`}></th>
          <th key={`lapmodal-header-aid`}></th>
        </tr>
      </thead>
      <tbody>
    { props.laps.laps && props.laps.laps.map( item => (
        <tr key={`lapmodal-${item.lap}`}>
          <td key={`lapmodal-lap`} className="cell-small">{item.lap}</td>
          <td key={`lapmodal-s1`} className={`${normalFasterOrFastest(item.sector1TimeInMS,props.laps.sector1TimeInMS, props.laps.fastestOverall.s1)} centerTxt`}>{sectorTimeFormat(item.sector1TimeInMS)}</td>
          <td key={`lapmodal-s2`} className={`${normalFasterOrFastest(item.sector2TimeInMS,props.laps.sector2TimeInMS, props.laps.fastestOverall.s2)} centerTxt`}>{sectorTimeFormat(item.sector2TimeInMS)}</td>
          <td key={`lapmodal-s3`} className={`${normalFasterOrFastest(item.sector3TimeInMS,props.laps.sector3TimeInMS, props.laps.fastestOverall.s3)} centerTxt`}>{sectorTimeFormat(item.sector3TimeInMS)}</td>
          <td key={`lapmodal-time`} className={normalFasterOrFastest(item.lapTime,props.laps.fastest, props.laps.fastestOverall.lapTime)}>
            {lapTimeFormat(item.lapTime)}
          </td>
          <td key={`lapmodal-item`}>{lapBadge(item)}</td>
          <td key={`lapmodal-speed`}>{lapSpeed(item)}</td>
          <td key={`lapmodal-pos`} className="cell-small">{item.carPosition}</td>
          <td key={`lapmodal-tyre`}>
            <img className="tyre" src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[item.visualTyreCompound]}.png`} alt={TYRE_VISUAL[item.visualTyreCompound]} width="20" height="20" />        
          </td>
          <td key={`lapmodal-aid`}>
          <DriverHelps antiLockBrakes={item.antiLockBrakes} tractionControl={item.tractionControl} />
          </td> 
        </tr>
        ))
    }
      </tbody>
    </Table>
  </Tab>
  <Tab eventKey="extra" title="Extra" disabled={!hasExtra()}>
    <Table striped bordered hover size="sm" className="racetab">
        <thead>
          <tr key={`lapmodale-header`}>
            <th key={`lapmodale-header-lap`}>Lap</th>
            <th key={`lapmodale-header-time`}>Time</th>
            <th>Fuel</th>
            <th>ERS</th>
            <th>Tyres</th>
            <th></th>
            <th>Eng.</th>
            <th>Damage</th>
          </tr>
        </thead>
        <tbody>
      { props.laps.laps && hasExtra() && props.laps.laps.map( item => (
          <tr key={`lapmodal-${item.lap}`}>
            <td key={`lapmodale-lap`} className="cell-small">{item.lap}</td>
            <td key={`lapmodale-time`} className={normalFasterOrFastest(item.lapTime,props.laps.fastest, props.laps.fastestOverall.lapTime)}>
              {lapTimeFormat(item.lapTime)}
            </td>
            <td className="cell-small">{item.fuelRemainingLaps && item.fuelRemainingLaps.toFixed(2)}</td>
            <td className="cell-small">{item.ersStoreEnergy && (item.ersStoreEnergy / 40000).toFixed(0)}%</td>
            <td className='centerTxt'>
              {item.tyresWear &&
                <>
                {item.tyresWear[2].toFixed(0)}% -  {item.tyresWear[3].toFixed(0)}%<br />
                {item.tyresWear[0].toFixed(0)}% -  {item.tyresWear[1].toFixed(0)}%
                </>
              }
            </td>
            <td className='centerTxt'>
                {item.tyresInnerTemperature[2].toFixed(0)}° -  {item.tyresInnerTemperature[3].toFixed(0)}°<br />
                {item.tyresInnerTemperature[0].toFixed(0)}° -  {item.tyresInnerTemperature[1].toFixed(0)}°
            </td>
            <td className="cell-small">{item.engineTemperature}°</td>
            <td className='centerTxt'>{damage(item)}</td>
          </tr>
        ))
      }
        </tbody>
      </Table>
  </Tab>
</Tabs>


<p>
{ props.laps.teoretically && 
  <i>
    {theretically(props.laps.teoretically, props.laps.fastest)}
  </i> 
}
</p>

{ props.logs && 
<RaceLog logs={props.logs} partecipants={props.partecipants} />
}

        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' size="sm" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
    );
  }

