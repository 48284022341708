import React from 'react';

import { TYRE_VISUAL_IMG } from 'racemapper_shared/enums';

  export default function RacePitChart ( props) {  

    const lapsTr = () => {
      let tds = [];
      const maxLaps = props.results.reduce( (max,obj) => obj.numLaps > max.numLaps ? obj : max);
      for (let index = 0; index < maxLaps.numLaps; index++) {
        tds.push(<td key={index} style={{textAlign:"right"}}>{index+1}</td>);
      }
      return tds;
    }

    const lapsTd = (item) => {
      let tds = [];
      let c = 0;
      // if driver pits first lap tyreStintsEndLaps doesn't have the info
      if( item.tyreStintsVisual.length > item.tyreStintsEndLaps.length) {
        item.tyreStintsEndLaps.unshift(1);
      }
      item.tyreStintsEndLaps.filter( i => i !== 0).map( (endslap, j) => {
        const span = endslap === 255 ? item.numLaps : endslap + 1;
        const tyre = item.tyreStintsVisual[j];
        const td = <td key={`driver:${item.position}-${j}`} style={{borderWidth:"1px",backgroundColor:TYRE_VISUAL_IMG[tyre]}} colSpan={span - c}>&nbsp;</td>;
        tds.push(td);
        c = span;
      });
      return tds;
    }
  
    return ( 
        <table width={"100%"}>
          <tbody>
          <tr>
            <td>&nbsp;</td>
            {lapsTr()}
          </tr>
          {props.results.filter( r => r.position > 0 && r.resultStatus !== 6)
          .sort((a, b) => a.position - b.position)
          .map( item => {
            return <tr key={`driver:${item.position}`}><td>{props.partecipants[item.driver] ? props.partecipants[item.driver].label ?? props.partecipants[item.driver].name :  ""}</td>{lapsTd( item)}</tr>
          })
          }
          </tbody>
        </table>
    );
  }
  