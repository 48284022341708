export const TRACK_DATA = {
    3: {
        s1: 2289.202148,
        s2: 4019.700928
    },
    5: {
        s1: 2399.068848,
        s2: 3315.527588
    },
    7: {
        s1: 1822.759766,
        s2: 4315.086914
    },
    9: {
        s1: 1722.884766,
        s2: 3256.642578
    },
    10: {
        s1: 2284.171875,
        s2: 5073.234375
    },
    11: {
        s1: 1924.765625,
        s2: 3753.562500
    },
    13: {
        s1: 2244.677734,
        s2: 4861.271484
    },
    16: {
        s1: 1183.708984,
        s2: 3126.998047
    },
    17: {
        s1: 1359.312500,
        s2: 3196.243652
    },
    19: {
        s1: 2049.679688,
        s2: 3924.722656
    },
    27: {
        s1: 1830.367188,
        s2: 3641.375000
    },
    28: {
        s1: 1445.878906,
        s2: 3518.185547
    },
    29: {
        s1: 2121.627441,
        s2: 4193.245605
    },
    30: {
        s1: 1872.522461,
        s2: 3720.675293
    }
}