import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from 'react-bootstrap/Spinner';

import { db } from "../services/firebase";
import {getOrganizationsData} from "racemapper_shared/data";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

const LeagueList = props => {

    const [loading, setLoading] = React.useState(true);
    const [organizations, setOrganizations] = React.useState([]);
    
    useEffect(() => {
        const getData = () => {
            try {
                getOrganizationsData( db).then( data => {
                    setLoading(false);
                    setOrganizations( data);
                });
            } catch( error) {
                console.log(error);
            }
        }
        getData();
    }, []);

    
    return (<>
            <Header></Header>
            <main className="bg-light">
            <div
            className="bg-image p-5 text-center shadow-1-strong mb-4 text-black"
            style={{backgroundImage: 'url(/static/images/b1.jpg)'}}
            >
                <h1 className='font-face-f1'>Leagues</h1>
            </div>
            <Container>
                {loading && (
                        <div className="d-flex justify-content-center">
                            <Spinner animation="grow" variant="secondary" />
                        </div>
                 )}
                <Row xs={1} md={3} className="g-4">
                    {
                        organizations.map(item => (
                            <Col key={`col-${item.id}`}>
                                <Link to={{pathname:`/league/${item.id}`, session: item}}>
                                <Card className="bg-dark text-white" key={item.id} style={{minHeight:"170px"}}>
                                <Card.Body>
                                    <Card.Title className='font-face-f1-bold'>{item.label}</Card.Title>
                                    <Card.Text className='font-face-f1'>
                                        {item.payoff}
                                    </Card.Text>                                    
                                </Card.Body>
                                </Card>
                                </Link>

                            </Col>
                        ))
                    }
                </Row>
            </Container>
            </main>
            <Footer></Footer>
            </>
    );
};
export default LeagueList;