exports.dateForEvent = (date) => {
    const options = { 
      weekday: 'long',
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return date.toLocaleDateString( undefined, options);
}

exports.smallDateForEvent = (date, locale = undefined) => {
  const options = { 
    month: 'short',
    day: 'numeric',
  };

  return date.toLocaleDateString( locale, options);
}

exports.raceFormat = (format) => {
    switch (format) {
      case 0:
        return "ShortQ + 50% Race";
      case 7:
        return "ShortQ + Sprint Race + 50% Race";
      case 2:
        return "Full Q + 50% Race";
      case 6:
        return "Full Q + Sprint Race + 50% Race";
      case 3:
        return "OneLapQ + 50% Race";
      case 1:
        return "OneLapQ + Sprint Race + 50% Race";
      case 4:
        return "ShortQ + 100% Race";
      case 5:
        return "Full Q + 100% Race";
      case 8:
          return "Sprint Race + 50% Race";
      default:
        return "Normal Race";
    }
  }

exports.isSprintRace = (format) => {
  return [1,6,7,8].includes(format);  
}