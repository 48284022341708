import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import StandingConstructorsTopBanner from "./StandingConstructorsTopBanner";

import { isLeagueAdmin } from '../helpers/auth';

import { TEAM_F1, TRACK , TRACK_SHORT } from 'racemapper_shared/enums';

export default function ChampionshipStandingConstructors ( props) {  
    const [showStandingTopBanner, setShowStandingTopBanner] = React.useState(false);

    const roundLbl = (hint, item) => {
        let lbl = TRACK_SHORT[item.trackId];
        if( item.results_provisional) {
            lbl += "*";
        }
        let retvalue = null;
        if( item.telemetry.length > 0) {
            retvalue = <Button variant='link' size='sm' style={{padding:0}} as={Link} to={`/session/${item.telemetry}`}>{lbl}</Button>
        } else {
            retvalue = <Button variant='link' size='sm' style={{padding:0}} onClick={()=>{props.onSelectedRace(item)}}>{lbl}</Button>
        }
        
        return <OverlayTrigger overlay={<Tooltip>{hint}</Tooltip>}>{retvalue}</OverlayTrigger>
    }

    const gapBetween = ( arr, to, from) => {
        if( from === 0) {
            return "";
        }
        return arr[from].tot - arr[to].tot;
    }

    const showConstructoPoints = (teamId) => {
        return props.calendar.map( (race) => {
            let lbl = "";
            if(race.results) {
                let tot = 0;
                const team = race.results.filter( item => item.teamId === teamId);
                //console.log(team);
                team.forEach( item => {
                    tot += item.teamPoints;
                });
                lbl = String(tot);
            }
            return <td className='centerTxt' key={`ctd-${teamId}-${race.id}`}>{lbl}</td>    
        });
    }

    const openTopBanner = () => {
        setShowStandingTopBanner(true);
    }

    return (        
        <>
        <ButtonGroup size="sm" style={{float:"right"}}>
            {props.championship && props.championship.banner_constructors &&
            <Button key="banner-dl" variant="outline-dark" href={props.championship.banner_constructors} target="_blank">
                <img alt="fs" src="/static/file-image.svg" style={{width: 20}} />
            </Button>
            }
        </ButtonGroup>
        <Table striped hover size="sm">
            <thead>
                <tr>
                    <th colSpan={3}></th>
                {props.calendar && props.calendar.length > 0 && props.calendar.map( (item,i) => {
                    return <th key={`th-${item.id}`} className='smallFont centerTxt'>{roundLbl( "R" + (i+1), item)}</th>
                })}
                    <th colSpan={2}></th>
                </tr>
                <tr>
                    <th className='smallFont'>P</th>
                    <th className='smallFont'>TEAM</th>
                    <th className='smallFont centerTxt'>PTS</th>
                {props.calendar && props.calendar.length > 0 && props.calendar.map( (item) => {
                    return <th key={`team-${item.id}`} className='centerTxt'>
                    <img className='flag-icon-small' src={`${process.env.PUBLIC_URL}/static/flags/${item.trackId}.png`} alt={TRACK[item.trackId]} />
                    </th>
                })}
                    <th className='smallFont'>+-</th>
                    <th className='smallFont'>1st</th>
                </tr>
            </thead>
            <tbody>
            {props.constructorStandings && props.constructorStandings.length > 0 ?
                props.constructorStandings.map( (item,i) => {
                        return <tr key={`team-${i}`}>
                            <td>{i+1}</td>
                            <td>{TEAM_F1[item.teamId]}</td>
                            <td className='centerTxt' style={{borderWidth:"1px",borderColor:"#999"}}>{item.tot}</td>
                            {showConstructoPoints(item.teamId)}
                            <td className='centerTxt'>{gapBetween( props.constructorStandings, i-1, i)}</td>
                            <td className='centerTxt'>{gapBetween( props.constructorStandings, 0, i)}</td>
                        </tr>
            })
            :
            <tr key={"nodata"}><td colSpan={30}>
            <Alert key="nodata" variant="light">
                    No data yet
            </Alert>
            </td></tr>
            }
            </tbody>
        </Table>
        <br />
        <span><b>*</b> provisional result</span>
        <br />
        <br />
        {isLeagueAdmin( props.league) && props.constructorStandings && props.constructorStandings.length >0 &&
        <>
                   </>
        }
        </>
    );
}
