import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Formula1/Formula1-Regular.ttf';
import './fonts/Formula1/Formula1-Bold.ttf';

import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>

    <HelmetProvider>
      <App>
        <Helmet>
          <meta name="description" content="Race Telemetry for F1 2022"/>
          <title>RaceMapper</title>
          <meta name="twitter:creator" content="@emln_22"/>
        </Helmet>
      </App>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();