import React from 'react';
import Table from 'react-bootstrap/Table';

import { pointsSystem , fastestLapPoints, fastestLapPosition , polePositionPoints } from "racemapper_shared/raceUtils";
export default function PointSystemComponent ( props) {  

    const getPointsArray = () => {
        const points = props.points.classification ?? pointsSystem();
        return points;
    }

    const getFastestLapPoint = () => {
        return props.points.fastestlap ?? fastestLapPoints();
    }
    const getPointsLbl = () => {
        return getFastestLapPoint() === 1 ? "point" : "points";
  
    }
    const getFastestLapPosition = () => {
        return props.points.fastestlapPositionLimit ?? fastestLapPosition();
    }

    const getPolePosition = () => {
        return props.points.poleposition ?? polePositionPoints();
    }


    const lblPosition = (pos) => {
        pos++;
        if( pos === 1) {
            return "1st";
        }
        if( pos === 2) {
            return "2nd";
        }
        if( pos === 3) {
            return "3rd";
        }
        return pos + "th";
    }

    return (        
        <>
        {props.points && 
                    <Table size='sm'>
                        {!props.hidetitle &&
                        <thead>
                            <tr>
                                <th colSpan={99}>Points system</th>
                            </tr>
                        </thead>
                        }
                        <tbody>
                            <tr>
                            <td>Pos.</td>
                                {
                                getPointsArray().map( (p, i) => {
                                    return <td key={`pos-${p}`} className='centerTxt'>{lblPosition(i)}</td>
                                })}
                            </tr>
                            <tr>
                            <td>Points.</td>
                                {getPointsArray().map( (p) => {
                                    return <td key={`points-${p}`} className='centerTxt'>{p}</td>
                                })}
                            </tr>
                        </tbody>
                    </Table>                   
        }
        <ul>
            {props.points?
                <li><b>{getFastestLapPoint()}</b> extra {getPointsLbl()} for <b>fastest lap</b> if classified inside the top <b>{getFastestLapPosition()}</b>
                </li>
            :<></>}
            {props.points && getPolePosition() !== 0 ?
                <li><b>{getPolePosition()}</b> extra points for <b>pole position</b></li>
            :<></>}
            <li><b>Reserve</b> drivers do <b>{props.points && props.points.wildcard4constructor ? "" : "NOT"}</b> score points for constructor championship</li>
        </ul>
        </>
    );
  }
