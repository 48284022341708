import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { db } from "../../services/firebase";
import { setResultForRace , calculateClassificationFromCalendar , getCalendarData } from "racemapper_shared/data";
import { isSprintRace } from "racemapper_shared/formats";
import { pointsSystem , sprintRacePoints , fastestLapPoints, fastestLapPosition , polePositionPoints , resultStatusDNF } from "racemapper_shared/raceUtils"

import TeamSelect from '../TeamSelect';
const fetch = require('node-fetch');

export default function ModalRaceResults ( props) {  
    const [isLoading, setLoading] = React.useState(false);
    const [drivers, setDrivers] = React.useState([]);
    const [standing, setStanding] = React.useState([]);
    const [driverchamps, setDriversChamp] = React.useState([]);

    const onFormSave = async (e) => {
        e.preventDefault();
        if( isLoading) {
            return;
        }
        setLoading( true);
        let data = [];

        for (let index = 0; index < 20; index++) {
            const strpos =  String(index + 1);
            const label = document.getElementById("driver-" + strpos).value.trim();
            if( label) {
                const gripposition = parseInt(document.getElementById("gridpos-" + strpos).value);
                const pole = gripposition === 1;
                const fl = document.getElementById("fastest-" + strpos).checked;
                let sprint = 0;
                if( isSprintRace(props.race.format)) {
                    sprint = parseInt( document.getElementById("sprint-" + strpos).value);
                }
                const dpoints =  getPoints(index + 1, sprint, pole, fl);
                const wc =  document.getElementById("reserve-" + strpos).checked;
                let row = {
                    "position" : index + 1,
                    "fastestlap" : fl,
                    "poleposition" : pole,
                    "gridPosition" : gripposition,
                    "label" : label,
                    "oldname": "",
                    "points": dpoints,
                    "reserve" : wc,
                    "resultStatus": document.getElementById("dnf-" + strpos).checked ? 4 : 1,
                    "teamId":  parseInt(document.getElementById("team-" + strpos).value),
                    "teamPoints": (!wc || props.points.wildcard4constructor === true) ? dpoints : 0
                }
                if( isSprintRace(props.race.format)) {
                    row["sprintRacePosition"] = sprint;
                }
                data.push(row);
            }
        }
        const provisional = e.target['provisional'].checked;
        setResultForRace( db, data, props.calendarid, provisional)
        .then(()=>{
            setLoading( false);
            props.onHide( true);
            // regen banner
            const settings = { method: "Get" };
            fetch("/workers/standingsbanner?champ=" + props.championshipid , settings);
        }).catch(e => {
            window.alert(e);
            setLoading( false);
        });
        
    }
/*    const getPointLabel = (pos) => {
        const strpos = String(pos);
        if (!document.getElementById("gridpos-" + strpos)) {
            return "";
        }
        const gridposition = parseInt(document.getElementById("gridpos-" + strpos).value || 0)
        const pole = gridposition === 1;
        const fl = document.getElementById("fastest-" + strpos).checked || false;
        const p = getPoints( pos, pole, fl);
        return p;
    }
*/
    const getPoints = (pos, sprint, pole, fl) => {
        const pointss = props.points.classification ?? pointsSystem();
        const flpoints = props.points.fastestlap ?? fastestLapPoints();
        const flposition = props.points.fastestlapPositionLimit ?? fastestLapPosition();
        const polePosition = props.points.poleposition ?? polePositionPoints();
        const sprintPoints = props.points.sprintRacePoints ?? sprintRacePoints();
        
        let p = pointss[pos-1] || 0;
        
        if( fl && pos <= flposition) {
            p += flpoints;
        }
        if( pole) {
            p += polePosition;
        }
        if( isSprintRace(props.race.format)) {
            p += sprintPoints[sprint-1] || 0;
        }

        return p;
    }

    const hideModal = () => {
        props.onHide( false);
    }

    const driverSelect = (driver, items) => {
        return <Form.Select defaultValue={driver.label} size='sm' id={`driver-${driver.position}`} onChange={() => {onDriverChange(driver)}}>
            <option value=""></option>
            {items.map( drv => {
                return <option value={drv} key={`driver-${drv}`}>{drv}</option>
            })}
            <option value={-1}>New driver</option>
            </Form.Select>
    }

    const onDriverChange = (driver) => {
        const strpos = String(driver.position);
        const componenet = document.getElementById("driver-" + strpos);
        if( componenet.value === "-1") {
            const resp = window.prompt("Driver Name");
            if( resp) {
                let newv = resp.trim()
                var opt = document.createElement('option');
                opt.value = newv;
                opt.innerHTML = newv;
                const lastc = componenet.lastChild;
                componenet.removeChild(lastc);
                componenet.appendChild(opt);
                componenet.appendChild(lastc);

                componenet.value = newv;
            } else {
                componenet.value = "";
            }
        } else {
            const searchTeam = driverchamps.find( i => i.label === componenet.value);
            if( searchTeam) {
                document.getElementById("team-" + strpos).value = searchTeam.teamId;
            }
        }
    }
    const positionSelect = (driver, sel, idprefix) => {
        return <Form.Select defaultValue={sel} size='sm' id={`${idprefix}-${driver.position}`}>
            <option value={0}></option>
            {[...Array(20)].map( (_,i) => {
                return <option value={i+1} key={`${idprefix}-${driver.position}-${i+1}`}>{i+1}</option>
            })}
            </Form.Select>
    }

    const flSelected = (position) => {
        for (let i = 1; i < 21; i++) {
            if( i !== position) {
                document.getElementById("fastest-" + String(i)).checked = false;
            }
        }
    }
    
    const onEnterModal = () => {
        
        getCalendarData( db, props.championshipid).then( dataModel => {
            const ret = calculateClassificationFromCalendar( dataModel, [props.calendarid]);
            setDriversChamp(ret[0]);
            const drivers = ret[0].map( i => i.label);
            setDrivers(drivers);
            let data = [];
            for (let index = 0; index < 20; index++) {
                let row = {
                    "position" : index + 1
                };
                if( props.race && props.race.results) {
                    const match = props.race.results.find( i => i.position === row.position);
                    if( match) {
                        row = {
                            "position" : index + 1,
                            "fastestlap" : match.fastestlap,
                            "gridPosition" : match.gridPosition,
                            "sprintRacePosition" : match.sprintRacePosition || 0,
                            "label" : match.label,
                            "points": match.points,
                            "reserve" : match.reserve,
                            "resultStatus": match.resultStatus,
                            "teamId": match.teamId,
                            "teamPoints": match.teamPoints
                        };
                    }
                }
                data.push(row);
            }
            setStanding(data);
        });
    }

    return (        
        <Modal size="xl" onHide={hideModal} centered {...props} onEnter={onEnterModal} >
          <Form onSubmit={onFormSave}>
          <Modal.Header closeButton>
            <Modal.Title>Add Race Results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped hover size="sm">
                <thead>
                        <tr>
                        <th className='smallFont'>P</th>
                        <th className='smallFont'>Driver</th>
                        <th className='smallFont'>Team</th>
                        <th className='smallFont'>Grid Position</th>
                        {isSprintRace(props.race.format) &&
                        <th className='smallFont'>Sprint race Pos.</th>
                        }
                        <th className='smallFont'>FastestLap</th>
                        <th className='smallFont'>Reserve</th>
                        <th className='smallFont'>DNF</th>
                        </tr>
                </thead>
                <tbody>
                {standing && standing.map((item) =>{
                    return <tr key={`sss-${item.position}`}>
                     <td>{item.position}</td>
                     <td>{driverSelect(item, drivers)}</td>
                     <td><TeamSelect selected={item.teamId} selectid={`team-${item.position}`} empty={true} /></td>
                     <td className='centerTxt' id={`gridl-${item.position}`}>{positionSelect(item, item.gridPosition, 'gridpos')}</td>
                     {isSprintRace(props.race.format) &&
                     <td className='centerTxt' id={`sprintl-${item.position}`}>{positionSelect(item, item.sprintRacePosition, 'sprint')}</td>
                     }
                     <td className='centerTxt'><Form.Switch id={`fastest-${item.position}`} label="" defaultChecked={item.fastestlap} onClick={() => flSelected(item.position)} /></td>
                     <td className='centerTxt'><Form.Switch id={`reserve-${item.position}`} label="" defaultChecked={item.reserve} /></td>
                     <td className='centerTxt'><Form.Switch id={`dnf-${item.position}`} label="" defaultChecked={item.resultStatus && resultStatusDNF().includes(item.resultStatus)} /></td>
                   </tr>
                })}
                </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => hideModal()}>
            Cancel
            </Button>
            <Button variant="primary" 
                disabled={isLoading}
                type="submit"
            >
            {isLoading ? 'Saving…' : 'Assign points'}
            </Button>
            <Form.Switch id="provisional" name="provisional" label="Provisional" />

          </Modal.Footer>
          </Form>
        </Modal>
    );
  }
