import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import {Helmet} from "react-helmet-async";

import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import ShareComponent from '../components/ShareComponent';

import { db } from "../services/firebase";
import { getOrganizationDetailData , getChampionshipsData , getCalendarByOrganisation , getTelemetrySessionsNoCaledar } from "racemapper_shared/data";
import { dateForEvent } from 'racemapper_shared/formats';
import { trackFormat } from '../helpers/format';
import { isLeagueAdmin } from '../helpers/auth';
import ChampionshipNewModal from '../components/ChampionshipNewModal';
import LeagueInfoModal from '../components/LeagueInfoModal';
import RaceDetail from '../components/RaceDetail';
import PointSystemCanvas from '../components/PointSystemCanvas';

import { basenameFormat , hostnameFormat } from "../helpers/urlformat";
const League = props => {

    const leagueID = props.match.params.leagueID;
    const maxRaces = 5;

    const location = useLocation()

    const [league, setLeague] = React.useState({});
    const [championships, setChampionships] = React.useState([]);
    const [nextCalendarsNumber, setNextCalendarsNumber] = React.useState(maxRaces);
    const [prevCalendarsNumber, setPrevCalendarsNumber] = React.useState(maxRaces);
    const [selectedRace, setSelectedRace] = React.useState(null);
    const [telemetryRaces, setTelemetryRaces] = React.useState([]);

    const [calendarPrev, setCalendarPrev] = React.useState([]);
    const [calendarNext, setCalendarNext] = React.useState([]);

    const [showChampModal, setShowChampModal] = React.useState(false);
    const [showEditInfoModal, setShowEditInfoModal] = React.useState(false);

    const [telemetryPage, setTelemetryPage] = React.useState(0);
    const [allTelemetry, setAllTelemetry] = React.useState({});

    useEffect(() => {
        const getCalendars = async(leagueID) => {
            try {
                const dataModel = await getCalendarByOrganisation( db, leagueID, "next", maxRaces);
                setCalendarNext( dataModel);
            } catch( error) {
                console.log(error);
            } 
            try {
                const dataModel = await getCalendarByOrganisation( db, leagueID, "prev", maxRaces);
                setCalendarPrev( dataModel);
            } catch( error) {
                console.log(error);
            }   
        }
        const getData = async(leagueID) => {
            try {
                const data = location.league ?? await getOrganizationDetailData( db, leagueID);
                setLeague( data);
            } catch( error) {
                console.log(error);
            }
        }
        const getChampData = async(leagueID) => {
            try {
                const arrayData = await getChampionshipsData( db, leagueID);
                setChampionships( arrayData);
            } catch( error) {
                console.log(error);
            }
        }

        const getTelemetryData = async(leagueID) => {
            try {
                const arrayData = await getTelemetrySessionsNoCaledar( db, leagueID, maxRaces);
                allTelemetry[0] = arrayData;
                setAllTelemetry(allTelemetry);
                setTelemetryRaces( arrayData);
            } catch( error) {
                console.log(error);
            }
        }
        getData( leagueID);
        getChampData( leagueID);
        getCalendars( leagueID);
        getTelemetryData( leagueID);
    },[leagueID, location.league]);

    const loadTelemetryPage = async (page) => {
        console.log(page);
        console.log(allTelemetry);

        if( allTelemetry[page]) {
            console.log("reuse");

            setTelemetryPage(page);
            setTelemetryRaces( allTelemetry[page]);
        } else {
            console.log("new");
            const lastdate = telemetryRaces[telemetryRaces.length - 1].created;
            const arrayData = await getTelemetrySessionsNoCaledar( db, leagueID, maxRaces, lastdate);
            allTelemetry[page] = arrayData;
            setAllTelemetry(allTelemetry);
            setTelemetryPage(page);
            setTelemetryRaces( arrayData);
        }
    }

    const onLeagueInfoEditing = (edited) => {
        setShowEditInfoModal(false); 
        if( edited) {
            getOrganizationDetailData(db, leagueID).then( data => { setLeague( data)});
        }        
    }
    const racelbl = (item) => {
        let ret = [trackFormat(item.trackId, true) , " " , dateForEvent(item.date.toDate())];
        if( item.telemetry) {
            ret = <Link to={`/session/${item.telemetry}`}>{ret}</Link>;
        } else {
            ret = <Link to={()=>{return false}} onClick={() => displayRaceDetail(item)}>{ret}</Link>;
        }
        return ret;
    }

    const raceComponent = (item, i, arr) => {
        return <div key={item.id}>
                <Row>
                    <Col sm>
                        <p className="mb-0">{racelbl(item)}</p>
                    </Col>
                    <Col sm>
                        <p className="text-muted mb-0"><Link to={{pathname:`/league/${leagueID}/champ/${item.championshipId}`, league: league}}>{item.championshipLabel}</Link> </p>
                    </Col>
                </Row>
                {(i < arr.length-1) &&
                    <hr />
                }
            </div>
    }

    const telemetryComponent = (item, i, arr) => {
        return <div key={item.id}>
                <Row>
                    <Col sm>
                        <p className="mb-0">{dateForEvent(item.created.toDate())}</p>
                    </Col>
                    <Col sm>
                        <p className="text-muted mb-0"><Link to={{pathname:`/session/${item.id}`, league: league}}>{item.label || item.id}</Link> </p>
                    </Col>
                </Row>
                {(i < arr.length-1) &&
                    <hr />
                }
            </div>
    }

    const nextRaces = ( races, n) => {
        const prev = races.filter(item => item.date.toDate() >= new Date());
        if( prev.length === 0) {
            return  <span>No races</span>;
        }
        let btn = undefined;
        let sliceTo = maxRaces;
        if (prev.length > maxRaces && n === maxRaces) {
            btn = <Button key="lessNext" variant="link" onClick={()=> setNextCalendarsNumber(999)}>More</Button>
        } else if( n === 999) {
            sliceTo = 999;
            btn = <Button key="moreNext" variant="link" onClick={()=> setNextCalendarsNumber(maxRaces)}>Less</Button>
        }
        
       return [prev.slice(0, sliceTo).map((item,i, arr) => (
                        raceComponent(item, i, arr)
                )), btn];
    }

    const prevRaces = ( races, n) => {
        const prev = races.filter(item => item.date.toDate() < new Date());
        if( prev.length === 0) {
            return  <span>No races</span>;
        }
        let btn = undefined;
        let sliceTo = maxRaces;
        if (prev.length > maxRaces && n === maxRaces) {
            btn = <Button key="morePrev" variant="link" onClick={()=> setPrevCalendarsNumber(999)}>More</Button>
        } else if( n === 999) {
            sliceTo = 999;
            btn = <Button key="lessPrev" variant="link" onClick={()=> setPrevCalendarsNumber(maxRaces)}>Less</Button>
        }
        
       return [prev.reverse().slice(0, sliceTo).map((item,i, arr) => (
                    raceComponent(item, i, arr)
                )), btn];
    }

    const orphantelemetryRaces = (sessions) => {
        return sessions.map((item,i, arr) => (
            telemetryComponent(item, i, arr)
        ));
    }

    const displayRaceDetail = (race) => {
        setSelectedRace( race);
    }

    return (
    <>
         <Helmet>
                <title>{league.label || leagueID}</title>
        </Helmet>
        <Header></Header>
        <main className="bg-light">
                <Container fluid className='py-3'>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/league" }}>Leagues</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {league.label || leagueID}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
                <Container>
                    <Row>
                    <Col lg={4}>
                        <Card mb={4} className="mb-4">
                            <Card.Body className="card-body text-center">
                                {league.label  &&
                                    <ButtonGroup size="sm" style={{position:"absolute", right:10, top:10}}>
                                        <ShareComponent title= {league.label || leagueID} twitterHandler={league.twitter || ""} placement={'left'} variant='outline' />
                                    </ButtonGroup>
                                }
                                <img src={league.profilepic || '/static/placeholder.png'} alt="avatar"
                                className="rounded-circle img-fluid" style={{width: "150px"}} />
                                <h5 className="my-3">{league.label || "..."}</h5>
                                <p className="text-muted mb-1">{league.payoff}</p>
                                <p className="text-muted mb-4"></p>
                            </Card.Body>
                        </Card>
                        <Card mb={4}>
                            <Card.Body className="p-0">
                                <ul className="list-group list-group-flush rounded-3">
                                {league.rules &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                        <p className="mb-0">Rules</p>
                                        <i className="fas fa-globe fa-lg text-warning"></i>
                                        <p className="mb-0"><a rel="noreferrer" href={`${league.rules}`} target={'_blank'}>{basenameFormat(league.rules)}</a></p>
                                    </li>
                                }
                                {league.points &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                        <p className="mb-0">Points system</p>
                                        <i className="fas fa-globe fa-lg text-warning"></i>
                                        <PointSystemCanvas points={league.points} />
                                    </li>
                                }
                                {league.website &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                        <p className="mb-0">Web</p>
                                        <i className="fas fa-globe fa-lg text-warning"></i>
                                        <p className="mb-0"><a rel="noreferrer" href={`${league.website}`} target={'_blank'}>{hostnameFormat(league.website)}</a></p>
                                    </li>
                                }
                            
                                {league.twitch &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Twitch</p>
                                    <i className="fas fa-globe fa-lg text-warning"></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`https://www.twitch.tv/${league.twitch}`} target={'_blank'}>{league.twitch}</a></p>
                                </li>
                                }
                                {league.youtube &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">YouTube</p>
                                    <i className="fas fa-globe fa-lg text-warning"></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`https://www.youtube.com/c/${league.youtube}`} target={'_blank'}>{league.youtube}</a></p>
                                </li>
                                }
                                {league.twitter &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Twitter</p>
                                    <i className="fab fa-twitter fa-lg" style={{color: "55acee"}}></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`https://www.twitter.com/${league.twitter}`} target={'_blank'}>{league.twitter}</a></p>
                                </li>
                                }
                                {league.discord &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Discord</p>
                                    <i className="fas fa-globe fa-lg text-warning"></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`${league.discord}`} target={'_blank'}>{basenameFormat(league.discord)}</a></p>
                                </li>
                                }
                                {league.instagram &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Instagram</p>
                                    <i className="fab fa-instagram fa-lg" style={{color: "ac2bac"}}></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`https://www.instagram.com/${league.instagram}/`} target={'_blank'}>{league.instagram}</a></p>
                                </li>
                                }
                                {league.facebook &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Facebook</p>
                                    <i className="fab fa-facebook-f fa-lg" style={{color: "3b5998"}}></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`https://www.facebook.com/${league.facebook}`} target={'_blank'}>{league.facebook}</a></p>
                                </li>
                                }
                                {league.tiktok &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Tiktok</p>
                                    <i className="fab fa-facebook-f fa-lg" style={{color: "3b5998"}}></i>
                                    <p className="mb-0"><a rel="noreferrer" href={`https://www.tiktok.com/${league.tiktok}`} target={'_blank'}>{league.tiktok}</a></p>
                                </li>
                                }
                                {league.shop &&
                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                    <p className="mb-0">Shop</p>
                                    <i className="fas fa-globe fa-lg text-warning"></i>
                                    <p className="mb-0"><a rel="noreferrer" href={league.shop} target={'_blank'}>{hostnameFormat(league.shop, false)}</a></p>
                                </li>
                                }
                                </ul>
                            </Card.Body>
                        </Card>
                        <br />
                        {league && isLeagueAdmin(league)
                            ?
                            <Card mb={4}>
                                <Card.Body className="p-0">
                                    <ul className="list-group list-group-flush rounded-3">
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">League Info</p>
                                            <Button variant="outline-dark" size="sm" onClick={()=> setShowEditInfoModal(true)}><img alt="lbl" src="/static/icons/pencil-square.svg" style={{width: 20}} /></Button>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Championship</p>
                                            <Button variant="outline-dark" size="sm" onClick={()=> setShowChampModal(true)}>New</Button>
                                        </li>
                                    </ul>
                                </Card.Body>
                                <ChampionshipNewModal leagueid={league.id} show={showChampModal} onHide={(newID, label)=>{setShowChampModal(false);if(newID){props.history.push(`/league/${leagueID}/champ/${newID}`); }}} />
                                <LeagueInfoModal league={league} show={showEditInfoModal} onHide={(edited)=>{ onLeagueInfoEditing(edited); }} />
                            </Card>
                            : <></>
                            }
                        <br />
                    </Col>
                    <Col lg={8}>
                        <Card mb={4} className="mb-4">
                            <Card.Body>
                                <h5 className="mb-4"><span className="text-primary">Champ</span>ionships</h5>
                                {championships.length === 0?
                                    <span>No Championships available yet</span>
                                :
                                championships.map((item,i) => (
                                    <div key={item.id}>
                                    <Row>
                                        <Col sm={3}>
                                            <p className="mb-0">{item.game}</p>
                                        </Col>
                                        <Col sm={9}>
                                            <p className="text-muted mb-0"><Link to={{pathname:`/league/${leagueID}/champ/${item.id}`, league:league, champ:item}}>{item.label}</Link> </p>
                                        </Col>
                                    </Row>
                                    {(i < championships.length-1) &&
                                        <hr />
                                    }
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                        <Row>
                            <Col>
                                <Card mb={4} className="mb-4">
                                    <Card.Body className="card-body">
                                        <h5 className="mb-4"><span className="text-primary">Previous</span>Races</h5>
                                        {prevRaces(calendarPrev, prevCalendarsNumber)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card mb={4} className="mb-4">
                                    <Card.Body className="card-body">
                                        <h5 className="mb-4"><span className="text-primary">Next</span>Races</h5>
                                        {nextRaces(calendarNext, nextCalendarsNumber)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {telemetryRaces.length > 0 &&
                        <Row>
                            <Col>
                                <Card mb={4} className="mb-4">
                                    <Card.Body className="card-body">
                                        <h5 className="mb-4"><span className="text-primary">Tele</span>metry</h5>
                                        {orphantelemetryRaces(telemetryRaces)}

                                        <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-end">
                                            <li className={`page-item ${telemetryPage > 0 ? "" : "disabled"}`}>
                                                <button onClick={()=> loadTelemetryPage(telemetryPage-1)} className="page-link">Previous</button>
                                            </li>
                                            <li className={`page-item ${telemetryRaces.length >= maxRaces ? "" : "disabled"}`}>
                                                <button onClick={()=> loadTelemetryPage(telemetryPage+1)} className="page-link">Next</button>
                                            </li>
                                           
                                        </ul>
                                        </nav>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        }
                    </Col>
                    </Row>
                    {league && !league.affiliated &&
                    <Row>
                        <center><i>This League is not affiliated with racemapper.app, content is provided from data available on the web. Any trademark belongs to its respective owners.</i></center>
                    </Row>
                    }
                </Container>
        </main>
        <Footer></Footer>
        <RaceDetail race={selectedRace} league={league} show={selectedRace !== null} onHide={()=>{ setSelectedRace(null); }}  />
    </>
    );
}

export default League;