import React, { Component } from 'react';
import {SESSION_TYPE,TRACK, WEATHER} from 'racemapper_shared/enums';
import WeatherForecast from './WeatherForecast';
import {Helmet} from "react-helmet-async";
import { Link } from 'react-router-dom';

import Placeholder from 'react-bootstrap/Placeholder'

class RaceHeader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isShow: true,
        };
    }
    
    toggleShow = () => {
        this.setState(state => ({ isShow: !state.isShow }));
    };
    
    render() {
        return(
        <>  {this.props.race.trackId !== undefined ?
            <>
            <Helmet>
                <title>{`${TRACK[this.props.race.trackId]} - ${SESSION_TYPE[this.props.race.sessionType]}`}</title>
                <meta name="og:title" content={`${TRACK[this.props.race.trackId]} - ${SESSION_TYPE[this.props.race.sessionType]}`} />
                <meta name="twitter:title" content={`${TRACK[this.props.race.trackId]} - ${SESSION_TYPE[this.props.race.sessionType]}`} />
                <meta name="description" content={`${this.props.session.organisationLabel}: ${this.props.session.championshipLabel}`} />
                {this.props.race.banner &&
                <>
                    <meta name="og:image" content={this.props.race.banner} />
                    <meta name="twitter:image" content={this.props.race.banner} />
                    <meta name="twitter:card" content="summary_large_image" />
                </>
                }
            </Helmet>
            <h1 className="display-4"><img className='flag-icon' src={`${process.env.PUBLIC_URL}/static/flags/${this.props.race.trackId}.png`} alt={TRACK[this.props.race.trackId]} /> {TRACK[this.props.race.trackId]} - {SESSION_TYPE[this.props.race.sessionType]}</h1>
            <h5><Link to={`/league/${this.props.session.organisationId}`}>{this.props.session.organisationLabel}</Link> {this.props.session.championshipLabel}</h5>
            <p className="lead font-face-f1">
                <img className="weather-icon" src={`${process.env.PUBLIC_URL}/static/weather/${this.props.race.weather}.png`} alt={WEATHER[this.props.race.weather]} /> {WEATHER[this.props.race.weather]}, {this.props.race.airTemperature}° track: {this.props.race.trackTemperature}°
                - {this.props.race.trackLength/1000} km - pit speed limit {this.props.race.pitSpeedLimit}km/h
            </p>
            </>
            :(
            <>
            <h1 className="display-4">
                <Placeholder as="p" animation="glow"><Placeholder className="w-100" /></Placeholder>
            </h1>
            <h5><Placeholder as="p" animation="glow"><Placeholder xs={2}  /></Placeholder></h5>
            <h5><Placeholder as="p" animation="glow"><Placeholder className="w-50" size="xs"  /></Placeholder></h5>
            </>
            )}
            { this.props.forecast && 
                <WeatherForecast weatherForecastSamples={this.props.weatherForecastSamples} forecastAccuracy={this.props.race.forecastAccuracy} linktoFullscreen={true} raceid={this.props.race.sessionUID} />
            }
        </>);
    }
}
  
  export default RaceHeader;