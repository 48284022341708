import React from 'react';

function DriverHelps( props) {
  return (
    <div>
    { props.antiLockBrakes > 0 &&
        <img src={`${process.env.PUBLIC_URL}/static/abs.svg`} alt="abs" width="20" height="20" />
    }
    { props.tractionControl > 0 &&
        <img src={`${process.env.PUBLIC_URL}/static/tc.svg`} alt={props.tractionControl} width="20" height="20" />
    }
    </div>
  )
}

export default DriverHelps;