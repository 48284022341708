const url = require('url');
const path = require('path');

exports.hostnameFormat = ( urlpath) => {
    return url.parse( urlpath, false).hostname;
}

exports.basenameFormat = ( urlpath) => {
    const p = path.basename(url.parse( urlpath, false).pathname);
    if( p === 'edit') { // gdrive
        return url.parse( urlpath, false).hostname;
    }
    return p;
}