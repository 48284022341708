import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import {timeFormat} from '../helpers/format';
import {formatLogs} from '../helpers/racelog';

  class RaceLog extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    render() {
      return(
        <div>
            <ListGroup key="logs" className="logs">
                {
                    formatLogs(this.props.logs, this.props.partecipants).map(item => (
                        <ListGroup.Item key={item.id} className="logline">                                       
                            {timeFormat(item.sessionTime)}: {item.label}
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        </div>);
    }
  }
  
  export default RaceLog;