import React, { Component } from 'react';

import ListGroup from 'react-bootstrap/ListGroup'
import Placeholder from 'react-bootstrap/Placeholder'

import {SESSION_TYPE, WEATHER} from 'racemapper_shared/enums';
import {filterWeatherForecast} from "racemapper_shared/data";

class WeatherForecast extends Component {

    accurancyLabel = ( accurancy) => {
        return "Accurancy: " + (accurancy === 0 ? "perfect" : "approximate");
    }

    timeLbl = (item) => {
        if( item.timeOffset === 0) return SESSION_TYPE[item.sessionType];
        return "+"+ item.timeOffset +"min";
    }
    
    showValue = (weatherForecastSamples) => {
        if (weatherForecastSamples ) {
          return filterWeatherForecast(weatherForecastSamples).map( (item,k) => (
            <ListGroup.Item key={k}>
                <center>
                {this.timeLbl( item)}<br />
                    <img className="weather-icon" src={`${process.env.PUBLIC_URL}/static/weather/${item.weather}.png`} alt={WEATHER[item.weather]} /><br />
                    <span className='inline-block'>Rain: {item.rainPercentage}%</span><br/>
                    <span className="inline-block">{item.airTemperature}° t{item.trackTemperature}°</span>
                </center>
            </ListGroup.Item>
            ));
        } else {
          return [1,2,3,4,5].map( k => (
            <ListGroup.Item key={`pl-${k}`} style={{ width: '115px' }}>
                    <Placeholder as="p" animation="glow"><Placeholder className="w-50" /></Placeholder>
                    <Placeholder as="p" animation="glow"><Placeholder className="w-100" size="xs" /></Placeholder>
                    <Placeholder as="p" animation="glow"><Placeholder className="w-75" size="xs" /></Placeholder>
            </ListGroup.Item>
            ));
        }
    };

    render() {
         const expadstyle = {
             width: 9,
             marginLeft: "4px"
         };

         return(
        <div id="weather">
            <ListGroup horizontal>
                {this.showValue(this.props.weatherForecastSamples)}
            </ListGroup>
                <div className='logline'>{this.accurancyLabel(this.props.forecastAccuracy)}
                { this.props.linktoFullscreen &&
                    <a href={`${this.props.raceid}/weather`} target="_blank" rel="noopener noreferrer"><img alt="weather" src="/static/arrows-fullscreen.svg" style={expadstyle} /></a>
                }
                </div>
         </div>);
    }
 }
   
export default WeatherForecast;