import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from 'react-bootstrap/Container';

const About = props => {
    return (<>
        <Header></Header>
        <main className="bg-light">
            <div
            className="bg-image p-5 text-center shadow-1-strong mb-4 text-white"
            style={{backgroundImage: 'url(/static/images/m1.jpg)'}}
            >
                <h1 className='font-face-f1'>About RaceMapper</h1>
            </div>
        <Container>
            <p>
                This is a personal project and it's pretty much still a work in progress.
                <br />It works thanks to the <a href="https://answers.ea.com/t5/General-Discussion/F1-22-UDP-Specification/td-p/11551274">UDP functionality of F1 Games</a>.<br /><br />
                When I've started my objectives were:
            </p>
                <ul>
                    <li>Build a tool for commentators and viewers to have more informations during the race</li>
                    <li>A place where the race can leave even after it's finished</li>
                    <li>Have some basic tools to analise the race</li>
                    <li>Somehow help league organisers</li>
                </ul>
            <p>
                We have also a twitch bot.<br /><br />
                If you have any other question please reach me: <a href="https://twitter.com/emln_22">@emln_22</a> or <a href="https://discord.gg/egyErhzUqB">join our Discord server</a> ( it's pretty emtpy right now, I use it mainly for testing)
            <br /><br />
            </p>
        </Container>
        </main>
        <Footer></Footer>
        </>
    );
};


export default About;
