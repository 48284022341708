import React from 'react';

function Footer() {

  return (
    <footer className="pt-5">
      <div className="container text-center">
        <p>&copy; RaceMapper 2022 | <a href="https://discord.gg/egyErhzUqB">Discord</a> | <a href="https://twitter.com/emln_22">Twitter</a> | <a target="_blank" rel="noreferrer" href="https://www.iubenda.com/privacy-policy/28712282" title="Privacy Policy ">Privacy Policy</a> | <a target="_blank" rel="noreferrer" href="https://www.iubenda.com/terms-and-conditions/28712282" title="Terms and Conditions ">Terms and Conditions</a> 
        </p>
      </div>
    </footer>
  )
}

export default Footer;