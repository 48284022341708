import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Partecipants from '../components/Partecipants';
import EditSesssionLabel from '../components/EditSessionLabel';
import ShareComponent from '../components/ShareComponent';
import PointSystemCanvas from '../components/PointSystemCanvas';
import ChampionshipStandingModal from '../components/ChampionshipStandingModal';
import Alert from 'react-bootstrap/Alert'
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet-async";

import { db } from '../services/firebase';
import { isLeagueAdmin , isSuperAdmin } from '../helpers/auth';
import { COLLECTION_ID, mapDriversLabel, updatePartecipantsMapper , getChampionshipDetailData ,getChampionshipsData, getOrganizationsData, getSessionData, deleteSessionData, deleteRaceData, SESSIONS_COLLECTION} from "racemapper_shared/data";
import { SESSION_TYPE } from 'racemapper_shared/enums';
import { dateForEvent , raceFormat , isSprintRace } from 'racemapper_shared/formats';
import { isRace } from 'racemapper_shared/raceUtils';

import { trackFormat } from '../helpers/format';
import { canDeleteSession } from '../helpers/raceUtils';

const fetch = require('node-fetch');

let organisationId = "";
let partecipantsMapper = [];

const Sessionrace = props => {

    //const { location } = props;

    const location = useLocation()

    const [showWarning, setShowWarning] = React.useState(false);

    const [sessionRace, setSessionRace] = React.useState([]);
    const [sessionLabel, setSessionLabel] = React.useState("");

    const [allSessions, setAllSessions] = React.useState([]);
    const [organizations, setOrganizations] = React.useState([]);
    const [partecipants, setPartecipants] = React.useState([]);
    const [sessionPartecipants, setSessionPartecipants] = React.useState([]);
    const [lobbyPartecipants, setLobbyPartecipants] = React.useState([]);

    const [currentOrg, setCurrentOrg] = React.useState([]);
    const [currentChamp, setCurrentChamp] = React.useState([]);

    const [championships, setChampionships] = React.useState([]);
    const [selectedRaceForStandings, setSelectedRaceForStandings] = React.useState(null);
    const [selectedSprintRaceForStandings, setSelectedSprintRaceForStandings] = React.useState(null);

    const sessionRaceID = props.match.params.sessionID;

    const getChampionships = async(org) => {
        try {
            const arrayData = await getChampionshipsData( db, org);
            setChampionships( arrayData);
        } catch( error) {
            console.log(error);
        }
    }

    const handleSelectOrg=(e)=>{
        getChampionships( e);
        organisationId = e;
        const o = organizations.filter( item => item.id === e)[0].label;
        setCurrentOrg( o);
    }

    const updatedDriver = (driver) => {
        let change = false;
        if(!partecipantsMapper) {
            partecipantsMapper = [];
        }
        if(driver.networkId) {
            const found = partecipants.find( p => p.networkId === driver.networkId);
            if (!found) {
                change = true;
                partecipantsMapper.push({label:driver.label,number:driver.raceNumber,teamId:driver.teamId, networkId:driver.networkId});
            } else if(found.label !== driver.label ) {
                change = true;
                found.label = driver.label;
            }
        } else { // only number and team
            const found = partecipantsMapper.find( p => p.teamId === driver.teamId && p.number === driver.raceNumber);
            if( found && found.length === 1 && driver.label !== found.label) {
                found.label = driver.label;
                change = true;
            } else if( !found && driver.label) {
                //new 
                partecipantsMapper.push({label:driver.label,number:driver.raceNumber,teamId:driver.teamId});
                change = true;
            }
        }
        if( change) {
            setShowWarning(true);
            updatePartecipantsMapper( db, sessionRaceID,partecipantsMapper);
        }
       // console.log(partecipantsMapper);
    }

    const handleSelectChamp= async (e)=>{
        const o = championships.filter( item => item.id === e)[0].label;
        setCurrentChamp(o);
        
        const p = await getChampionshipDetailData( db, organisationId, e);
        setPartecipants( p.partecipants);
        const ref = db.collection(COLLECTION_ID).doc(sessionRaceID);
        const orgdata = organizations.filter( item => item.id === organisationId)[0];
        let data = {
            partecipants: p.partecipants,
            admins: orgdata.admins,
            championshipId: e,
            championshipLabel: o,
            organisationId: organisationId,
            organisationLabel: currentOrg
        };
        if( orgdata.points) {
            data["points"] = orgdata.points;
        }
        if( orgdata.profilepic) {
            data["organisationPic"] = orgdata.profilepic;
        }
        ref.set( data, { merge: true });
    }

    const deleteSession = (sessionID) => {
        allSessions.forEach( r => {
            deleteRaceData( db, sessionID, r.id);
        });
        deleteSessionData( db, sessionRace)
        .then( (res)=> {
            props.history.push('/');
        });
    }
    const getOrganizations = async() => {
        if( organizations.length === 0) {
            try {
                const arrayData = await getOrganizationsData( db);
                setOrganizations( arrayData);
            } catch( error) {
                console.log(error);
            }
        }
    }

    const openAssignStandings = () => {
        const allraces = allSessions.filter( item => isRace(item.sessionType));
        if( allraces.length > 0) {
            const lastrace = allraces[0];
            if( lastrace.classificationData) {
                if (isSprintRace(sessionRace.format) && allraces.length > 1) {
                    setSelectedSprintRaceForStandings(allraces[1]);
                }
                setSelectedRaceForStandings( lastrace);
            }
        }
    }

    const regenerateAssets = () => {
        const settings = { method: "Get" };
        const race =  allSessions.map( item => item.id).join(",");
        const type =  allSessions.map( item => item.sessionType).join(",");
        const bnr = document.getElementById('session-race-banner');
        if( bnr) {
            bnr.style.display = "none";
            document.getElementById('session-race-banner-spiinner').style.display = "";
        }
        setShowWarning(false);

        fetch("/workers/race-regen-assets?game=" + COLLECTION_ID + "&session=" + sessionRaceID + "&race=" + race + "&type=" + type, settings)
        .then( _ => {
            const rand = Math.floor(Math.random() * 1000);
            document.getElementById('session-race-banner').src = document.getElementById('session-race-banner').src + "&_=" + rand.toString(); 
            document.getElementById('session-race-banner-spiinner').style.display = "none";
            document.getElementById('session-race-banner').style.display = "";
        }).catch( e => {
            console.log(e);
        });
    }

    useEffect(() => {
        const getSessionRaceData = async() => {
            try {
                let data
                if( location.session) {
                    data = location.session;
                } else {
                    data = await getSessionData( db, sessionRaceID);
                }
                setSessionRace( data);
                setSessionLabel( data.label);
                if( data.championshipId) {
                    setCurrentChamp( data.championshipLabel);
                    setCurrentOrg( data.organisationLabel);
                } else {
                    setCurrentChamp( "Championship");
                    setCurrentOrg( "Organization");
                }
                if( data.partecipants) {
                    partecipantsMapper = data.partecipants;
                    setPartecipants( data.partecipants);
                } else {
                    setPartecipants([]);
                    partecipantsMapper = [];
                }
                if( data.lobby) {
                    data.lobby = Object.values(data.lobby);
                    mapDriversLabel(data.lobby, partecipantsMapper);
                    setLobbyPartecipants(data.lobby.filter( item => item.teamId !== 255 ));
                } else {
                    setLobbyPartecipants([]);
                }
            } catch( error) {
                console.log(error);
            }
        }
        getSessionRaceData();
    },[sessionRaceID,location.session]);
    
    useEffect(() => { 
        const getAllSessionsData = async() => {
            try {
                // I avoid to use snaphot because it triggers many read
                const sessions = await db.collection(COLLECTION_ID).doc(sessionRaceID).collection(SESSIONS_COLLECTION).orderBy("created","desc").get();
                const arrayData = sessions.docs.map( doc => ({id:doc.id, ...doc.data()}));
                setAllSessions( arrayData);
                if( arrayData.length > 0) {
                    const showww = sessionRace.partecipants_refreshed && arrayData.filter( i => i.classificationData).length > 0;
                    setShowWarning( showww);
                    const lastrace = arrayData[0];
                    lastrace.partecipants = Array.isArray( lastrace.partecipants) ? lastrace.partecipants : Object.values(lastrace.partecipants);
                    mapDriversLabel(lastrace.partecipants, partecipantsMapper);
                    const cloned = JSON.parse(JSON.stringify(lastrace.partecipants));
                    setSessionPartecipants(cloned.sort((a,b)=>{ return a.teamId > b.teamId}).filter( item => item.driverId === 255 && item.teamId !== 255));
                }
            } catch( error) {
                console.log(error);
            }
        }
        getAllSessionsData();
    }, [sessionRaceID, sessionRace.partecipants_refreshed]);

    return (
        <>
            <Helmet>
                <title>{sessionLabel|| sessionRaceID}</title>
            </Helmet>
            <Header></Header>
                <main className="bg-light">
    
                <Container fluid className='py-3'>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {sessionLabel|| sessionRaceID}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
    
                <Container className='py-3'>       
                    <Row>
                        <Col lg={4}>
                            <Card mb={4} className="mb-4">
                                <Card.Body className="card-body text-center">
                                    <img src={sessionRace.organisationPic || '/static/placeholder.png'} alt="avatar"
                                    className="rounded-circle img-fluid" style={{width: "150px"}} />
                                    <h5 className="my-3"><Link to={`/league/${sessionRace.organisationId}`}>{sessionRace.organisationLabel}</Link></h5>
                                    <p className="text-muted mb-1">{sessionRace.championshipLabel}</p>
                                </Card.Body>
                            </Card>
                            {sessionRace && sessionRace.format !== undefined &&
                            <Card mb={4}>
                                <Card.Body className="p-0">
                                <ul className="list-group list-group-flush rounded-3">
                                { sessionRace.briefing &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Briefing</p>
                                            <Button size='sm' variant="outline-dark" href={sessionRace.briefing} target="_blank">Download</Button>
                                    </li>
                                }
                                { sessionRace.format !== undefined &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Format</p>
                                            <span>{raceFormat(sessionRace.format)}</span>
                                    </li>
                                }
                                { sessionRace.championshipId &&
                                <>
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Calendar</p>
                                            <Link to={`/league/${sessionRace.organisationId}/champ/${sessionRace.championshipId}`}>Go</Link>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                        <p className="mb-0">Standings</p>
                                        <Link to={`/league/${sessionRace.organisationId}/champ/${sessionRace.championshipId}#driver`}>Go</Link>
                                    </li>
                                </>
                                }
                                { sessionRace.points &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Points system</p>
                                            <PointSystemCanvas points={sessionRace.points} />
                                    </li>
                                }
                                </ul>
                                </Card.Body>
                            </Card>
                            }
                            {sessionRace && isLeagueAdmin(sessionRace)
                            ?
                            <>
                            <br />
                            <Card mb={4}>
                                <Card.Body className="p-0">
                                    <ul className="list-group list-group-flush rounded-3">
                                        {sessionRace.ip &&
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Telemetry:</p>
                                            {sessionRace.ip}:{sessionRace.port}
                                            {isSuperAdmin(sessionRace) && 
                                             - sessionRace.port_local
                                            }
                                        </li>
                                        }
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Session Label</p>
                                            <EditSesssionLabel className="mb-0" label={sessionLabel} sessionID={sessionRaceID} onHide={(lbl) => {setSessionLabel(lbl)}} />
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Organization</p>
                                            <DropdownButton className="mb-0" variant="outline-dark" onClick={() => getOrganizations()} onSelect={handleSelectOrg} title={currentOrg} size="sm" id="dropdown-orgs" as={ButtonGroup}>
                                            {
                                                organizations.map(item => (
                                                <Dropdown.Item key={item.id} eventKey={item.id}>{item.label}</Dropdown.Item>
                                                ))
                                            }
                                            </DropdownButton>
                                        </li>
                                        {championships.length > 0 &&
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Championship</p>
                                                <DropdownButton className="mb-0" variant="outline-dark" onSelect={handleSelectChamp} title={currentChamp} size="sm" id="dropdown-champ" as={ButtonGroup}>
                                                {
                                                    championships.map(item => (
                                                    <Dropdown.Item key={item.id} eventKey={item.id}>{item.label}</Dropdown.Item>
                                                    ))
                                                }
                                                </DropdownButton>
                                        </li>
                                        }
                                        {sessionRace.calendarId &&
                                            <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                                <p className="mb-0">Championship Points</p>
                                                <Button variant="outline-dark" size='sm' onClick={() => {openAssignStandings()}}>Assign</Button>
                                                <ChampionshipStandingModal calendarid={sessionRace.calendarId} championshipid={sessionRace.championshipId} partecipants={partecipants} race={selectedRaceForStandings} sprint={selectedSprintRaceForStandings}  points={sessionRace.points} show={selectedRaceForStandings !== null} onHide={( saved)=>{ setSelectedRaceForStandings(null); if(saved){ props.history.push(`/league/${sessionRace.organisationId}/champ/${sessionRace.championshipId}#driver`);}}} />
                                            </li>
                                        }
                                        {sessionRace.created && canDeleteSession(sessionRace.created) &&
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <p className="mb-0">Session</p>
                                            <Button variant="danger" size='sm' onClick={() => { window.confirm( 'Are you sure you want to delete this Session? There is no come back', ) && deleteSession(sessionRaceID) }}>Delete</Button>
                                        </li>
                                        }
                                    </ul>
                                </Card.Body>
                            </Card>
                            </>
                            : <></>
                            }
                            <br />
                        </Col>
                        <Col lg={8}>
                        <Row>
                            <Col>
                                <Card mb={4} className="mb-4">
                                    <Card.Body>
                                        <h5 className="mb-4" style={{float:"left"}}>{sessionLabel}</h5>
                                        <ButtonGroup size="sm" style={{position:"absolute", top:10, right:10}}>
                                            <ShareComponent title={sessionLabel} twitterHandler={sessionRace.twitter || ""} placement={'left'} variant="outline" />
                                        </ButtonGroup>
                                        <br style={{clear:"both"}} />
                                        {allSessions.length === 0?
                                            <span>No Telemetry data available yet</span>
                                        :
                                        allSessions.map((item,i) => (
                                            <div key={item.id}>
                                            <Row >
                                                <Col sm={9}>
                                                    <p className="mb-0"><Link to={{pathname:`/session/${sessionRaceID}/${item.sessionType}/${item.id}`}}>{trackFormat(item.trackId)} {dateForEvent(item.created.toDate())}</Link></p>
                                                </Col>
                                                <Col sm={3}>
                                                    <p className="text-muted mb-0">{SESSION_TYPE[item.sessionType]}</p>
                                                </Col>
                                            </Row>
                                            {(i < allSessions.length-1) &&
                                             <hr />
                                            }
                                            </div>
                                        ))}
                                    </Card.Body>
                                </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                {showWarning && isLeagueAdmin( sessionRace) && 
                                <Alert key="success" variant="success">
                                    Once you finish editing driver names, please regenerate the associated assets<br />
                                    <Button variant='warning' size='sm' onClick={() => {regenerateAssets()}}>Regenerate</Button>
                                </Alert>
                                }
                                {sessionRace.banner &&
                                    <Card mb={4} className="mb-4">
                                        <Image id="session-race-banner" fluid={true} rounded={true} src={sessionRace.banner} />
                                        <div  className="d-flex justify-content-center" >
                                             <Spinner id="session-race-banner-spiinner" animation="grow" variant="secondary"  style={{display:"none"}} />
                                         </div>
                                    </Card>
                                }
                                {(isLeagueAdmin( sessionRace) || !sessionRace.banner) &&
                                    <Card mb={4} className="mb-4">
                                        { sessionPartecipants.length === 0 && lobbyPartecipants.length === 0 && partecipants  && 
                                        <Card.Body className="card-body">
                                            <h5 className="mb-4">Partecipants</h5>
                                            <Partecipants partecipants={partecipants} canEdit={isLeagueAdmin(sessionRace) && false} />
                                        </Card.Body>
                                        }
                                        { sessionPartecipants.length === 0 && lobbyPartecipants.length > 0  && 
                                        <Card.Body className="card-body">
                                            <h5 className="mb-4">Lobby</h5>
                                            <Partecipants 
                                                        partecipants={lobbyPartecipants} 
                                                        canEdit={isLeagueAdmin(sessionRace)} 
                                                        onEditSave={(changed ) => {
                                                            if(changed) updatedDriver(changed);
                                                        }
                                            } />
                                        </Card.Body>
                                        }

                                        { sessionPartecipants.length > 0  && 
                                        <Card.Body className="card-body">
                                            <h5 className="mb-4">Drivers</h5>
                                            <Partecipants 
                                                        partecipants={sessionPartecipants} 
                                                        canEdit={isLeagueAdmin(sessionRace)} 
                                                        onEditSave={( changed) => {
                                                            if(changed) updatedDriver(changed);
                                                        }
                                            } />
                                        </Card.Body>
                                        }
                                    </Card>
                                }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </Container>
                </main>
            <Footer></Footer>
        </>
    );
};

export default Sessionrace;