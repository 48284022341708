exports.SESSION_TYPE = {
    0: "UNKNOWN",
    1: "P1",
    2: "P2",
    3: "P3",
    4: "SHORTP",
    5: "Q1",
    6: "Q2",
    7: "Q3",
    8: "SHORTQ",
    9: "OSQ",
    10: "RACE",
    11: "RACE2",
    12: "RACE3",
    13: "TT"
};

exports.TRACK = {
    0: "Melbourne",
    1: "Paul Ricard",
    2: "Shanghai",
    3: "Sakir",
    4: "Catalunya",
    5: "Monaco",
    6: "Montreal",
    7: "Silverstone",
    8: "Hockenheim",
    9: "Hungaroring",
    10: "Spa",
    11: "Monza",
    12: "Marina Bay",
    13: "Suzuka",
    14: "Yas Marina",
    15: "Austin",
    16: "Interlagos",
    17: "Spielberg",
    18: "Sochi",
    19: "Mexico City",
    20: "Baku",
    21: "Sakir Short",
    22: "Silverstone Short",
    23: "Texas Short",
    24: "Suzuka Short",
    25: "Hanoi",
    26: "Zandvoort",
    27: "Imola",
    28: "Portimão",
    29: "Jeddah",
    30: "Miami"
}

exports.TRACK_SHORT = {
    0: "AUS",
    1: "FRA",
    2: "shangai",
    3: "BHR",
    4: "ESP",
    5: "MCO",
    6: "CAN",
    7: "GBR",
    8: "GER",
    9: "HUN",
    10: "BEL",
    11: "ITA",
    12: "SGP",
    13: "JPN",
    14: "UAE",
    15: "USA",
    16: "BRA",
    17: "AUT",
    18: "RUS",
    19: "MEX",
    20: "AZE",
    21: "BHR",
    22: "GBR",
    23: "USA",
    24: "JPN",
    25: "VIE",
    26: "NLD",
    27: "IMO",
    28: "POR",
    29: "SAU",
    30: "MIA"
}

exports.TRACK_COUNTRY = {
    0: "Australia",
    1: "France",
    2: "Cina",
    3: "Bahrain",
    4: "Spain",
    5: "Monte Carlo",
    6: "Canada",
    7: "UK",
    8: "Germany",
    9: "Hungary",
    10: "Belgium",
    11: "Italy",
    12: "Singapore",
    13: "Japan",
    14: "Abu Dhabi",
    15: "USA",
    16: "Brazil",
    17: "Austria",
    18: "Russia",
    19: "Mexico",
    20: "Azerbaijan",
    21: "Bahrain",
    22: "UK",
    23: "USA",
    24: "Japan",
    25: "Vietnam",
    26: "Netherlands",
    27: "Italy",
    28: "Portugal",
    29: "Saudi Arabia",
    30: "USA"
}


exports.TRACK_FLAG = {
    0: "🇦🇺",
    1: "🇫🇷",
    2: "🇨🇳",
    3: "🇧🇭",
    4: "🇪🇸",
    5: "🇲🇨",
    6: "🇨🇦",
    7: "🇬🇧",
    8: "🇩🇪",
    9: "🇭🇺",
    10: "🇧🇪",
    11: "🇮🇹",
    12: "🇸🇬",
    13: "🇯🇵",
    14: "🇦🇪",
    15: "🇺🇸",
    16: "🇧🇷",
    17: "🇦🇹",
    18: "🇷🇺",
    19: "🇲🇽",
    20: "🇦🇿",
    21: "🇧🇭",
    22: "🇬🇧",
    23: "🇺🇸",
    24: "🇯🇵",
    25: "🇨🇳",
    26: "🇳🇱",
    27: "🇮🇹",
    28: "🇵🇹",
    29: "🇸🇦",
    30: "🇺🇸"
};


exports.WEATHER = {
    0:"clear",
    1:"lightCloud",
    2:"overcast",
    3:"lightRain",
    4:"heavyRain",
    5:"storm"
}

exports.PENALITY_TYPE = {
    0:"Drive through",
    1:"Stop Go",
    2:"Grid penalty",
    3:"Penalty reminder",
    4:"Time penalty",
    5:"Warning",
    6:"Disqualified",
    7:"Removed from formation lap",
    8:"Parked too long timer",
    9:"Tyre regulations",
    10:"This lap invalidated",
    11:"This and next lap invalidated",
    12:"This lap invalidated",
    13:"This and next lap invalidated",
    14:"This and previous lap invalidated",
    15:"This and previous lap invalidated",
    16:"Retired",
    17:"Black flag timer"
};

exports.PENALITY_ICON = {
    0:"🛑",
    1:"🛑",
    2:"🛑",
    3:"🕐",
    4:"🛑",
    5:"🟡",
    6:"🏴",
    7:"⛔️",
    8:"🏴",
    9:"🏴",
    10:"🚫",
    11:"🚫",
    12:"🚫",
    13:"🚫",
    14:"🚫",
    15:"🚫",
    16:"❌",
    17:"🏴"
};


exports.INFRINGMENT_TYPE = {
    0:"Blocking by slow driving",
    1:"Blocking by wrong way driving",
    2:"Reversing off the start line",
    3:"Big Collision",
    4:"Small Collision",
    5:"Collision failed to hand back position single",
    6:"Collision failed to hand back position multiple",
    7:"Corner cutting gained time",
    8:"Corner cutting overtake single",
    9:"Corner cutting overtake multiple",
    10:"Crossed pit exit lane",
    11:"Ignoring blue flags",
    12:"Ignoring yellow flags",
    13:"Ignoring drive through",
    14:"Too many drive throughs",
    15:"Drive through reminder serve within n laps",
    16:"Drive through reminder serve this lap",
    17:"Pit lane speeding",
    18:"Parked for too long",
    19:"Ignoring tyre regulations",
    20:"Too many penalties",
    21:"Multiple warnings",
    22:"Approaching disqualification",
    23:"Tyre regulations select single",
    24:"Tyre regulations select multiple",
    25:"Lap invalidated corner cutting",
    26:"Lap invalidated running wide",
    27:"Corner cutting ran wide gained time minor",
    28:"Corner cutting ran wide gained time significant",
    29:"Corner cutting ran wide gained time extreme",
    30:"Lap invalidated wall riding",
    31:"Lap invalidated flashback used",
    32:"Lap invalidated reset to track",
    33:"Blocking the pitlane",
    34:"Jump start",
    35:"Safety car to car collision",
    36:"Safety car illegal overtake",
    37:"Safety car exceeding allowed pace",
    38:"Virtual safety car exceeding allowed pace",
    39:"Formation lap below allowed speed",
    40:"Formation lap parking",
    41:"Retired mechanical failure",
    42:"Retired terminally damaged",
    43:"Safety car falling too far back",
    44:"Black flag timer",
    45:"Unserved stop go penalty",
    46:"Unserved drive through penalty",
    47:"Engine component change",
    48:"Gearbox change",
    49:"Parc Fermé change",
    50:"League grid penalty",
    51:"Retry penalty",
    52:"Illegal time gain",
    53:"Mandatory pitstop",
    54:"Attribute assigned"
};


exports.RESULT_STATUS = {
    0:"", // invalid
    1:"", // inactive
    2:"", // active
    3:"🏁", // finished
    4:"DNF", //didnotfinish
    5:"SQ", //disqualified
    6:"NC", // not classified
    7:"RT" // retired
};


exports.TEAM = {
    0:"Mercedes",
    1:"Ferrari",
    2:"Red Bull Racing",
    3:"Williams",
    4:"Aston Martin",
    5:"Alpine",
    6:"AlphaTauri",
    7:"Haas",
    8:"McLaren",
    9:"Alfa Romeo",
    10:"McLaren 1988",
    11:"McLaren 1991",
    12:"Williams 1992",
    13:"Ferrari 1995",
    14:"Williams 1996",
    15:"McLaren 1998",
    16:"Ferrari 2002",
    17:"Ferrari 2004",
    18:"Renault 2006",
    19:"Ferrari 2007",
    21:"Red Bull 2010",
    22:"Ferrari 1976",
    23:"ART Grand Prix",
    24:"Campos Vexatec Racing",
    25:"Carlin",
    26:"Charouz Racing System",
    27:"DAMS",
    28:"Russian Time",
    29:"MP Motorsport",
    30:"Pertamina",
    31:"McLaren 1990",
    32:"Trident",
    33:"BWT Arden",
    34:"McLaren 1976",
    35:"Lotus 1972",
    36:"Ferrari 1979",
    37:"McLaren 1982",
    38:"Williams 2003",
    39:"Brawn 2009",
    40:"Lotus 1978",
    41:"Multiplayer",
    42:"Art GP '19",
    43:"Campos '19",
    44:"Carlin '19",
    45:"Sauber Junior Charouz '19",
    46:"Dams '19",
    47:"Uni-Virtuosi '19",
    48:"MP Motorsport '19",
    49:"Prema '19",
    50:"Trident '19",
    51:"Arden '19",
    53:"Benetton 1994",
    54:"Benetton 1995",
    55:"Ferrari 2000",
    56:"Jordan 1991",
    63:"Ferrari 1990",
    64:"McLaren 2010",
    65:"Ferrari 2010",
    85: "Mercedes 2020",
    86: "Ferrari 2020",
    87: "Red Bull 2020",
    88: "Williams 2020",
    89: "Racing Point 2020",
    90: "Renault 2020",
    91: "Alpha Tauri 2020",
    92: "Haas 2020",
    93: "McLaren 2020",
    94: "Alfa Romeo 2020",
    95: "Aston Martin DB11 V12",
    96: "Aston Martin Vantage F1 Edition",
    97: "Aston Martin Vantage Safety Car",
    98: "Ferrari F8 Tributo",
    99: "Ferrari Roma",
    100: "McLaren 720S",
    101: "McLaren Artura",
    102: "Mercedes AMG GT Black Series Safety Car",
    103: "Mercedes AMG GTR Pro",
    104: "F1 Custom Team",
    106: "Prema ‘21",
    107: "Uni-Virtuosi ‘21",
    108: "Carlin ‘21",
    109: "Hitech ‘21",
    110: "Art GP ‘21",
    111: "MP Motorsport ‘21",
    112: "Charouz ‘21",
    113: "Dams ‘21",
    114: "Campos ‘21",
    115: "BWT ‘21",
    116: "Trident ‘21",
    117: "Mercedes AMG GT Black Series91Alpha Tauri 2020",
    255: "My Team"
}

exports.TEAM_F1 = Object.values(this.TEAM).filter( (_,v) => v < 10);

exports.TEAM_COLOR = {
    0:"mercedes",
    1:"ferrari",
    2:"redbull",
    3:"williams",
    4:"rp",
    5:"renault",
    6:"alphatauri",
    7:"haas",
    8:"mclaren",
    9:"alfaromeo",
    10:"mclaren",
    11:"mclaren",
    12:"williams",
    13:"ferrari",
    14:"williams",
    15:"mclaren",
    16:"ferrari",
    17:"ferrari",
    18:"renault",
    19:"ferrari",
    21:"redbull",
    22:"ferrari",
    23:"",
    24:"",
    25:"",
    26:"",
    27:"",
    28:"",
    29:"",
    30:"",
    31:"mclaren",
    32:"",
    33:"",
    34:"mclaren",
    35:"",
    36:"ferrari",
    37:"mclaren",
    38:"williams",
    39:"",
    40:"",
    41:"",
    42:"",
    43:"",
    44:"",
    45:"",
    46:"",
    47:"",
    48:"",
    49:"",
    50:"",
    51:"",
    53:"",
    54:"",
    55:"ferrari",
    56:"",
    63:"ferrari",
    64:"mclaren",
    65:"ferrari",
    255:""
}
exports.TEAM_COLORS_HEX = {
    0:"#86d1bf",
    1:"#db3832",
    2:"#305abe",
    3:"#62bbd9",
    4:"#46806e",
    5:"#468ec8",
    6:"#587b98",
    7:"#b7babd",
    8:"#d87e37",
    9:"#a12f3b",
    10:"#d87e37",
    11:"#d87e37",
    12:"#62bbd9",
    13:"#db3832",
    14:"#62bbd9",
    15:"#d87e37",
    16:"#db3832",
    17:"#db3832",
    18:"#468ec8",
    19:"#db3832",
    21:"#305abe",
    22:"#db3832",
    23:"",
    24:"",
    25:"",
    26:"",
    27:"",
    28:"",
    29:"",
    30:"",
    31:"#d87e37",
    32:"",
    33:"",
    34:"#d87e37",
    35:"",
    36:"#db3832",
    37:"#d87e37",
    38:"#62bbd9",
    39:"",
    40:"",
    41:"",
    42:"",
    43:"",
    44:"",
    45:"",
    46:"",
    47:"",
    48:"",
    49:"",
    50:"",
    51:"",
    53:"",
    54:"",
    55:"#db3832",
    56:"",
    63:"#db3832",
    64:"#d87e37",
    65:"#db3832",
    255:""
}

exports.TYRE_VISUAL = {
    16:"Soft",
    17:"Medium",
    18:"Hard",
    7:"Inter",
    8:"Wet",
    9:"Dry",
    10:"Wet",
    11:"Super Soft",
    12:"Soft",
    13:"Medium",
    14:"Hard",
    15:"Wet"
};

exports.TYRE_VISUAL_IMG = {
    16:"red",
    17:"yellow",
    18:"white",
    7:"green",
    8:"blue",
    9:"white",
    10:"blue",
    11:"violet",
    12:"red",
    13:"yellow",
    14:"white",
    15:"blue"
}


exports.DRIVER_STATUS = {
    0:"box",
    1:"", //flying lap
    2:"inlap",
    3:"outlap",
    4:"" // track
}

exports.PIT_STATUS = {
    0: "", //none, , 2 = in pit area
    1: "PIT", // pitting
    2: "PIT"
}

exports.SC_STATUS = {
    0: "", //none
    1: "SAFETY CAR", // FULL
    2: "VIRTUAL SAFETY CAR"
}

exports.GAME_MODE = {
    0: "Event Mode",
    3: "Grand Prix",
    5: "Time Trial",
    6: "Splitscreen",
    7: "Online Custom",
    8: "Online League",
    11: "Career Invitational",
    12: "Championship Invitational",
    13: "Championship",
    14: "Online Championship",
    15: "Online Weekly Event",
    19: "Career ‘22",
    20: "Career ’22 Online",
    127: "Benchmark"
}

exports.RULE_SETS = {
    0:	"Practice & Qualifying",
    1:	"Race",
    2:	"Time Trial",
    4:	"Time Attack",
    6:	"Checkpoint Challenge",
    8:	"Autocross",
    9:	"Drift",
    10:	"Average Speed Zone",
    11:	"Rival Duel"
}


exports.SESSION_LENGTH = {
    0:	"",
    2:	"Very Shor",
    3:	"Short",
    4:  "Medium",
    5:  "Medium Long",
    6:  "Long",
    7:  "Full"
}

exports.FASTEST_COLOR = "#AB3CDE";

exports.FORCE_DRIVER_STATUS = {
    ok :0,
    left: 1,
    promoted: 2,
    demoted: 3,
    banned:4
}