import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import Spinner from 'react-bootstrap/Spinner';

import { db, storage} from "../services/firebase";
import { getLapStats , getLapsForSession} from "racemapper_shared/data";
import { lapTimeFormat , sectorTimeFormat , timeOffset, percValue, speedValue} from "../helpers/format";
import { TYRE_VISUAL_IMG , TYRE_VISUAL , TRACK, SESSION_TYPE} from 'racemapper_shared/enums';
import { TRACK_DATA } from "../F1/tracks";

import { Scatter} from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Interaction,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';

import {chartsOptions} from '../charts/options'

const fetch = require('node-fetch');

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  annotationPlugin,
  CrosshairPlugin
);
Interaction.modes.interpolate = Interpolate;

// eslint-disable-next-line no-extend-native
Array.prototype.hasMin = function(attrib) {
  return this.reduce(function(prev, curr) {
    return prev[attrib] < curr[attrib] ? prev : curr;
  });
};

// eslint-disable-next-line no-extend-native
Array.prototype.hasMax = function(attrib) {
  return this.reduce(function(prev, curr) {
    return prev[attrib] > curr[attrib] ? prev : curr;
  });
};

const Statslap = props => {

  //  const sessionRaceID = props.match.params.sessionID;
  //  const raceID = props.match.params.raceID;
    const lapID = props.match.params.lapID;

    const [charts, setCharts] = React.useState([]);
    const [data1, setData1] = React.useState([]);
    const [data2, setData2] = React.useState([]);

    const [lap1, setLap1] = React.useState(null);
    const [lap2, setLap2] = React.useState(null);

    const [lap1Extras, setLap1Extras] = React.useState({});
    const [lap2Extras, setLap2Extras] = React.useState({});

    const [otherslaps, setOtherslaps] = React.useState([]);
    const [focus, setFocus] = React.useState("lap");

    const graphs = [{k:"speed",l:"Speed"},{k:"brake",l:"Brake"},{k:"throttle",l:"Throttle"},{k:"steer",l:"Steer"},{k:"engineRPM",l:"RPM"},{k:"gear",l:"Gear"},{k:"drs",l:"DRS"},{k:"engineTemperature",l:"Engine °C"}];
   
    const color1 = 'blue';
    const color2 = 'red';

    const styleLap1 = {backgroundColor:color1};
    const styleLap2 = {backgroundColor:color2};

    const getExtraData = (json) => {
      const minV = json.carStats.hasMin("speed");
      const maxV = json.carStats.hasMax("speed");

      let tmax = 0;
      let bmax = 0;
      let kerbs = [0,0,0,0];
      let offroad = [0,0,0,0];

      let prevdistance = 0;
      const ltime = json.carStats.hasMax("distance");

      json.carStats.forEach( el => {
        if( el.time > 0 && el.distance > prevdistance) {
          if(el["throttle"] === 1) {
            tmax += el.distance - prevdistance;
          }
          if(el["brake"] === 1) {
            bmax += el.distance - prevdistance;
          }
          el["surfaceType"].map( (v,i) => {
            if( v === 1) {
              kerbs[i] += el.distance - prevdistance;
            } else if (v > 1) {
              offroad[i] += el.distance - prevdistance;
            }
          });
 
          prevdistance = el.distance;
        }
      });

      return {"minV": minV["speed"], 
              "maxV": maxV["speed"], 
              "throttleMax":( 100 * tmax / ltime.distance),
              "brakeMax":( 100 * bmax / ltime.distance),
              "strip0":( 100 * kerbs[0] / ltime.distance),
              "strip1":( 100 * kerbs[1] / ltime.distance),
              "strip2":( 100 * kerbs[2] / ltime.distance),
              "strip3":( 100 * kerbs[3] / ltime.distance),
              "offroad0":( 100 * offroad[0] / ltime.distance),
              "offroad1":( 100 * offroad[1] / ltime.distance),
              "offroad2":( 100 * offroad[2] / ltime.distance),
              "offroad3":( 100 * offroad[3] / ltime.distance)
            };
    }
  
    const filterData = ( lap, focus, data, type, compact) => {
      let d = [];
    
      let timelimit = 0;
      let toffset = 0;
      let meterlimit = 0;
      let meterOffset = 0;

      const track =  TRACK_DATA[lap.trackID] ?? {s1:0,s2:0};
      switch (focus) {
        case "s1":
          timelimit = lap.sector1TimeInMS;
          toffset = -1000000; // to avoid filtering negative fvalues
          meterOffset = -1000000;
          meterlimit = track.s1;
        break;
        case "s2":
          timelimit = lap.sector1TimeInMS + lap.sector2TimeInMS;
          toffset = lap.sector1TimeInMS;
          meterOffset = track.s1;
          meterlimit = track.s2;
        break;
        case "s3":
          timelimit = lap.lapTimeInMS;
          toffset = lap.sector1TimeInMS + lap.sector2TimeInMS;
          meterOffset = track.s2;
          meterlimit = 99999999999;
        break;
        default: timelimit = 0;
      }
        
      if( TRACK_DATA[lap.trackID]) {
        let lastdata = 0;
        let lastdistance = 0;
  
        data.carStats.forEach( el =>  {
          if (el.distance > lastdistance
            && (!compact || lastdata !== el[type]) 
            && ( meterlimit === 0 || ( el.distance >= meterOffset && el.distance <= meterlimit))) { 
            d.push({'x': el.distance, 'y': el[type]});
                lastdistance = el.distance;
                lastdata = el[type];
            }
        });
      } else {
        let lastdata = 0;
        let lastdistance = 0;
        let lastt = -10000; // some times there is a glitch with timing
  
        data.carStats.forEach( el =>  {
          if (el.distance > lastdistance 
            && (!compact || lastdata !== el[type]) 
            && ( timelimit === 0 || ( el.time >= toffset && el.time <= timelimit && lastt <= el.time))) { 
            d.push({'x': el.distance, 'y': el[type]});
                lastdistance = el.distance;
                lastt = el.time;
                lastdata = el[type];
            }
        });
      }
      return d;
    }
  
    const getChartData = ( type) => {  
      let datasets = [];

      const compact = ["speed"].includes(type);

      const d1 = filterData( lap1, focus, data1, type, compact);
      datasets.push(
        {
          label: type,
          showLine: true,
          data: d1,
          borderColor: color1,
          pointRadius: 0
        }
      );
      if( data2.carStats) {
        const d2 = filterData( lap2, focus, data2, type, compact);
        datasets.push(
          {
            label: type,
            data: d2,
            borderColor: color2,
            pointRadius: 0,
            showLine: true,
          }
        );
      }
  
      return {
        datasets: datasets
      };
  }

  const getDeltaData = () => {  
      let datasets = [];

      let d = [];
      let lastdistance = 0;

      let meterlimit = 0;
      let meterOffset = 0;

      if( TRACK_DATA[lap1.trackID]) {
        const track =  TRACK_DATA[lap1.trackID] ?? {s1:0,s2:0};
        switch (focus) {
          case "s1":
            meterOffset = -1000000;
            meterlimit = track.s1;
          break;
          case "s2":
            meterOffset = track.s1;
            meterlimit = track.s2;
          break;
          case "s3":
            meterOffset = track.s2;
            meterlimit = 99999999999;
          break;
          default: meterlimit = 0;
        }
      }

      data1.carStats.forEach( el =>  {
        if (el.distance > lastdistance && (el.distance - lastdistance) >= 50
        && ( meterlimit === 0 || ( el.distance >= meterOffset && el.distance <= meterlimit))) { 
          let closest = -1;
          for(var i=0;i<data2.carStats.length;i++){
            if( data2.carStats[i].distance >= el.distance) {
              closest = data2.carStats[i].time
              break;
            }
          }
          const delta =  el.time - closest;
          if( closest > 0 && el.time > 0 && closest > 0 && delta < 1500) {
            d.push({'x': el.distance, 'y': delta});
            lastdistance = el.distance;
          }
        }
      });
    
  //  d.push({'x': data1.carStats[data1.carStats.length -1].distance, 'y': data1.carStats[data1.carStats.length -1].time - data2.carStats[data1.carStats.length -1].time});
    datasets.push(
      {
        label: "delta",
        showLine: true,
        data: d,
        borderColor: color1,
        pointRadius: 0
      }
    );

    return {
      datasets: datasets
    };
}

    const toggle = e => {
      const k = e.target.id;
      if( !charts.includes(k)) {
        var tmp = charts.slice();
        tmp.push(k);
        setCharts( tmp);
      } else {
        const newa = charts.filter(item => item !== k);
        setCharts( newa);
      }
    }

  const handleSelectLap1=(lapID)=>{
    if (lap1.id !== lapID) {
      getLap1Data( lapID);
    }
  }

  const getLap1Data = async(lap1ID) => {    
    const lap = await getLapStats( db, lap1ID);
    setLap1(lap);
    const json = await downloadJsonLap(lap1ID);
    setLap1Extras( getExtraData(json));
    setData1(json);
  }

  const handleSelectLap2=(lap2ID)=>{
    if (!lap2 || lap2.id !== lapID) {
      getLapStats( db, lap2ID).then(
        lap => {
          setLap2(lap);
          getLap2Data( lap.id);
        }
      );
    }
  }
  const getLap2Data = async(lap2ID) => {     
    setCharts( []);   
    downloadJsonLap(lap2ID)
    .then( json => {
      setLap2Extras( getExtraData(json));
      setData2(json);
      const lastc = charts;
      setCharts( lastc);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const downloadJsonLap = async(lID) => {
    const gsReference = storage.refFromURL( 'gs://race-telemetry-f12021-laps/F1-2022/'+lID+'.json');
    return gsReference.getDownloadURL()
    .then((url) => {
        const settings = { method: "Get" };
        return fetch(url, settings);
    })
    .then( res => { return res.json()})
    .catch((error) => {
      console.log(error);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
     /* switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;        
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }*/
    });
  }

  const onFocuseChanged = ( value, control) => {
    setFocus(value);
  }

  const getChartOptions = ( k ) => {
    chartsOptions[k].plugins.annotation = {"annotations":{}};
    if( ["speed"].includes(k) && focus === "lap" && (lap1 && TRACK_DATA[lap1.trackID])) {
      chartsOptions[k].plugins.annotation["annotations"]["s1"] = {
        type: 'line',
        xMin: TRACK_DATA[lap1.trackID]["s1"],
        xMax: TRACK_DATA[lap1.trackID]["s1"],
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2
      };
      chartsOptions[k].plugins.annotation["annotations"]["s1l"] = {
        type: 'label',
        xValue: TRACK_DATA[lap1.trackID]["s1"],
        yValue: 10,
        backgroundColor: 'rgba(245,245,245)',
        content: 's2',
      };
      chartsOptions[k].plugins.annotation["annotations"]["s2"] = {
        type: 'line',
        xMin: TRACK_DATA[lap1.trackID]["s2"],
        xMax: TRACK_DATA[lap1.trackID]["s2"],
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2
      };
      chartsOptions[k].plugins.annotation["annotations"]["s2l"] = {
        type: 'label',
        xValue: TRACK_DATA[lap1.trackID]["s2"],
        yValue: 10,
        backgroundColor: 'rgba(245,245,245)',
        content: 's3',
      };
    }
    return chartsOptions[k];
  }
  useEffect(() => {
        const getLapData = async() => {        
          const json = await downloadJsonLap(lapID);
          setLap1Extras( getExtraData(json));
          setData1(json);
          setCharts( ["speed","brake","throttle"]);
        }

        const getLocalLapData = async() => {        
          const url = '/static/laps/11678491598535940461-1-2.json';
          const settings = { method: "Get" };
          fetch(url, settings)
          .then( res => res.json())
          .then( json => {
            setLap1Extras( getExtraData(json));
            setData1(json);
            setCharts( ["speed","brake","throttle"]);
          })
          .catch((error) => {
            console.log(error);
          });
        }
        const getLap1DataFromDB = async() => {   
            const lap = await getLapStats( db, lapID);
            setLap1(lap);
            getOthersLaps(lap);
        }

        const getOthersLaps = async( lap) => {   
          const laps = await getLapsForSession( db, lap.sessionUID);
          setOtherslaps(laps.sort( (function (a, b) {return (a.lapTimeInMS < b.lapTimeInMS) ? -1 : 1;})));
        }
        getLapData();
        //getLocalLapData();
        getLap1DataFromDB();
    }, [lapID]);

    return (
    <>
    <Link to={`../../${lapID.split("-")[0]}`}><Button size="sm" variant='outline-dark'>Back</Button></Link>
    {lap1 ?
    <>
      <h4>{`${TRACK[lap1.trackID]} - ${SESSION_TYPE[lap1.sessionType]}`}</h4>
      <Table striped bordered hover size="sm" className="compareLaps">
        <thead>
          <tr>
            <th>&nbsp;</th>        
            <th>Driver</th>
            <th>Time</th>
            <th>S1</th>
            <th>S2</th>
            <th>S3</th>
            <th>MinV</th>
            <th>MaxV</th>
            <th>FullT</th>
            <th>FullB</th>
            <th>Kerbs</th>
            <th>Offroad</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td style={styleLap1}></td>
              <td> 
                <DropdownButton variant="outline-dark" size='sm' as={ButtonGroup} onSelect={handleSelectLap1} title={ (lap1 && lap1.driverName) || "Select"} id="bg-lap1-driver">
                    {otherslaps.map( (lap) => 
                      <Dropdown.Item key={lap.id} eventKey={lap.id}>{lapTimeFormat(lap.lapTimeInMS)} - {lap.driverName}</Dropdown.Item>
                    )}     
                </DropdownButton>
              </td>
              <td>{lapTimeFormat(lap1.lapTimeInMS)}</td>
              <td>{sectorTimeFormat(lap1.sector1TimeInMS)}</td>
              <td>{sectorTimeFormat(lap1.sector2TimeInMS)}</td>
              <td>{sectorTimeFormat(lap1.sector3TimeInMS)}</td>
              <td>{speedValue(lap1Extras["minV"])}</td>
              <td>{speedValue(lap1Extras["maxV"])}</td>
              <td>{percValue(lap1Extras["throttleMax"])}</td>
              <td>{percValue(lap1Extras["brakeMax"])}</td>
              <td>{percValue(lap1Extras["strip2"])} {percValue(lap1Extras["strip3"])}<br />{percValue(lap1Extras["strip0"])} {percValue(lap1Extras["strip1"])}</td>
              <td>{percValue(lap1Extras["offroad2"])} {percValue(lap1Extras["offroad3"])}<br />{percValue(lap1Extras["offroad0"])} {percValue(lap1Extras["offroad1"])}</td>
              <td><img className="tyre" src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[lap1.tyreVisualCompound]}.png`} alt={TYRE_VISUAL[lap1.tyreVisualCompound]} width="20" height="20" /></td>
            </tr>
             <tr>
              <td style={styleLap2}></td>
                <td>
                  <DropdownButton variant="outline-dark" size='sm' as={ButtonGroup} onSelect={handleSelectLap2} title={ (lap2 && lap2.driverName) || "Compare"} id="bg-nested-dropdown">
                    {otherslaps.map( (lap) => 
                      <Dropdown.Item key={lap.id} eventKey={lap.id}>{lapTimeFormat(lap.lapTimeInMS)} - {lap.driverName}</Dropdown.Item>
                    )}     
                  </DropdownButton>
                </td>
                {lap2?
                <>
                <td>{lapTimeFormat(lap2.lapTimeInMS)} {timeOffset(lap2.lapTimeInMS, lap1.lapTimeInMS, 2)}</td>
                <td>{sectorTimeFormat(lap2.sector1TimeInMS)} {timeOffset(lap2.sector1TimeInMS, lap1.sector1TimeInMS, 2)}</td>
                <td>{sectorTimeFormat(lap2.sector2TimeInMS)} {timeOffset(lap2.sector2TimeInMS, lap1.sector2TimeInMS, 2)}</td>
                <td>{sectorTimeFormat(lap2.sector3TimeInMS)} {timeOffset(lap2.sector3TimeInMS, lap1.sector3TimeInMS, 2)}</td>
                <td>{speedValue(lap2Extras["minV"])}</td>
                <td>{speedValue(lap2Extras["maxV"])}</td>
                <td>{percValue(lap2Extras["throttleMax"])}</td>
                <td>{percValue(lap2Extras["brakeMax"])}</td>
                <td>{percValue(lap2Extras["strip2"])} {percValue(lap2Extras["strip3"])}<br />{percValue(lap2Extras["strip0"])} {percValue(lap2Extras["strip1"])}</td>
                <td>{percValue(lap2Extras["offroad2"])} {percValue(lap2Extras["offroad3"])}<br />{percValue(lap2Extras["offroad0"])} {percValue(lap2Extras["offroad1"])}</td>
                <td><img className="tyre" src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[lap2.tyreVisualCompound]}.png`} alt={TYRE_VISUAL[lap2.tyreVisualCompound]} width="20" height="20" /></td>
                </>
                :
                <>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </>}
              </tr>
        </tbody>
      </Table>
    </>
    :
     <div className="d-flex justify-content-center">
          <Spinner animation="grow" variant="secondary" />
      </div>
    }
      {data1.lapID ?
      <>
        <ButtonGroup>
            <DropdownButton  size="sm" variant="primary"  title="Graphs" id="add-chart" as={ButtonGroup}>
            {
                graphs.map(item => (
                  <Form.Check 
                  type="checkbox"
                  id={item.k}
                  key={item.k}
                  label={item.l}
                  onChange={toggle}
                  checked={charts.includes(item.k)}
                />
                ))
            }
            </DropdownButton>
        </ButtonGroup>
        <ToggleButtonGroup size="sm" type="radio" name="focus" defaultValue={0} onChange={onFocuseChanged}>
          <ToggleButton variant="outline-primary" id="tbg-radio-1" value={0}>
            Lap
          </ToggleButton>
          <ToggleButton variant="outline-primary" id="tbg-radio-2" value="s1">
            S1
          </ToggleButton>
          <ToggleButton variant="outline-primary" id="tbg-radio-3" value="s2">
            S2
          </ToggleButton>
          <ToggleButton variant="outline-primary" id="tbg-radio-4" value="s3">
            S3
          </ToggleButton>
      </ToggleButtonGroup>
      </>
      :
      <div className="d-flex justify-content-center">
          <Spinner animation="grow" variant="secondary" />
      </div>
      }
      <Form>
</Form>
      {lap1 && data2 && data2.carStats && data2.carStats.length &&
        <Row key={`r-delta`}><Col key={`c-delta`}><div key={`d-delta`} style={{"position": "relative", "height":"20vh", "width":"100%"}}><Scatter options={chartsOptions["delta"]} data={getDeltaData()} /></div></Col></Row>
      }
       {charts && charts.map( (k,i) => {
        return <Row key={`r-${k}`}><Col key={`c-${k}`}><div key={`d-${k}`} style={{"position": "relative", "height":"40vh", "width":"100%"}}><Scatter options={getChartOptions(k)} data={getChartData(k)} /></div></Col></Row>
      })} 
    </>
    );

};
export default Statslap;