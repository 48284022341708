import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { db } from '../services/firebase';
import { COLLECTION_ID } from "racemapper_shared/data";

export default function EditSesssionLabel( props) {  
    const [sessionName, setSessionName] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    const onLabelSave = () => {
        const label = sessionName.trim();
        if(label.length) {
            setLoading(true);
            const ref = db.collection(COLLECTION_ID).doc(props.sessionID);
            ref.set( {label}, { merge: true }).then( res =>{
                setLoading(false);
                props.onHide(label);
                hideModal();
            });
        }
    }

    const hideModal = () => {
        setShowModal(false);
    }
    return (
        <>
        <OverlayTrigger
            key={`setlabel`}
            placement="top"
            overlay={
                    <Tooltip>
                        Edit Label
                    </Tooltip>
                    }
        >
            <Button key={`edit-lbl`} 
                variant="outline-dark" 
                size='sm'
                onClick={() => setShowModal(true)}>
                <img alt="lbl" src="/static/icons/pencil-square.svg" style={{width: 20}} />
            </Button>
        </OverlayTrigger>
        
        <Modal show={showModal} onHide={hideModal} size="sm" centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Label</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="editDriver.Label">
                <Form.Control
                  type="text"
                  placeholder="ex: Social Race"
                  defaultValue={props.label ?? ""}
                  onChange={event => setSessionName( event.target.value)}
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" 
                disabled={isLoading}
                onClick={!isLoading ? onLabelSave : null}
            >
            {isLoading ? 'Saving…' : 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        </>
    );
  }
