import React, { Component } from 'react';
import {lapTimeFormat, driverFormat, teamFormat,sectorTimeFormat, timeOffset, lblAssistants, plainTimeDelta} from '../helpers/format';
import {DRIVER_STATUS,RESULT_STATUS,TYRE_VISUAL_IMG,TYRE_VISUAL} from 'racemapper_shared/enums';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function classForSector(sector, overallBestSector) {
  if( sector === overallBestSector) {
    return "fastest";
  }
  return "";
}

/*
function classForLastLap(item, fastest) {
  if( item.s2class === 'invalidated') {
    return "invalidated";
  }

  if( item.lastLapTimeInMS === fastest) {
    return "fastest";
  }

  if( item.lastLapTimeInMS === item.bestLapTime) {
    return "faster";
  }
}
*/

function cellForItem( item, fastest) {
 // if( item.driverStatus === 1 ||  item.driverStatus === 4) {
    if( item.sector1TimeInMS === 0 && item.lastLapTimeInMS > 0) {
      return ""; //<span className={classForLastLap( item, fastest)}>{lapTimeFormat(item.lastLapTimeInMS)}</span>
    } else if( item.s1offset ) {
      const offset = item.s1offset + item.s2offset;
      if( offset < 3000 && offset > -10000) {
        return <span>{plainTimeDelta(item.s1offset + item.s2offset)}</span>
      }
    }
 // }
  return "";
}
function lblPenalities(item) {
  if( item.penalties === 0) {
    return "";
  }
  return  "+" + item.penalties + " s.";
}

function badgeForStatus( status) {
  const bg = status === 3 ? "primary" : "secondary";
  return <Badge bg={bg}>{DRIVER_STATUS[status]}</Badge>
}

class RaceQLapData extends Component {

    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
    }

    render() {
      return(
        <>                     
<Table striped  hover size="sm" className="racetab">
  <thead>
    <tr key={`lp-driver-header`}>
      <th className="positionCell"></th>
      <th></th>
      <th></th>
      <th>BEST</th>
      <th>S1</th>
      <th>S2</th>
      <th>S3</th>
      <th>CUR.</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
{ this.props.partecipants && this.props.lapData.filter( r => r.resultStatus > 1)
    .sort((a, b) => {
      return a.carPosition - b.carPosition;
      }
    ).map( item => (
    <tr key={`lp-driver-${item.driver}`} id={`lp-driver-${item.driver}`}>
      <td key={`lp-driver-${item.driver}-p`}>{item.carPosition} {RESULT_STATUS[item.resultStatus]}</td>
      <td key={`lp-driver-${item.driver}-d`} className="cell-driver">
          <OverlayTrigger
            key={`lp-driver-${item.driver}-t`}
            placement="right"
            overlay={
              <Tooltip>
                {teamFormat(this.props.partecipants[item.driver].teamId)}<br />
                {lblAssistants(item)}
              </Tooltip>
            }
            >
            <Button variant="link" onClick={this.props.onPress(item.driver)}>
            {driverFormat(this.props.partecipants[item.driver])}
            </Button>
          </OverlayTrigger>  
          <Badge bg="danger">{lblPenalities(item)}</Badge>
          {badgeForStatus(item.driverStatus)}
      </td>
      <td key={`lp-driver-${item.driver}-dlt`} className="lp-delta">{timeOffset(item.bestLapTime, this.props.overallFastestLap.lapTime, item.carPosition)}</td>
      <td key={`lp-driver-${item.driver}-bl`} className="lp-bestlap">{lapTimeFormat(item.bestLapTime)}</td>
      <td key={`lp-driver-${item.driver}-s1`} className="cell-small">
          { item.bestLapSector1TimeInMS !== 0 &&
            <span className={classForSector( item.bestLapSector1TimeInMS, this.props.overallFastestLap.s1)}>
              {sectorTimeFormat(item.bestLapSector1TimeInMS || item.sector1TimeInMS)}
            </span>
          } 
          <span className={`timing timing-${item.s1class}`}></span>
      </td>
      <td key={`lp-driver-${item.driver}-s2`} className="cell-small">
        { item.bestLapSector2TimeInMS !== 0 &&
          <span className={classForSector( item.bestLapSector2TimeInMS, this.props.overallFastestLap.s2)}>{sectorTimeFormat(item.bestLapSector2TimeInMS || item.sector2TimeInMS)}</span>
        }
        <span className={`timing timing-${item.s2class}`}></span>
      </td>
      <td key={`lp-driver-${item.driver}-s3`} className="cell-small">
          { item.bestLapSector3TimeInMS !== 0 &&
                <span className={classForSector( item.bestLapSector3TimeInMS, this.props.overallFastestLap.s3)}>{sectorTimeFormat(item.bestLapSector3TimeInMS || item.sector3TimeInMS)}</span>
          }
          <span className={`timing timing-${item.s3class}`}></span>
      </td>
      <td key={`lp-driver-${item.driver}-bf`} className="lp-bestlap">
        {cellForItem( item, this.props.overallFastestLap.lapTime)}
      </td>
      <td key={`lp-driver-${item.driver}-tyres`}><img className="tyre" key={`lp-driver-${item.driver}-tv`} src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[item.visualTyreCompound]}.png`} alt={TYRE_VISUAL[item.visualTyreCompound]} width="20" height="20" /></td>

    </tr>))
}

  </tbody>
</Table>
        </>);
    }
  }
  
  export default RaceQLapData;