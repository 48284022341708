import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';

import PointSystemComponent from './PointSystemComponent';

export default function PointSystemCanvas( props) {  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button size='sm' variant="outline-dark" onClick={handleShow}>
          Show
        </Button>
  
        <Offcanvas show={show} onHide={handleClose} placement="top">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className='font-face-f1'>Points system</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <PointSystemComponent points={props.points} hidetitle={true} />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
}
  
