import React, { Component } from 'react';
import {lapTimeFormat, driverFormat, teamFormat,sectorTimeFormat,lblAssistants} from '../helpers/format';
import {PIT_STATUS,RESULT_STATUS,TYRE_VISUAL_IMG,TYRE_VISUAL} from 'racemapper_shared/enums';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function classForSector(sector, overallBestSector) {
  const c = "lp-sector";  
  if( sector === overallBestSector) {
    return c + " fastest";
  }
  return c;
}

function classForBestLap(laptime, fastest) {
  if( !laptime) return "";

  if( laptime.toFixed(0) === fastest.toFixed(0)) {
    return "fastest";
  }
  return "";
}

function classForLastLap(item, fastest) {
  if( !item.lastLapTimeInMS) return "";

  if( item.lastLapTimeInMS.toFixed(3) === fastest.toFixed(3)) {
    return "fastest";
  }

  if( item.lastLapTimeInMS === item.bestLapTime) {
    return "faster";
  }
}

function lblPenalities(item) {
  if( item.penalties === 0) {
    return "";
  }
  return  "+" + item.penalties + " s.";
}

function lblWarnings(item) {
  if( item.warnings === 0) {
    return "";
  }
  return item.warnings;
}

function lblDelta(item, firstItem, prevItem, overall) {
  if( item.carPosition === 1) {
      return "Lap " + item.currentLapNum;
  }

  if( firstItem.currentLapNum - item.currentLapNum > 1) {
      return "+" + (firstItem.currentLapNum - item.currentLapNum - 1 ) + " laps";
  }

  if( item.delta && item.delta !== 0) {
    if( overall || item.carPosition === 2) {
      return "+" + item.delta.toFixed(3);
    }
    if( prevItem && prevItem.delta && prevItem.delta !== 0) {
      return "+" + ( item.delta - prevItem.delta).toFixed(3);
    }
  }
  return "Lap " + item.currentLapNum;
}

function tyresAges(item ) {
  if( item.tyresAgeLaps === -1 || item.tyresAgeLaps === 0 ) {
      return 0;
  }
  return item.tyresAgeLaps;
}

function drsLabel(item) {
  if( item.drsAllowed) {
    return "drs";
  }
  return "";
}

function badgesForItem( item) {
  let bdgs = [];
  if( item.pitStatus > 0) {
    bdgs.push(<Badge bg="secondary">{PIT_STATUS[item.pitStatus]}</Badge>);
  }
  if( item.drsAllowed) {
    bdgs.push(<Badge bg="primary">{drsLabel(item)}</Badge>);
  }
/*  
  don't have the info in the laps
  if( item.fuelRemainingLaps) {
    const damage = item.frontLeftWingDamage + item.frontRightWingDamage + item.rearWingDamage +  item.diffuserDamage + item.sidepodDamage + item.floor + item.drsFault;
    if( damage > 0) {
      bdgs.push(<Badge bg="warning">damage</Badge>);
    }
  }
  */
  return bdgs;
}

class RaceLapData extends Component {

    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
    }

    render() {
      return(
        <div>
<Table striped  hover size="sm" className="racetab">
  <thead>
    <tr key={`lp-driver-header`}>
      <th className="positionCell"></th>
      <th>DRIVER</th>
      <th>PEN</th>
      <th></th>
      <th></th>
      <th></th>
      <th>PIT</th>
      <th>BEST</th>
      <th>LAST</th>
      <th>S1</th>
      <th>S2</th>
    </tr>
  </thead>
  <tbody>
{ this.props.partecipants && this.props.lapData.map( (item, i) => (
    <tr key={`lp-driver-${item.driver}`} id={`lp-driver-${item.driver}`}>
      <td key={`lp-driver-${item.driver}-p`} className="positionCell">{item.carPosition} {RESULT_STATUS[item.resultStatus]}</td>
      <td key={`lp-driver-${item.driver}-d`} className="cell-driver">
          <OverlayTrigger
            key={`lp-driver-${item.driver}-t`}
            placement="right"
            overlay={
              <Tooltip>
                {teamFormat(this.props.partecipants[item.driver].teamId)}<br />
                {lblAssistants(item)}
              </Tooltip>
            }
            >
            <Button variant="link" onClick={this.props.onPress(item.driver)}>
            {driverFormat(this.props.partecipants[item.driver])}
            </Button>
          </OverlayTrigger>
          {badgesForItem(item)}
      </td>
      <td key={`lpr-${item.driver}-pen`}>
        <Badge bg="danger">{lblPenalities(item)}</Badge>
        <Badge bg="warning">{lblWarnings(item)}</Badge>
      </td>
      <td key={`lp-driver-${item.driver}-dlt`} className="lp-delta">{lblDelta(item, this.props.lapData[0], this.props.lapData[i-1], false)}</td>
      <td key={`lp-driver-${item.driver}-tyres`}>
        <img className="tyre" key={`lp-driver-${item.driver}-tv`} src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[item.visualTyreCompound]}.png`} alt={TYRE_VISUAL[item.visualTyreCompound]} width="20" height="20" />
      </td>
      <td key={`lp-driver-${item.driver}-tyres-ages`}>
        <code>{tyresAges(item)}</code>
      </td>
      <td key={`lp-driver-${item.driver}-pit`} className="centerTxt">
        <code>{item.numPitStops}</code>
      </td>
      <td key={`lp-driver-${item.driver}-bl`} className="lp-bestlap">
        <span className={classForBestLap( item.bestLapTime, this.props.overallFastestLap.lapTime)}>{lapTimeFormat(item.bestLapTime)}</span><br />
        <span className={classForSector( item.bestLapSector1TimeInMS, this.props.overallFastestLap.s1)}>{sectorTimeFormat(item.bestLapSector1TimeInMS)}</span>
        <span className={classForSector( item.bestLapSector2TimeInMS, this.props.overallFastestLap.s2)}>{sectorTimeFormat(item.bestLapSector2TimeInMS)}</span>
        <span className={classForSector( item.bestLapSector3TimeInMS, this.props.overallFastestLap.s3)}>{sectorTimeFormat(item.bestLapSector3TimeInMS)}</span>
      </td>
      <td key={`lp-driver-${item.driver}-bf`} className="lp-bestlap">
        <span className={classForLastLap( item, this.props.overallFastestLap.lapTime)}>{lapTimeFormat(item.lastLapTimeInMS)}</span>
      </td>
      <td key={`lp-driver-${item.driver}-s1`} className="cell-small">
      {item.resultStatus !== 7 && // RT
        <>
          {sectorTimeFormat(item.sector1TimeInMS)}
          <span className={`timing timing-${item.s1class}`}></span>
          </>
        }
      </td>
      <td key={`lp-driver-${item.driver}-s2`} className="cell-small">
        {item.resultStatus !== 7 &&
        <>
        {sectorTimeFormat(item.sector2TimeInMS)}
        <span className={`timing timing-${item.s2class}`}></span>
        </>
        }
      </td>
    </tr>))
}

  </tbody>
</Table>
        </div>);
    }
  }
  
  export default RaceLapData;