import React, {useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';

import { db } from '../services/firebase';
import { COLLECTION_ID, getOrganizationsData, getChampionshipsData} from 'racemapper_shared/data';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const HomePage = props => {

    const orgDefLabel = "Organisations";
    const champDefLabel = "All tiers";

    let firstLoad = useRef(true);

    const [loading, setLoading] = React.useState(true);

    const [sessions, setSessions] = React.useState([]);

    const [organizations, setOrganizations] = React.useState([]);
    const [championships, setChampionships] = React.useState([]);

    const [organisationLabel, setOrganisationLabel] = React.useState(orgDefLabel);
    const [championshipLabel, setChampionshipLabel] = React.useState(champDefLabel);

    const search = props.location.search;
    const org = new URLSearchParams(search).get("o");
    const [currentOrg, setCurrentOrg] = React.useState(org);

    const champ = new URLSearchParams(search).get("c");
    const [currentChamp, setCurrentChamp] = React.useState(champ);

    const handleSelectOrg=(e)=>{
        setCurrentOrg(e);
        const params = new URLSearchParams({ "o": e });
        props.history.replace({ pathname: props.location.pathname, search: params.toString()});
        
        const o = organizations.filter( item => item.id === e)[0].label;
        setOrganisationLabel(o);
        setCurrentChamp(false);
        setChampionships([]);
        setChampionshipLabel(champDefLabel);
    }

    const handleSelectChamp=(e)=>{
        if( e === "-1") {
            setCurrentChamp(false);
            setChampionships([]);
            setCurrentOrg(false);
            setOrganisationLabel(orgDefLabel);
            const params = new URLSearchParams({});
            props.history.replace({ pathname: props.location.pathname, search: params.toString() });
        } else {
            setCurrentChamp(e);
            const params = new URLSearchParams({ "c": e , "o":currentOrg});
            props.history.replace({ pathname: props.location.pathname, search: params.toString() });
            const o = championships.filter( item => item.id === e)[0].label;
            setChampionshipLabel(o);
        }
    }
    
    useEffect(() => {
        const getData = () => {
            try {
                const dbref = db.collection(COLLECTION_ID)
                .where("private","==", false)
                .orderBy("created","desc")
                .limit(20);
                let q;
                if( currentChamp) {
                    q =  dbref.where("championshipId", "==", currentChamp);
                } else if( currentOrg) {
                    q = dbref.where("organisationId", "==", currentOrg);
                } else {
                    q = dbref;
                }
                q.onSnapshot(docSnapshot => {
                    const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}));
                    setSessions( arrayData);
                    setLoading(false);

                });

                if( currentOrg && (!currentChamp || firstLoad.current)) {
                    getChampionshipsData( db, currentOrg).then( data => {
                        setChampionships( data);
                        if( currentChamp) {
                            const o = data.filter( item => item.id === currentChamp)[0].label;
                            setChampionshipLabel(o);
                        }
                    });
                }
            } catch( error) {
                console.log(error);
            }
        }
        getData();
        if( firstLoad.current) {
            firstLoad.current = false;
            getOrganizationsData( db).then( data => {
                setOrganizations( data);
                if( currentOrg) {
                    const o = data.filter( item => item.id === currentOrg)[0].label;
                    setOrganisationLabel(o);
                }
            });
        }
    }, [currentOrg, currentChamp]);

    /*useEffect(() => {
        const getData = () => {
            try {
                setLoading(true);
                const dbref = db.collection(COLLECTION_ID).orderBy("created","desc");
                let q;
                if( currentChamp) {
                    q =  dbref.where("championshipId", "==", currentChamp);
                } else if( currentOrg) {
                    q = dbref.where("organisationId", "==", currentOrg);
                } else {
                    q = dbref;
                }
                q.onSnapshot(docSnapshot => {
                    const arrayData = docSnapshot.docs.map( doc => ({id:doc.id, ...doc.data()}));
                    setSessions( arrayData);
                    setLoading(false);
                });
            } catch( error) {
                console.log(error);
            }
        }
        getData();
        //eslint-disable-next-line
    }, [currentOrg, currentChamp]);*/

    function pillbg( org) {
        let bg = "primary";
        let text = "light";
        switch( org) {
            case 'outplayed.racing':
            case 'Outplayed Racing':
                bg =  "light";
                text = "dark";
            break;
            case 'WOR': bg =  "secondary";break;
            case 'Virtual Racing League': 
                bg =  "warning";
                text = "dark";
            break;
            case 'RCI': 
                bg =  "danger";
            break;
            case 'Racing League IT': 
                bg =  "success";
            break;
            default:
        }
        return <Badge pill bg={bg} text={text} className="badge-bottom-right" >{org}</Badge>;
    }
    return (<>
            <Header></Header>
            <main className="bg-light">
            <div
            className="bg-image p-5 text-center shadow-1-strong mb-4 text-white"
            style={{backgroundImage: 'url(/static/images/a1.jpg)'}}
            >
                <h1 className='font-face-f1'>Last F1-2022 game races</h1>
            </div>
            <Container>
                <div className="float-end" style={{top:"0px !important"}}>
                    <DropdownButton variant="outline-dark" onSelect={handleSelectOrg} title={organisationLabel} size="sm" id="dropdown-orgs" as={ButtonGroup}>
                        {
                            organizations.map(item => (
                            <Dropdown.Item key={item.id} eventKey={item.id} active={item.id === currentOrg}>{item.label}</Dropdown.Item>
                            ))
                        }
                    </DropdownButton>

                    { championships.length > 0 &&
                        <DropdownButton variant="outline-dark" onSelect={handleSelectChamp} title={championshipLabel} size="sm" id="dropdown-champ" as={ButtonGroup}>
                        {
                            championships.map(item => (
                            <Dropdown.Item key={item.id} eventKey={item.id} active={item.id === currentChamp}>{item.label}</Dropdown.Item>
                            ))
                        }
                        { currentOrg &&
                            <>
                            <Dropdown.Divider />
                            <Dropdown.Item key="-1" eventKey="-1">Clear filter</Dropdown.Item>
                            </>
                        }
                        </DropdownButton>
                    } 
                </div>
                {loading && (
                        <div className="d-flex justify-content-center">
                            <Spinner animation="grow" variant="secondary" />
                        </div>
                 )}
                <Row xs={1} md={3} className="g-4">
                    {
                        sessions.map(item => (
                            <Col key={`col-${item.id}`}>
                                
                                <Card className="bg-dark text-white" key={item.id} style={{minHeight:"170px"}}>
                                <Card.Body>
                                    <Card.Title className='font-face-f1-bold'>
                                    <Link to={{pathname:`/session/${item.id}`, session: item}} className="stretched-link link-light">{item.label||item.id}</Link></Card.Title>
                                    <Card.Text className='font-face-f1'>
                                    {item.created.toDate().toLocaleDateString( 'it-IT')}
                                    </Card.Text>
                                    {pillbg(item.organisationLabel)}
                                    
                                </Card.Body>
                                </Card>
                                

                            </Col>
                        ))
                    }
                </Row>
            </Container>
            </main>
            <Footer></Footer>
            </>
    );
};
export default HomePage;


/*
<div className="mt-4">
    <Link className="btn btn-primary px-5 mr-3" to="/signup">Create New Account</Link>
    <Link className="btn px-5" to="/login">Login to Your Account</Link>
</div>
*/