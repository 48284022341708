import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import { db , storage } from '../services/firebase';
import { auth } from '../services/firebase';
import { COLLECTION_ID, CALENDAR_COLLECTION } from "racemapper_shared/data";

import path from 'path';

export default function ModalUploadBriefingFile ( props) {  
    const [isLoading, setLoading] = React.useState(false);
    const [isDeleting, setDeleting] = React.useState(false);

    const [file, setFile] = React.useState('');


    const getPath = () => {
      return `/u/${auth().currentUser.uid}/s/w/` + props.race.id + path.extname(file.name);
    }
    const onSuccess = (ref) => {
      
      ref.getDownloadURL().then( (briefing) => {
        const ref = db.collection(CALENDAR_COLLECTION).doc(props.race.id)
        ref.set({briefing},{ merge: true }).then(()=>{
          setFile('');
          setLoading(false);
          props.onHide(briefing);
        });
        if( props.race.telemetry) {
          const refs = db.collection(COLLECTION_ID).doc(props.race.telemetry)
          refs.set({briefing},{ merge: true });
        }
      });
    }

    const onError = (e) => {
      alert("error");
      console.log(e);
      setLoading(false);
  }

    const onFormSubmit = (e) => {
      e.preventDefault();

      if(!file) return;
      setLoading(true);
      
      const ref = storage.ref(getPath());
      ref.put(file)
        .on("state_changed", (snapshot) => {}, onError, () => {onSuccess( ref)});

      return false;
    }

    const deleteBriefing = () => {
      setDeleting(true);
      const tmp = (getPath() + path.extname(props.race.briefing)).split('?');
      const ref = storage.ref(tmp[0]);
      ref.delete().then(
        () => {
          const newdata = {'briefing': ''};
          const ref = db.collection(CALENDAR_COLLECTION).doc(props.race.id)
          ref.set(newdata,{ merge: true }).then(()=>{
            setDeleting(false);
            props.onHide(null);
          });
          if( props.race.telemetry) {
            const refs = db.collection(COLLECTION_ID).doc(props.race.telemetry)
            refs.set(newdata,{ merge: true });
          }
        }
      );
  }

    const hideModal = () => {
        console.log('hide');
        setFile('');
        props.onHide(false);
    }
  
    return (        
        <Modal size='sm' onHide={hideModal} centered {...props} >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Upload Briefing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFileLg" className="mb-3">
                <Form.Control type="file" onChange={(e)=>{setFile(e.target.files[0])}} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" 
                disabled={isLoading}
                onClick={onFormSubmit}
            >
            {isLoading ? 'Uploading' : 'Upload'}
            </Button>
            {props.race && props.race.briefing &&
              <Button variant="danger" 
                  disabled={isDeleting}
                  onClick={deleteBriefing}
              >
              {isDeleting ? 'Deleting' : 'Delete'}
              </Button>
            }
          </Modal.Footer>
          </Form>
        </Modal>
    );
  }