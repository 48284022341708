exports.supportedFonts = {
    "Arial": {"path":""},
    "Arial-Bold" : {"path":""},
    "Verdana": {"path":""},
    "Verdana-Bold": {"path":""},
    "Abel Pro" : {"path":"fonts/Abel-Pro.otf"},
    "Abel Pro-Bold" : {"path":"fonts/Abel-Pro-Bold.otf"},
    "A-OTF Shin Go Pro" : {"path":"fonts/AOTFShinGoProRegular.otf"},
    "Formula1" : {"path":"fonts/Formula1-Regular.ttf"},
    "Formula1-Bold" : {"path":"fonts/Formula1-Bold.ttf"},
    "Mortend-Bold" : {"path":"fonts/Mortend-Bold.otf","offsetY": 1}
}

exports.topBannertemplates = {
    "Default": {}
}