const getOptionsPerc = (title) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    callback: function(tick) {
                        return tick  +  ' m';
                    }
                }
            },
            y: {
                ticks: {
                    callback: function(tick) {
                        return (tick*100).toFixed(0).toString() + '%';
                    }
                }
            }
        },
        plugins: {
            title: {
              display: true,
              text: title,
            },
            tooltip: {
              callbacks: {
                label: function(tooltipItem, data) {
                    return (tooltipItem.raw.y*100).toFixed(0).toString() + '% ' + tooltipItem.raw.x.toFixed(2) + 'm)';
                },
              }
            },
            crosshair: {
                sync: {
                  enabled: true,            // enable trace line syncing with other charts
                  group: 2,                 // chart group
                  suppressTooltips: false   // suppress tooltips when showing a synced tracer
                }
            }
          },
    }
}

const getOptionsPlain = (title) => {
    return {
        responsive: true,
        scales: {
            x: {
                ticks: {
                    callback: function(tick) {
                        return tick  +  ' m';
                    }
                }
            },
        },
        plugins: {
            title: {
              display: true,
              text: title
            },
            tooltip: {
              callbacks: {
                label: function(tooltipItem, data) {
                    return tooltipItem.raw.y.toString();// + ' at ' + tooltipItem.raw.x.toFixed(2) + 'm)';
                },
              }
            },
            crosshair: {
                sync: {
                  enabled: true,            // enable trace line syncing with other charts
                  group: 2,                 // chart group
                  suppressTooltips: false   // suppress tooltips when showing a synced tracer
                }
            }
          },
    }
}



export const chartsOptions = {
    "speed":  {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    callback: function(tick) {
                        return tick  +  ' km/h';
                    }
                }
            },
            x: {
                ticks: {
                    callback: function(tick) {
                        return tick  +  ' m';
                    }
                }
            }
        },
        elements: {
            line: {
               tension: 0,
               stepped: false,
               borderWidth: 1.5
            },
            point: {
                radius: 0
            }
        },
        plugins: {
            title: {
              display: true,
              text: 'Speed km/h',
            },
            tooltip: {
                mode: 'interpolate',
                intersect: false,
                callbacks: {
                    label: function(tooltipItem, data) {
                        return tooltipItem.raw.y + 'km/h';// + tooltipItem.raw.x.toFixed(2) + 'm)';
                    }
                }
            },
            crosshair: {
                line: {
                  color: '#F66',  // crosshair line color
                  width: 1        // crosshair line width
                },
                sync: {
                  enabled: true,            // enable trace line syncing with other charts
                  group: 2,                 // chart group
                  suppressTooltips: false   // suppress tooltips when showing a synced tracer
                }
            }
        },
     },
    "steer": getOptionsPerc("Steering"),
    "brake": getOptionsPerc("Brake %"),
    "throttle": getOptionsPerc("Throttle %"),
    "engineRPM": getOptionsPlain("RPM"),
    "gear": getOptionsPlain("GEAR"),
    "drs": getOptionsPlain("DRS"),
    "engineTemperature": getOptionsPlain("Engine °C"),
    "tyresInnerTemperature": getOptionsPlain("Tyres °C"),
    "delta":  {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    callback: function(tick) {
                        return tick;
                    }
                }
            }
        },
        elements: {
            line: {
               tension: 0,
               stepped: false,
               borderWidth: 1
            }
        },
        plugins: {
            title: {
              display: true,
              text: 'Delta',
            },
            tooltip: {
                mode: 'interpolate',
                intersect: false,
                callbacks: {
                    label: function(tooltipItem, data) {
                        return tooltipItem.raw.y;// + tooltipItem.raw.x.toFixed(2) + 'm)';
                    }
                }
            },
            crosshair: {
                line: {
                  color: '#F66',  // crosshair line color
                  width: 1        // crosshair line width
                },
                sync: {
                  enabled: true,            // enable trace line syncing with other charts
                  group: 2,                 // chart group
                  suppressTooltips: false   // suppress tooltips when showing a synced tracer
                }
            }
        },
     }
 } 