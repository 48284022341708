import React from 'react';
import {teamFormat} from '../helpers/format';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import EditDriverModal from '../components/EditDriverModal';


const Partecipants = props => {

    const [driverForEditing, setDriverForEditing] = React.useState(false);

    return(
        <>
<Table striped hover size="sm">
  <tbody>
{ props.partecipants && props.partecipants.map( (item, i) => (
    <tr key={`partecipant-${i}`}>
        <td key={`driver-${i}-num`}>{item.number || item.raceNumber}</td>
        <td key={`driver-${i}-lbl`}>
          {props.canEdit ?
            <Button key={`driver-${i}-btn-edit`} size="sm" variant="link" onClick={() => setDriverForEditing(item)} >{item.label || item.name}</Button>
          : 
          <>{item.label || item.name}</>
          }
          </td>
        <td key={`driver-${i}-team`}>
          {teamFormat(item.teamId)}
        </td>
    </tr>
    ))
}
  </tbody>
</Table>
<EditDriverModal 
    show={driverForEditing}
    driver={driverForEditing}
    onHide={( changed) => {setDriverForEditing(false); props.onEditSave(changed)}}
/>
     </>);
  }
  
  export default Partecipants;