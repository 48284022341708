import React, { Component } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { TEAM_COLORS_HEX } from 'racemapper_shared/enums';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
  );
  
class QperformanceChart extends Component {

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        const normalized = props.result.filter( r => r.position > 0 && r.resultStatus !== 6).sort((a, b) => a.position - b.position);
        const labels = normalized.map( item => props.partecipants[item.driver] ? props.partecipants[item.driver].label ?? props.partecipants[item.driver].name :  "");
        this.fastestLap = normalized[0].bestLapTime;

        const rawdata = normalized.map( item => {
                    const v = (item.bestLapTime / this.fastestLap) - 1;
                    return v >= 0 && v <= 0.07 ?  v : 0;
                });

        const bgcolot = normalized.map( item => props.partecipants[item.driver] ? TEAM_COLORS_HEX[props.partecipants[item.driver].teamId] :  "");
        this.data = {
                    labels,
                    datasets: [
                      {
                        label: 'Fastest Lap',
                        data: rawdata,
                        backgroundColor: bgcolot,
                      }
                    ],
                  };
    }

    options = {
        indexAxis: 'y',
        scales: {
            x: {
                ticks: {
                    format: {
                        style: 'percent'
                    },
                    callback: function(tick) {
                        return (tick*100).toFixed(2).toString() + '%';
                    }
                }
            },
            y: {
                ticks: {
                    autoSkip: false
                }
            },
        },
        elements: {
          bar: {
            borderWidth: 1,
            borderColor: "black",
          },
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Q Performance',
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem, data) {
                return "+"+(tooltipItem.raw*100).toFixed(2).toString() + '%';
              }
            }
          },
          crosshair: {
            zoom: {
              enabled: false
            },
            line: {
              width: 0
            }
          }
        },
      };
            
    render() {
        return <Bar options={this.options} data={this.data} />;
    }
  }
  
  export default QperformanceChart;