import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {db} from '../services/firebase';
import {ORG_COLLECTION} from "racemapper_shared/data";

export default function LeagueInfoModal ( props) {  
    const [isLoading, setLoading] = React.useState(false);

    const socialKeys = ["twitch","youtube","twitter","instagram","tiktok","facebook"];
    const websites = ["profilepic","rules","website","shop","discord"];

    const onFormSubmit = (e) => {
      e.preventDefault();

      setLoading(true);
      let data = {
        'label': e.target.label.value.trim(),
        'payoff': e.target.payoff.value.trim()
      }
      websites.forEach(k => {
        let v = e.target[k].value.trim();
        const prefix = "https://"
        if( v.length > 0 && !v.startsWith(prefix)) {
          v = prefix + v;
        }
        data[k] = v;
      });
      socialKeys.forEach( k => {
        data[k] = e.target[k].value.trim();
      });
      let k = 'tiktok';
      if( data[k].length >0 && !data[k].startsWith('@')){
        data[k] = '@' + data[k];
      }
      setLoading(false);
      const ref = db.collection(ORG_COLLECTION).doc(props.league.id);
      ref.set( data, { merge: true }).then( res =>{
          setLoading(false);
          props.onHide(true);
      }).catch( e => console.log(e));
      return false;
    }

    const hideModal = () => {
        props.onHide(false);
    }
  
    return (        
        <Modal size='lg' onHide={hideModal} centered {...props} >
        <Form onSubmit={onFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>League Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel
                controlId="label"
                label="Label"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="My fantastic League"
                  defaultValue={props.league ? props.league.label : ""}
                  autoFocus
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="payoff"
                label="Payoff"
                className="mb-3"
              >
                <Form.Control
                    type="text"
                    placeholder="Best league ever"
                    defaultValue={props.league ? props.league.payoff : ""}
                  />
              </FloatingLabel>
              <FloatingLabel
                controlId="profilepic"
                label="Logo, best if 512x512 jpg/png"
                className="mb-3"
              >
                <Form.Control
                    type="text"
                    placeholder="Square 512x512 jpg/png"
                    defaultValue={props.league ? props.league.profilepic: ""}
                  />
              </FloatingLabel>
              <FloatingLabel
                controlId="rules"
                label="Link to the league rules"
                className="mb-3"
              >
                <Form.Control
                    type="text"
                    placeholder="Link to the rules"
                    defaultValue={props.league ? props.league.rules : ""}
                  />
              </FloatingLabel>
              <FloatingLabel
                controlId="website"
                label="Web (https://...)"
                className="mb-3"
              >
                <Form.Control
                    type="url"
                    placeholder="https://"
                    defaultValue={props.league ? props.league.website : ""}
                  />
              </FloatingLabel>

              <FloatingLabel
                controlId="discord"
                label="Discord invite link"
                className="mb-3"
              >
                <Form.Control
                    type="url"
                    placeholder="https://"
                    defaultValue={props.league ? props.league.discord : ""}
                  />
              </FloatingLabel>

              {socialKeys.map( (item) => {
                return <FloatingLabel
                  controlId={item}
                  as={Col}
                  label={`${item.charAt(0).toUpperCase() + item.slice(1)} username`}
                  className="mb-3"
                  key={item}
                >
                <Form.Control
                      type="text"
                      defaultValue={ props.league ? props.league[item] : ""}
                    />
                </FloatingLabel>
              })}

              <FloatingLabel
                controlId="shop"
                label="Shop (https://...)"
                className="mb-3"
              >
                <Form.Control
                    type="url"
                    placeholder="https://"
                    defaultValue={props.league ? props.league.shop : ""}
                  />
              </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => hideModal}>Cancel</Button>
            <Button variant="primary" 
                disabled={isLoading}
                type="submit"
            >
            {isLoading ? 'Saving…' : 'Save'}
            </Button>
          </Modal.Footer>
          </Form>
        </Modal>
    );
  }
