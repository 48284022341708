import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { db } from '../services/firebase';
import { COLLECTION_ID, ORG_COLLECTION ,CHAMP_COLLECTION } from "racemapper_shared/data";

export default function ChampionshipNewModal ( props) {  
    const [name, setName] = React.useState("");
    const [isLoading, setLoading] = React.useState(false);

    const onLabelSave = async () => {
        const label = name.trim();
        if(label.length) {
            setLoading(true);
            if( props.championship) { // edit
              const ref = db.collection(ORG_COLLECTION).doc(props.leagueid).collection(CHAMP_COLLECTION).doc( props.championship.id);
              ref.set( {label}, { merge: true }).then( res =>{
                  setLoading(false);
                  props.onHide( props.championship.id, label);
              }).catch( e => console.log(e));
            } else { // new insert
              const ref = await db.collection(ORG_COLLECTION).doc(props.leagueid).collection(CHAMP_COLLECTION).add({label, game: COLLECTION_ID, private:false, partecipants:[]});
              setLoading(false);
              props.onHide(ref.id, label);
            }
        }
    }

    const hideModal = () => {
        props.onHide();
    }
    return (        
        <Modal onHide={hideModal} size="sm" centered {...props} >
          <Modal.Header closeButton>
            <Modal.Title>Championship Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="edit.Label">
                <Form.Control
                  type="text"
                  placeholder={"ex: PC F1 S30"}
                  defaultValue={props.championship ? props.championship.label : ""}
                  onChange={event => setName( event.target.value)}
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" 
                disabled={isLoading}
                onClick={!isLoading ? onLabelSave : null}
            >
            {isLoading ? 'Saving…' : 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
