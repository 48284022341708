import React, {useEffect} from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import {driverFormat} from '../helpers/format';

//import { getDBLaps } from "racemapper_shared/data";

const CompareDrivers = props => {

    //const [session, setSession] = React.useState([]);
  //  const [driver1, setDriver1] = React.useState([]);
  //  const [driver2, setDriver2] = React.useState([]);

  /*  const sessionRaceID = props.match.params.sessionID;
    const raceID = props.match.params.raceID;
    const driver1 = props.match.params.driver1;
    const driver2 = props.match.params.driver2;
*/
    useEffect(() => {
          /*  let datastruct = [];
            const laps = getDBLaps( db,sessionRaceID, raceID, [driver1, driver2])
            const race = getLaps( sessionRaceID, raceID, [driver1, driver2])
/*
            laps.docs.map( doc => {
                const row = {id:doc.id, ...doc.data()};
                const kd = row.vehicleIdx === driver1 ? 0 : 1;
                datastruct[row.lap][kd] = row;
            }); */
    }, []);
    return (

<ButtonToolbar>
  <ButtonGroup  size="sm">
    <DropdownButton as={ButtonGroup} title="Compare" id="bg-nested-dropdown">
      {props.partecipants && props.partecipants.filter( item => item.driverId !== 0 && item !== props.laps.partecipant).sort( (a,b) => a.teamId < b.teamId ).map( (driver, key) => 
         <Dropdown.Item key={`cmp-${driver.driverId}`} eventKey={key}>{driverFormat(driver)}</Dropdown.Item>
      )}     
    </DropdownButton>
    vs
    <DropdownButton as={ButtonGroup} title="Compare" id="bg-nested-dropdown">
      {props.partecipants && props.partecipants.filter( item => item.driverId !== 0 && item !== props.laps.partecipant).sort( (a,b) => a.teamId < b.teamId ).map( (driver, key) => 
         <Dropdown.Item key={`cmp2-${driver.driverId}`} eventKey={key}>{driverFormat(driver)}</Dropdown.Item>
      )}     
    </DropdownButton>
  </ButtonGroup>
</ButtonToolbar>


    );
  }

  export default CompareDrivers;
