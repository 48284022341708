import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import ModalUploadBriefingFile from "./ModalUploadBriefingFile";
import ModalRaceResults from "./RaceDetail/ModalRaceResults";

import { dateForEvent , raceFormat } from 'racemapper_shared/formats';
import { trackFormat , driverFormat } from '../helpers/format';
import { isLeagueAdmin } from "../helpers/auth";

export default function RaceDetail ( props) {  

    const [showBriefingUpload, setShowBriefingUpload] = React.useState(false);
    const [showRaceResultsModal, setShowRaceResultsModal] = React.useState(false);

    const handleClose = () => props.onHide();

    const onUploadHide = (furl) => {
        if( furl || furl === null) {
            props.race.briefing = furl;
        }
        setShowBriefingUpload(false);
    }

    const lblPoints = (item) => {
        let lbl = '';
        if( item.points > 0) lbl = String(item.points);
        if( item.poleposition) {
            lbl = <u>{lbl}</u>;
        }
        if( item.fastestlap) {
            lbl = <b>{lbl}</b>;
        }
        return lbl;
      }

    return (        
        <Offcanvas onHide={handleClose} {...props} >
        {props.race &&
        <>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='font-face-f1'>{trackFormat( props.race.trackId, false)}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item><strong>When:</strong> {dateForEvent(props.race.date.toDate())}</ListGroup.Item>
                    <ListGroup.Item><strong>League:</strong> {props.race.organisationLabel}</ListGroup.Item>
                    <ListGroup.Item><strong>Championship:</strong> {props.race.championshipLabel}</ListGroup.Item>
                    <ListGroup.Item><strong>Format:</strong> {raceFormat(props.race.format)}</ListGroup.Item>
                    {props.race.briefing &&
                    <ListGroup.Item><strong>Briefing:</strong> <Button size="sm" rel="noreferrer" target='_blank'  href={props.race.briefing} variant='outline-dark' download>Download</Button></ListGroup.Item>
                    }
                    {isLeagueAdmin( props.league) &&
                    <ListGroup.Item><strong>ID:</strong> {props.race.id}</ListGroup.Item>
                    }
                </ListGroup>

                {props.race.results &&
                <>
                    <Table striped hover size="sm" className="racetab">
                    <thead>
                        <tr key={`lp-driver-header`}>
                        <th className="positionCell"></th>
                        <th>DRIVER</th>
                        <th>POINTS</th>
                        <th className="positionCell">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    { props.race.results.sort((a, b) => a.position - b.position).map( item => (
                        <tr key={`driver-${item.position}`}>
                            <td key={`driver-${item.position}-pos`}>{item.position}</td>
                            <td key={`driver-${item.position}-name`}>{driverFormat(item, false)}</td>
                            <td key={`driver-${item.position}-points`} className="centerTxt">{lblPoints(item)}</td>
                        </tr>
                        ))
                    }
                    </tbody>
                    </Table>
                    <span><u>Pole position</u> | <b>fastestlap</b></span>
                    <br />
                </>
                }
            {isLeagueAdmin( props.league) &&
            <>
            <br />
            <Card style={{ width: '18rem' }}>
                <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">Race Admin</Card.Subtitle>
                <Card.Text></Card.Text>
                <Button size="sm" variant="info" onClick={() => setShowBriefingUpload(true) }>Manage Briefing</Button><br /><br />
                <Button size="sm" variant="info" onClick={() => setShowRaceResultsModal(true) }>Add Race Results</Button>
                </Card.Body>
            </Card>
            <ModalUploadBriefingFile race={props.race} show={showBriefingUpload} onHide={( url)=>{ onUploadHide(url); }} />
            <ModalRaceResults race={props.race} calendarid={props.race.id} championshipid={props.race.championshipId} points={props.league.points || {}} show={showRaceResultsModal} onHide={(saved)=>{setShowRaceResultsModal(false); if(saved) handleClose();}} />
            </>
            }
        </Offcanvas.Body>
        </>
        }
      </Offcanvas>
    );
  }
