import {PENALITY_TYPE,INFRINGMENT_TYPE,PENALITY_ICON} from 'racemapper_shared/enums';
import {lapTimeFormat,driverFormat} from '../helpers/format';

export function formatLogs(logs, partecipants) {
    if( ! partecipants) {
        console.log( "no partecipants");
    }

    return logs.map((item) => {
        let icon = "";
        let driver = "";
        let opponent = "";

        if( typeof item.data.vehicleIdx !== "undefined" && partecipants) {
            driver = driverFormat( partecipants[item.data.vehicleIdx]);
        } 

        if( item.data.otherVehicleIdx !== 255 && partecipants) {
            opponent = driverFormat( partecipants[item.data.otherVehicleIdx]);
        } 

        if( item.type === "FTLP") {
            icon = "⚡️";
            item.label = "Fastest Lap: "+ lapTimeFormat(item.data.lapTime);
        } else if( item.type === "SEND") {
            icon = "🏁";
            item.label = "Session Ended";
        } else if (  item.type === "PENA") {
            item.label =  PENALITY_TYPE[item.data.penaltyType];
            icon = PENALITY_ICON[item.data.penaltyType];
            if( item.data.placesGained !== 255 && item.data.placesGained !== 0) {
                item.label += " +" + item.data.placesGained + " pos.";
            }
            if( item.data.time !== 255 && item.data.time !== 0) {
                item.label += " +" + item.data.time + " s.";
            }
            if( item.data.infringementType) {
                item.label += ", " + INFRINGMENT_TYPE[item.data.infringementType];
            }
        } else if ( item.type === "DRSE") {
            icon = "🟢";
            item.label = "DRS ENABLED";
        } else if ( item.type === "DRSD") {
            icon = "✋";
            item.label = "DRS DISABLED";
        } else if ( item.type === "SFTC") {
            if( item.data === 0) {
                icon = "🟩";
                item.label = "RESUME RACING";
            } else {
                icon = "🟨";
                item.label = item.data === 2 ? "VIRTUAL SC" :"SAFETY CAR";
            }
        } else if ( item.type === "SSTA") {
            icon = "🚥";
            item.label = "Session Starts";
        } else if ( item.type === "RCWN") {
            icon = "🏆";
            item.label = "Race Winner";
        } else if ( item.type === "CHQF") {
            icon = "🏁";
            item.label = "Chequered flag";
        } else if ( item.type === "RTMT") {
            icon = "⛑";
            item.label = "Retires";
        } else if ( item.type === "_PIT") {
            icon = "➡️";
            item.label = "Pits";
            if ( item.data.pitStopTime) {
                item.label = "PIT Stop " + lapTimeFormat(item.data.pitStopTime) + " PIT Lane " + lapTimeFormat(item.data.pitLaneTime);
            }
        } else {
            item.label = item.type + " ";
        }

        if( item.data.lapNum) {
            item.label += " on lap " + item.data.lapNum;
        }

        if( driver) {
            item.label =  icon ? ([icon, " ", <b key={`lbl-${item.id}`}>{driver}</b>, " ",item.label]) : ([<b key={`lbl-${item.id}`}>{driver}</b>," ",item.label]);
            if( opponent) {
                item.label = [item.label , " with " ,<b key={`lblo-${item.id}`}>{opponent}</b>];
            }
        }  else if (icon) {
            item.label = ([icon, " ",item.label]);
        }
    
        return item;
    });
}