import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBGGaWUE3n_MZ-g97bY5v7gNS1voE6_rAc",
    authDomain: "helpmybusiness-752eb.firebaseapp.com",
    databaseURL: "https://helpmybusiness-752eb.firebaseio.com",
    projectId: "helpmybusiness-752eb",
    storageBucket: "race-telemetry",
    messagingSenderId: "853264167274",
    appId: "1:853264167274:web:f519eb221185b7666bf513",
    measurementId: "G-1EJG2H8CFT"
  };

/*fetch('/__/firebase/init.json').then(async response => {
    firebase.initializeApp(await response.json());
  });
*/
firebase.initializeApp(firebaseConfig);

firebase.auth().useDeviceLanguage();

export const auth = firebase.auth;

export const db = firebase.firestore();

export const storage = firebase.storage();

//db.useEmulator("127.0.0.1", 8080);
