import React, {useEffect} from 'react';

import { db } from '../services/firebase';
import { COLLECTION_ID } from "racemapper_shared/data";
import WeatherForecast from '../components/WeatherForecast';

const Weather = props => {

    const [race, setRace] = React.useState([]);

    const sessionRaceID = props.match.params.sessionID;
    const raceID = props.match.params.raceID;

    useEffect(() => {
        const getRaceData = async() => {
            try {
                let raceRef = db.collection(COLLECTION_ID).doc(sessionRaceID).collection("sessions").doc(raceID);
                raceRef.onSnapshot(docSnapshot => {
                    let data = docSnapshot.data();
    
                    setRace( data);
                    
                    }, err => {
                    console.log(`Encountered error: ${err}`);
                });   
    
            } catch( error) {
                console.log(error);
            }
        }
        getRaceData()
    }, [sessionRaceID,raceID]);


    return (
        <>
        { race && 
            <WeatherForecast weatherForecastSamples={race.weatherForecastSamples} forecastAccuracy={race.forecastAccuracy} linktoFullscreen={false} />
        }
        </>
    );
};
export default Weather;

