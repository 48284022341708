import React, {useEffect} from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert'

import { db } from "../services/firebase";
import { TRACK , TRACK_FLAG , TRACK_COUNTRY } from 'racemapper_shared/enums';
import { addCalendarData , editCalendarData , getTelemetrySessions } from "racemapper_shared/data";
import { raceFormat } from "racemapper_shared/formats";
import { zeroPad } from '../helpers/format';

export default function CalendarModal( props) {  
    const [showError, setShowError] = React.useState(false);
    const [telemetryArr, setTelemetryArr] = React.useState([]);

    const onFormSave = (e) => {
        e.preventDefault();
 
        const date = e.target.date.valueAsDate;
        const time = e.target.time.value;
        const trackId = e.target.track.value;
        let telemetry = "";
        let format = 0;
        if( e.target.telemetry) {
            telemetry = e.target.telemetry.value;
        }
        if( e.target.format) {
            format = parseInt(e.target.format.value);
        }
        if( ! date) {
            return;
        }
        if( ! time) {
            return;
        }
        const hhmm = time.split(":");
        date.setHours(hhmm[0], hhmm[1]);

        if( ! date) {
            return;
        }

        if( props.editRace) {
            let editRace = {
                id: props.editRace.id,
                date,
                trackId,
                telemetry,
                format,
                organisationId: props.league.id,
                organisationLabel: props.league.label,
                championshipId: props.championship.id,
                championshipLabel: props.championship.label,
                briefing: props.editRace.briefing ?? ''
            }
            console.log(props.editRace);
            const oldAssociation = props.editRace.telemetry ?? undefined;
            editCalendarData( db, editRace, oldAssociation)
            .then( res => {
                setShowError(false); 
                props.onHide();
            })
            .catch( e => {
                console.log("error:" + e);
                setShowError(true)
            });
        } else {
            addCalendarData( db, date, trackId, props.league.id,props.league.label, props.championship.id, props.championship.label, telemetry, format)
            .then( res => {
                setShowError(false);
                props.onHide()
            }).catch(e => {
                setShowError(true);
                console.log("error:" + e);
            });
        }
    }

    const getTimeZone = () => {
        const date = new Date();
        const dateAsString = date.toString();
        const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
        return timezone;
    }
    
    const defaultDate = (def) => {
        let d = def;
        if( props.editRace) {
            d = props.editRace.date.toDate()
        }
        if( !d) {
            return null;
        }
        const n = d.getFullYear() + "-" + zeroPad(d.getMonth()+1) + "-" + zeroPad(d.getDate());
        return n;
    }

    const defaultTime = (def) => {
        let d = def;
        if( props.editRace) {
            d = props.editRace.date.toDate()
        }
        if( ! d) {
            return null;
        }
        return zeroPad(d.getHours()) + ":" +zeroPad( d.getMinutes()) + ":00";
    }

    useEffect(() => {
        if( props.show) {
            getTelemetrySessions( db, props.league.id, props.championship.id).then( res => {
                setTelemetryArr( res.filter( (item) =>  !item.calendarId || ( props.editRace && item.calendarId === props.editRace.id)));
            });
        }
    },[ props.show, props.league.id, props.championship.id, props.editRace]);

    return (  
        <Modal show={props.show} onHide={props.onHide} size="lg" centered>
        <Alert variant="danger" show={showError} onClose={() => setShowError(false)} dismissible>
            <Alert.Heading>Ops, an error occurred</Alert.Heading>
            <p>
                Please retry later or contact us
            </p>
        </Alert>
          <Modal.Header closeButton>
            <Modal.Title>{props.editRace ? "Edit": "Add"} Race</Modal.Title>
          </Modal.Header>
          <Form onSubmit={onFormSave}>
          <Modal.Body>
                <Row className="g-2">
                    <Col md>
                        <FloatingLabel controlId="date" label="Day" className="mb-3">
                            <Form.Control
                                type="date"
                                size="sm"
                                defaultValue={defaultDate(props.calendarDate)}
                                required={true}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel controlId="time" label="Hour" className="mb-3">
                            <Form.Control
                                type="time"
                                size="sm"
                                defaultValue={defaultTime(props.calendarDate)}
                                required={true}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col md>
                        <FloatingLabel controlId="track" label="Track" className="mb-3">
                            <Form.Select aria-label="Track" name="track" defaultValue={props.editRace ? props.editRace.trackId:0} >
                                { Object.values(TRACK).map( (item,i) => {
                                    if(!item.includes("Short")) {
                                        return <option key={`track:${i}`} value={i}>{TRACK_FLAG[i]} {item} - {TRACK_COUNTRY[i]}</option>
                                    }
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <FloatingLabel controlId="format" label="Format" className="mb-3">
                            <Form.Select aria-label="Format" name="format" defaultValue={props.editRace ? props.editRace.format:0} >
                                {[0,7,2,6,3,1,4,5].map( (i) => {
                                    return <option key={`format:${i}`} value={i}>{raceFormat(i)}</option>
                                })
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    {telemetryArr.length > 0 &&
                        <Col md>
                            <FloatingLabel controlId="telemetry" label="Telemetry" className="mb-3">
                                <Form.Select aria-label="Telemetry" name="telemetry" defaultValue={props.editRace ? props.editRace.telemetry : ""} >
                                    <option value=""></option>
                                    { telemetryArr.map( item => {
                                        return <option key={`telemetry:${item.id}`} selected={props.editRace && item.id === props.editRace.telemetry } value={item.id}>{item.label}</option>
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    }
                    <span>Time is in your current TimeZone <b>{getTimeZone()}</b></span>
                </Row>
          </Modal.Body>
          <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onHide()}>Cancel</Button>
                <Button variant="primary" type="submit">Save</Button>
          </Modal.Footer>
          </Form>
        </Modal>
    );
  }
