import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { TEAM } from 'racemapper_shared/enums';


export default function EditDriverModal( props) {  
    const [driverName, setDriverName] = React.useState([]);

    const onLabelSave = () => {
        if(driverName.length) {
          props.driver.label = driverName.trim();
          //console.log(props.driver);
          closeAction( props.driver);
        }
    }

    const closeAction = (changed) => {
      props.onHide( changed);
    }
    return (
        <Modal show={props.show} onHide={() => closeAction(false)} size="sm" centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Driver</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="editDriver.Label">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ex: Ayrton Senna"
                  defaultValue={props.driver.label ?? ""}
                  onChange={event => setDriverName( event.target.value)}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="editDriver.number">
                <Form.Label>Number</Form.Label>
                <Form.Control
                  type="text"
                  value={props.driver.raceNumber}
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="editDriver.team">
                <Form.Label>Team</Form.Label>
                <Form.Control
                  type="text"
                  value={TEAM[props.driver.teamId]}
                  readOnly
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onLabelSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
