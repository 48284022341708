import React, { Component } from 'react';
import {lapTimeFormat, driverFormat, teamFormat, timeOffset, sectorTimeFormat} from '../helpers/format';
import {RESULT_STATUS,TYRE_VISUAL_IMG,TYRE_VISUAL} from 'racemapper_shared/enums';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';


function lblPenalities(item) {
  return item.numPenalties === 0 ? "" : "+" + (item.numPenalties * 5) + " pos.";
}

function lblResult(resultStatus) {
  return( resultStatus === 3 || resultStatus === 7) ? "" : RESULT_STATUS[resultStatus];
}

function classForSector(sector, overallBestSector) {
  return sector === overallBestSector ? "fastest centerTxt d-none d-lg-table-cell" : "centerTxt d-none d-lg-table-cell";
}

function classForTime(position) {
  return position === 1 ? "lp-bestlap fastest" : "lp-bestlap";
}

class RaceQResult extends Component {
    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
      // we can't always rely on the fastest lap saved, we pick the first one
      this.fastestLap = this.props.result.filter( r => r.resultStatus > 1).sort((a, b) => a.position - b.position)[0].bestLapTime;
    }


    render() {
      return(
        <>
<Table striped hover size="sm" className="racetab">
  <thead>
    <tr key={`lp-driver-header`}>
      <th className="positionCell"></th>
      <th>DRIVER</th>
      <th className="d-none d-lg-table-cell">TEAM</th>
      <th>BEST</th>
      <th>GAP</th>
      <th className="d-none d-lg-table-cell">S1</th>
      <th className="d-none d-lg-table-cell">S2</th>
      <th className="d-none d-lg-table-cell">S3</th>
      <th>TYRES</th>
      <th className="positionCell">&nbsp;</th>
    </tr>
  </thead>
  <tbody>

{ this.props.result && this.props.result.map((item,driver) => { 
    item.driver = driver;
    return item;
  }).filter( r => r.position > 0 && r.resultStatus !== 6 ).sort((a, b) => a.position - b.position).map( (item) => (
    <tr key={`driver-${item.driver}`}>
      <td key={`driver-${item.driver}-pos`}>{item.position} {lblResult(item.resultStatus)}</td>
      <td key={`driver-${item.driver}-name`}><Button key={`driver-${item.driver}-namebtn`} variant="link" onClick={this.props.onPress(item.driver)}>{driverFormat(this.props.partecipants[item.driver])}</Button> <Badge bg="danger">{lblPenalities(item)}</Badge></td>
      <td key={`driver-${item.driver}-team`} className="d-none d-lg-table-cell">{teamFormat(this.props.partecipants[item.driver].teamId)}</td>
      <td key={`driver-${item.driver}-lt`} className={classForTime(item.position)}>{lapTimeFormat(item.bestLapTime)}</td>
      <td key={`driver-${item.driver}-diff`} className="lp-delta">{timeOffset(item.bestLapTime, this.fastestLap , item.position)}</td>
      <td key={`driver-${item.driver}-s1`} className={classForSector(this.props.lapData[item.driver].bestLapSector1TimeInMS, this.props.fastestLap.s1)}>{sectorTimeFormat(this.props.lapData[item.driver].bestLapSector1TimeInMS)}</td>
      <td key={`driver-${item.driver}-s2`} className={classForSector(this.props.lapData[item.driver].bestLapSector2TimeInMS, this.props.fastestLap.s2)}>{sectorTimeFormat(this.props.lapData[item.driver].bestLapSector2TimeInMS)}</td>
      <td key={`driver-${item.driver}-s3`} className={classForSector(this.props.lapData[item.driver].bestLapSector3TimeInMS, this.props.fastestLap.s3)}>{sectorTimeFormat(this.props.lapData[item.driver].bestLapSector3TimeInMS)}</td>
      <td key={`driver-${item.driver}-tyres`}>{item.tyreStintsVisual.filter(t => t !== 0).map( (t,i) => ( <img className="tyre" key={`${item.position}-${i}`} src={`${process.env.PUBLIC_URL}/static/tyres/${TYRE_VISUAL_IMG[t]}.png`} alt={TYRE_VISUAL[t]} width="20" height="20" />))}</td>
      <td>
        { this.props.blaps && this.props.blaps[item.driver] &&  
        <Link key={`driver-${item.driver}-stats`} to={`${this.props.raceID}/lap/${this.props.blaps[item.driver]}`} variant="light" style={{width: 20, height:20, padding:0}} ><img alt="fs" src="/static/icons/graph-up.svg" style={{width: 15, margin:0, padding:0}} /></Link>
        }
      </td>
    </tr>
    ))
}

  </tbody>
</Table>
        </>);
    }
  }
  
  export default RaceQResult;