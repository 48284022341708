import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { db } from "../services/firebase";
import { pointsForRace , setResultForRace , calculateClassificationFromCalendar, driverIdFromLabel , getCalendarData } from "racemapper_shared/data";
import { driverFormat } from "../helpers/format";

import PointSystemComponent from './PointSystemComponent';
import TeamSelect from './TeamSelect';
const fetch = require('node-fetch');

export default function ChampionshipStandingModal ( props) {  
    const [isLoading, setLoading] = React.useState(false);
    const [driverStanding, setDriverStanding] = React.useState([]);
    const [notFoundDrivers, setNotFoundDrivers] = React.useState([]);
    const [standing, setStanding] = React.useState([]);

    const onFormSave = async (e) => {
        e.preventDefault();
        if( isLoading) {
            return;
        }
        setLoading( true);
        let data = [];
        const flap = props.race.classificationData.filter( r => r.bestLapTime > 0).reduce( (a, b) => Math.min(a, b.bestLapTime), props.race.classificationData[0].bestLapTime);

        props.race.classificationData.filter( r => r.resultStatus > 1).forEach( item => {
            const action = e.target['action-'+item.driver].value;
            if(action !== "1") {
                const wc = action === "3"
                const points =  getPoints(item, props.sprint);
                const lastname = driverFormat(props.race.partecipants[item.driver], true);
                let oldname = "";
                if(  e.target['driver-'+item.driver]) {
                    oldname = e.target['driver-'+item.driver].value;
                    if( oldname === lastname) {
                        oldname = "";
                    }
                }
                const wildcard4constructor = props.points && props.points.wildcard4constructor;
                const drv = {
                    position: item.position,
                    gridPosition: item.gridPosition,
                    points: points,
                    resultStatus: /*action === "2" ?  5 :*/ item.resultStatus, // BAN to SQ
                    teamId: parseInt(e.target['team-'+item.driver].value),
                    reserve: wc,
                    label: lastname,
                    oldname,
                    fastestlap: flap === item.bestLapTime,
                    poleposition: item.gridPosition === 1,
                    teamPoints: (!wc || wildcard4constructor === true) ? points : 0
                };
                data.push(drv);
            }
        });
        const provisional = e.target['provisional'].checked;
        setResultForRace( db, data, props.calendarid, provisional)
        .then(()=>{
            setLoading( false);
            props.onHide( true);
            // regen banner
            const settings = { method: "Get" };
            fetch("/workers/standingsbanner?champ=" + props.championshipid , settings);
        }).catch(e => {
            window.alert(e);
            setLoading( false);
        });
        
    }

    const onWcSelect = (e, item) => {
        let v = "0";
        const wc = "3";
        const wildcard4constructor = props.points && props.points.wildcard4constructor;

        if (e.target.value === "0" || ( e.target.value === wc && wildcard4constructor === true)) {
            v = getPoints(item, props.sprint);
        } 
        document.getElementById("construtor-"+item.driver).innerHTML = v;
    }
    const hideModal = () => {
        props.onHide( false);
    }

    const getPoints = (item, sprint) => {
        let pstrint = 0;
        let sprintdriver = {};
        if( sprint) {
            let matchNames = false;
            if (sprint.weekendLinkIdentifier && props.race.weekendLinkIdentifier) {
                matchNames = sprint.weekendLinkIdentifier !== props.race.weekendLinkIdentifier; // if the session is restarted, the driver id is not the same
            }
            if (matchNames) {
                const par = props.race.partecipants[item.driver];
                Object.entries(sprint.partecipants).forEach( ([i, p]) => {
                    if( p.teamId === par.teamId && par.raceNumber === p.raceNumber) {
                        sprintdriver = sprint.classificationData[i];
                        pstrint = sprintdriver.points;
                    }
                });
            } else {
                sprintdriver = sprint.classificationData[item.driver];
                pstrint = sprintdriver.points;
            }
        }
        if( props.points) {
            const flap = props.race.classificationData.filter( r => r.bestLapTime > 0).reduce( (a, b) => Math.min(a, b.bestLapTime), props.race.classificationData[0].bestLapTime);
            return pointsForRace( item, props.points, flap, sprint ? sprintdriver: null);
        } else {
          return (item.points || 0) + pstrint;
        }
    }

    const lblPoints = ( p, item) => {
        let ret = String(p);
        if( item.resultStatus > 3 && p === 0) {
            ret = "DNF";
        }
        const flap = props.race.classificationData.filter( r => r.bestLapTime > 0).reduce( (a, b) => Math.min(a, b.bestLapTime), props.race.classificationData[0].bestLapTime);
        if( item.bestLapTime === flap) {
            ret = <b>{ret}</b>;
        }
        if( item.gridPosition === 1) {
            ret = <u>{ret}</u>
        }
        return ret;
    }

    const displayDriver = item => {
        const drv = props.race.partecipants[item.driver];
        if (item.allowedit) {
            const newdriver = driverFormat( drv, true)
            return <Form.Select defaultValue={drv} size='sm' id={`driver-${item.driver}`}>
                <option value={newdriver}>{newdriver} (new)</option>
                {notFoundDrivers.map( drv => {
                    return <option value={drv.label} key={`driver-${item.driver}-${drv.label}`}>{drv.label}</option>
                })}
             </Form.Select>
        } else {
            return  driverFormat( drv, false);
        }
    }
    const onEnterModal = () => {
        getCalendarData( db, props.championshipid).then( dataModel => {
            const ret = calculateClassificationFromCalendar( dataModel, [props.calendarid]);
            setDriverStanding( ret[0]);
            const editingData = dataModel.filter( i => i.id === props.calendarid)[0];
            const data = props.race.classificationData.map((item,driver) => { item.driver = driver; return item;})
                    .filter( r => r.resultStatus > 1)
                    .sort((a, b) => a.position - b.position)
                    .map( item => {
                        const dvrlbl = driverFormat(props.race.partecipants[item.driver], true);
                        const drvId = driverIdFromLabel(dvrlbl);
                        const found =  ret[0].filter( ch => driverIdFromLabel(ch.label) === drvId);
                        let reserve = false;
                        let championship = "-";
                        let matchd = found.length && found[0].label;
                        let newtot = 0;
                        let teamId = props.race.partecipants[item.driver].teamId;
                        let allowedit = false;
                        if(matchd) {
                            reserve = found[0].reserve;
                            championship = found[0].tot;
                            newtot = championship;
                        } else {
                            allowedit = ret[0].length > 0; // first race I don' allow to change the driver
                        }
                        if(editingData && editingData.results) { // it's editing a previous one
                            const lastdata =  editingData.results.filter( ch => ch.label === dvrlbl);
                            if(lastdata.length) {
                                reserve = lastdata[0].reserve;
                                teamId = lastdata[0].teamId;
                            }
                        }
                        const p = getPoints( item, props.sprint);
                        newtot += p;
                        const constr = lblPoints(p, item);
                        const row = {
                            "position" : item.position,
                            "label" : dvrlbl,
                            "driverId" : drvId,
                            "driver" : item.driver,
                            "points" : constr,
                            "construtorPoints" : constr,
                            teamId,
                            newtot,
                            championship,
                            reserve,
                            matchd,
                            allowedit
                        };
                        return row;
                    });
            setStanding(data);
            let notassigned = [];
            ret[0].forEach(item => {
                const drvId = driverIdFromLabel(item.label);
                const found =  data.filter( ch => drvId === ch.driverId);
                if( found.length === 0) {
                    notassigned.push(item);
                }
            });
            setNotFoundDrivers(notassigned);
        });
    }

    return (        
        <Modal size="xl" onHide={hideModal} centered {...props} onEnter={onEnterModal} >
          <Form onSubmit={onFormSave}>
          <Modal.Header closeButton>
            <Modal.Title>Championship Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped hover size="sm">
                <thead>
                        <tr>
                        <th className='smallFont'>P</th>
                        <th className='smallFont'>Driver</th>
                        <th className='smallFont'></th>
                        <th className='centerTxt'>
                            <OverlayTrigger
                                placement='auto'
                                overlay={
                                    <Tooltip>
                                    <b>Bold</b>: fastest lap<br/>
                                    <u>Underline</u>: pole position
                                    </Tooltip>
                                }
                                >
                                <Button size='sm' className='smallFont' variant="link" style={{padding:0,height:18}} >Race</Button>
                                </OverlayTrigger>
                        </th>
                        <th className='smallFont'>Constr.</th>
                        <th className='smallFont'></th>
                        </tr>
                </thead>
                <tbody>
                {props.race && standing.map((item) =>{
                    return <tr key={`sss-${item.driver}`}>
                     <td>{item.position}</td>
                     <td>{displayDriver(item)}</td>
                     <td><TeamSelect selected={item.teamId} selectid={`team-${item.driver}`} /></td>
                     <td className='centerTxt'>{item.points}</td>
                     <td className='centerTxt' id={`construtor-${item.driver}`}>
                            {item.construtorPoints}
                     </td>
                     <td>
                     <Form.Select defaultValue={item.reserve ? "3" : "0"} size='sm' onChange={(e) => onWcSelect( e, item)} id={`action-${item.driver}`}>
                            <option value={0}></option>
                            <option value={3}>Reserve</option>
                            <option value={1}>Remove</option>
                    </Form.Select>
                     </td>
                   </tr>
                })}
                </tbody>
            </Table>
            <PointSystemComponent points={props.points} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => hideModal()}>
            Cancel
            </Button>
            <Button variant="primary" 
                disabled={isLoading}
                type="submit"
            >
            {isLoading ? 'Saving…' : 'Assign points'}
            </Button>
            <Form.Switch id="provisional" name="provisional" label="Provisional" />

          </Modal.Footer>
          </Form>
        </Modal>
    );
  }
