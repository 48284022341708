import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import StandingTopBanner from "./StandingTopBanner";
import { TRACK_SHORT , TRACK , FORCE_DRIVER_STATUS } from 'racemapper_shared/enums';
import { driverIdFromLabel , setResultForRace, labelTeamWithDriver } from 'racemapper_shared/data';
import { isLeagueAdmin } from '../helpers/auth';
import { db } from '../services/firebase';


export default function ChampionshipStandingDriver ( props) {  
    
    const [showStandingTopBanner, setShowStandingTopBanner] = React.useState(false);

    const openTopBanner = () => {
        setShowStandingTopBanner(true);
    }

    const updateStatus = (item, reserve, status) => {
        const withresults = props.calendar.filter( (cal) => {
            if( !cal.results) return false;
           
            const found =  cal.results.filter( res => res.label === item.label);
            return found.length;
        });

        const last = withresults[withresults.length - 1];
        last.results.map( (res,i) => {
            if( res.label === item.label) {
                if( reserve !== null) {
                    last.results[i].reserve = reserve;
                }
                if( status !== null) {
                    last.results[i].force = status;
                }
            }
        });
        const provisional = last.results_provisional ?? false;
        setResultForRace( db, last.results, last.id, provisional);
    }

    const roundLbl = (hint, item) => {
        let lbl = TRACK_SHORT[item.trackId];
        let retvalue = null;
        if( item.results_provisional) {
            lbl += "*";
        }
        if( item.telemetry.length > 0) {
            retvalue = <Button variant='link' size='sm' style={{padding:0, fontSize:"11px"}} as={Link} to={`/session/${item.telemetry}`}>{lbl}</Button>
        } else {
            retvalue = <Button variant='link' size='sm' style={{padding:0, fontSize:"11px"}} onClick={()=>{props.onSelectedRace(item)}}>{lbl}</Button>
        }
        
        return <OverlayTrigger overlay={<Tooltip>{hint}</Tooltip>}>{retvalue}</OverlayTrigger>
    }

    const gapBetween = ( arr, to, from) => {
        if( from === 0) {
            return "";
        }
        return arr[from].tot - arr[to].tot;
    }

    const lblTeam = (item) => {
        let valueAndColor = labelTeamWithDriver(item);
        if( valueAndColor.color) {
            return <span style={{color:valueAndColor.color}}>{valueAndColor.value}</span>
        }
        return valueAndColor.value;
    }

    const showPoints = (dchamp) => {
        return props.calendar.map( (race, i) => {
            let lbl = "";
            let style = {};
            if(race.results) {
                const did = driverIdFromLabel(dchamp.label);
                const d = race.results.filter( item => {
                    const driverId = driverIdFromLabel(item.label);
                    return did === driverId || dchamp.aliases.includes(driverId);
                });
                if( d.length > 0) {
                    const drv = d[0];
                    if( drv.resultStatus === 5) {
                        lbl = "BAN";
                    } else if( drv.resultStatus > 3 && !drv.points) {
                        lbl = "DNF";
                        lbl = <span style={{color:"orange"}}>{lbl}</span>;
                    } else {
                        let fntcolor = "#000"
                        const clrs = ["#ffd700","#c0c0c0","#cd7f32"]
                        if(drv.position <= 3) {
                            fntcolor = clrs[drv.position-1];
                            style = {backgroundColor:fntcolor};
                        }
                        lbl = String(drv.points);
                        if(drv.poleposition) {
                            lbl = <u>{lbl}</u>;
                        }
                        if(drv.fastestlap) {
                            lbl = <b>{lbl}</b>;
                        }
                    }
                    
                } else {
                    lbl = "DNS";
                }
            }
            return <td className='centerTxt' key={`dtd-${i}-${race.id}`} style={style}>{lbl}</td>    
        });
    }

    return (        
        <>
        <ButtonGroup size="sm" style={{float:"right"}}>
            {props.championship && props.championship.banner_drivers &&
            <Button key="banner-dl" variant="outline-dark" href={props.championship.banner_drivers} target="_blank">
                <img alt="fs" src="/static/file-image.svg" style={{width: 20}} />
            </Button>
            }
        </ButtonGroup>
        <Table striped hover size="sm">
        <thead>
            <tr>
                <th colSpan={4}></th>
            {props.calendar && props.calendar.length > 0 && props.calendar.map( (item,i) => {
                return <th key={`dh-${item.id}`} className='centerTxt'>{roundLbl( "R" + (i+1), item)}</th>
            })}
                <th colSpan={2}></th>
            </tr>
            <tr>
                <th className='smallFont'>P</th>
                <th className='smallFont'>DRIVER</th>
                <th className='smallFont'>TEAM</th>
                <th className='smallFont centerTxt'>PTS</th>
            {props.calendar && props.calendar.length > 0 && props.calendar.map( (item) => {
                return <th key={`drv-${item.id}`} className='centerTxt'>
                    <img className='flag-icon-small' src={`${process.env.PUBLIC_URL}/static/flags/${item.trackId}.png`} alt={TRACK[item.trackId]} />
                </th>
            })}
                <th className='smallFont'>+-</th>
                <th className='smallFont'>1st</th>
            </tr>
        </thead>
        <tbody>
            {props.driverStandings && props.driverStandings.length >0 ? 
                props.driverStandings.map( (item,i) => {
                return <tr key={`driver-${i}`}>
                    <td>{i+1}</td>
                    <td>{item.label}</td>
                    <td className='inline-block'>{lblTeam(item)}</td>
                    <td className="centerTxt" style={{borderWidth:"1px",borderColor:"#999"}}>{item.tot}</td>
                    {showPoints(item)}
                    <td className='centerTxt'>{gapBetween( props.driverStandings, i-1, i)}</td>
                    <td className='centerTxt'>{gapBetween( props.driverStandings, 0, i)}
                    {isLeagueAdmin( props.league) &&
                        <DropdownButton style={{float:"right"}} as={ButtonGroup} variant='link' size='sm' title="..." id={`dropdown-${item.id}`}>
                            <Dropdown.Item as={Button} onClick={() => updateStatus(item, true, null)}>Reserve</Dropdown.Item>
                            <Dropdown.Item as={Button} onClick={() => updateStatus(item, null, FORCE_DRIVER_STATUS.left)}>Left</Dropdown.Item>
                            <Dropdown.Item as={Button} onClick={() => updateStatus(item, null, FORCE_DRIVER_STATUS.promoted)}>Promoted</Dropdown.Item>
                            <Dropdown.Item as={Button} onClick={() => updateStatus(item, null, FORCE_DRIVER_STATUS.demoted)}>Demoted</Dropdown.Item>
                            <Dropdown.Item as={Button} onClick={() => updateStatus(item, null, FORCE_DRIVER_STATUS.banned)}>Banned</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as={Button} onClick={() => updateStatus(item, false, FORCE_DRIVER_STATUS.ok)}>Regular driver</Dropdown.Item>

                        </DropdownButton>
                    }
                    </td>
                </tr>
            })
            :
            <tr key={"nodata"}><td colSpan={30}>
            <Alert key="nodata" variant="light">
                    No data yet
            </Alert>
            </td></tr>
        }
        </tbody>
        </Table>
        <div><b>*</b> provisional result | <u> Pole position</u> | <b>fastestlap</b></div>
        <br />
        {isLeagueAdmin( props.league) && props.driverStandings && props.driverStandings.length >0 &&
        <>
            <ButtonGroup>
                <Button variant='outline-primary' onClick={()=> openTopBanner() }>Top Drivers Banner</Button>
            </ButtonGroup>
            <StandingTopBanner show={showStandingTopBanner} leagueid={props.league.id} championship={props.championship} config={props.championship.topbanner_config} onHide={()=>{setShowStandingTopBanner(false)}} />
        </>
        }
        </>
    );
}
