const {TEAM_F1,TEAM} = require('./enums');
const {FORCE_DRIVER_STATUS} = require('./enums');

exports.COLLECTION_ID = "F1-2022";
exports.STATSLAPS_COLLECTION = "F1-2022_Laps";
exports.SESSIONS_COLLECTION = 'sessions';
exports.LAPS_COLLECTION = 'laps';
exports.ORG_COLLECTION = 'organizations';
exports.CHAMP_COLLECTION = 'championships';
exports.LOGS_COLLECTION = 'logs';
exports.SPEEDTRAP_COLLECTION = 'speedtrap';
exports.STEWARDS_COLLECTION = 'stewards';
exports.CALENDAR_COLLECTION = 'calendar';


exports.getTelemetrySessions = async ( db, orgID, championshipID) => {
    const data = await db.collection(this.COLLECTION_ID).where( "championshipId" , "==", championshipID).where("organisationId", "==", orgID).orderBy("created","desc").get();
    return data.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getTelemetrySessionsNoCaledar = async ( db, orgID, limit = 10, after = null) => {
    let data = null;
    if( after === null) {
        data = await db.collection(this.COLLECTION_ID)
            .where( "calendarId", "==", "" )
            .where( "organisationId" , "==", orgID)
            .orderBy("created","desc")
            .limit(limit)
            .get();
    } else {
        data = await db.collection(this.COLLECTION_ID)
            .where( "calendarId", "==", "" )
            .where( "organisationId" , "==", orgID)
            .orderBy("created","desc")
            .startAfter(after)
            .limit(limit)
            .get();
    }
    return data.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getOrganizationsData = async( db ) => {
    const organizations = await db.collection(this.ORG_COLLECTION).where("enabled", "==", true).get();
    return organizations.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getOrganizationDetailData = async (db,orgID) => {
    const sessionRef = await db.collection(this.ORG_COLLECTION).doc(orgID).get();
    let res = sessionRef.data();
    res.id = orgID;
    return res;
}

exports.getChampionshipsData = async (db,orgID) => {
    const champs = await db.collection(this.ORG_COLLECTION).doc(orgID).collection(this.CHAMP_COLLECTION).where("game","==", this.COLLECTION_ID).where("private","==", false).orderBy("label").get();
    return champs.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getChampionshipDetailData = async ( db,orgID, champID) => {
    const sessionRef = await db.collection(this.ORG_COLLECTION).doc(orgID).collection(this.CHAMP_COLLECTION).doc(champID).get();
    let res = sessionRef.data();
    res.id = champID;
    return res;
}

exports.addCalendarData = ( db,date, trackId, organisationId, organisationLabel, championshipId, championshipLabel, telemetry, format) => {
    return db.collection(this.CALENDAR_COLLECTION).add(
        {
            date,
            trackId: parseInt(trackId),
            organisationId,
            championshipId,
            organisationLabel,
            championshipLabel,
            telemetry,
            format,
            game: this.COLLECTION_ID
        }
    );
}

exports.editCalendarData = async ( db,calendarObj, oldAssociation) => {
    if( calendarObj.telemetry) {
         const refsession = db.collection(this.COLLECTION_ID).doc(calendarObj.telemetry);
         refsession.set({
             "format": calendarObj.format,
             "briefing": calendarObj.briefing,
             "calendarId": calendarObj.id
         }, { merge: true });
    }
    
    if( oldAssociation) {
        const refsessionold = db.collection(this.COLLECTION_ID).doc(oldAssociation);
        refsessionold.set({
            "format": "",
            "briefing": "",
            "calendarId": ""
        }, { merge: true });
    }
    
    const ref = db.collection(this.CALENDAR_COLLECTION).doc(calendarObj.id);
    return ref.set(
        {
            "date": calendarObj.date,
            "trackId": parseInt(calendarObj.trackId),
            "telemetry": calendarObj.telemetry,
            "format": calendarObj.format,
            "championshipLabel": calendarObj.championshipLabel
        },{ merge: true }
    );
}

exports.setResultForRace = async ( db, result, calendarID, provisional) => {
    const ref = db.collection(this.CALENDAR_COLLECTION).doc(calendarID);
    return ref.set({"results": result, "results_provisional":provisional},{ merge: true });
}

exports.getCalendarData = async ( db,championshipID) => {
    const data = await db.collection(this.CALENDAR_COLLECTION).where( "championshipId" , "==", championshipID).orderBy("date").get();
    return data.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getCalendarByOrganisation = async ( db,organisationId) => {
    const data = await db.collection(this.CALENDAR_COLLECTION).where( "organisationId" , "==", organisationId).where("game", "==", this.COLLECTION_ID).orderBy("date").get();
    return data.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getCalendarByOrganisation = async ( db,organisationId, type = "all", limit = 999) => {
    let qref =  null;
    if( type === "prev") {
        qref = db.collection(this.CALENDAR_COLLECTION)
        .where( "organisationId" , "==", organisationId)
        .where("game", "==", this.COLLECTION_ID)
        .where("date", "<", new Date())
        .orderBy("date", "desc")
        .limit(limit);
    } else if( type === "next") {
        qref = db.collection(this.CALENDAR_COLLECTION)
                .where( "organisationId" , "==", organisationId)
                .where("game", "==", this.COLLECTION_ID)
                .where("date", ">=", new Date())
                .orderBy("date")
                .limit(limit);
    } else {
        qref = db.collection(this.CALENDAR_COLLECTION)
                .where( "organisationId" , "==", organisationId)
                .where("game", "==", this.COLLECTION_ID)
                .orderBy("date")
                .limit(limit);
    }
    const data = await qref.get();
    return data.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getCalendarDetailData = async ( db,calendarID) => {
    const sessionRef = await db.collection(this.CALENDAR_COLLECTION).doc(calendarID).get();
    let res = sessionRef.data();
    res.id = calendarID;
    return res;
}

exports.deleteCalendarData = async ( db, calendar) => {
    if( calendar.telemetry) {
        const refsessionold = db.collection(this.COLLECTION_ID).doc(calendar.telemetry);
        refsessionold.set({
            "calendarId": ""
        }, { merge: true });
    }
    return db.collection(this.CALENDAR_COLLECTION).doc(calendar.id).delete();
}

exports.getSessionData = async ( db,sessionID) => {
    const sessionRef = await db.collection(this.COLLECTION_ID).doc(sessionID).get();
    let res = sessionRef.data();
    res.id = sessionID;
    return res;
}

exports.deleteSessionData = async ( db,session) => {
    if ( session.calendarId) {
        const calendRef = db.collection(this.CALENDAR_COLLECTION).doc(session.calendarId)
        calendRef.set({
            "telemetry": ""
        }, { merge: true });
    }
    return await db.collection(this.COLLECTION_ID).doc(session.id).delete();
}

exports.deleteRaceData = async ( db,sessionID, raceID) => {
    return await db.collection(this.COLLECTION_ID).doc(sessionID).collection(this.SESSIONS_COLLECTION).doc(raceID).delete();
}

exports.getLapStats = async ( db,lapID) => {
    const sessionRef = await db.collection(this.STATSLAPS_COLLECTION).doc(lapID).get()
    let res = sessionRef.data();
    res.id = lapID;
    return res;
}

exports.getLapsForSession = async ( db,sessionID) => {
    const sessionRef = await db.collection(this.STATSLAPS_COLLECTION).where("sessionUID","==", sessionID).get();
    return sessionRef.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.getDBLaps = async ( db,sessionID, raceID, drivers) => {
    try {
        let lapsref = db.collection(this.COLLECTION_ID).doc(sessionID).collection(this.SESSIONS_COLLECTION).doc(raceID).collection(this.LAPS_COLLECTION).orderBy("lap").where("vehicleIdx","==",drivers[0]);
        const data = await lapsref.get();
        return data;
      /* 
        const lapsref = db.collection(F1_COLLECTION).doc(sessionID).collection(SESSIONS_COLLECTION).doc(raceID).collection(LAPS_COLLECTION).orderBy("lap")

        if(drivers.length == 1) {
            lapsref.where("vehicleIdx","==",drivers[0]);
        } else {
            lapsref.where("vehicleIdx","in",drivers);
        }        
        return lapsref.get();*/
    } catch( error) {
        console.log(error);
        return null;
    }
}

exports.saveStewardData = async ( db,sessionID, raceID, vehicleIdx, reportedby, seconds, license_points, url, desc, created) => {
    const ref = db.collection(this.COLLECTION_ID).doc(sessionID).collection(this.SESSIONS_COLLECTION).doc(raceID).collection(this.STEWARDS_COLLECTION);
    return ref.doc().set( {
        "vehicleId": vehicleIdx,
        "reportedbyVehicleId": reportedby,
        "seconds": seconds,
        "url": url,
        "description" : desc,
        "license_points" : license_points,
        "created": created
    });
}

exports.setRaceRefreshNeeded = async ( db,sessionID, raceID) => {
    const ref = db.collection(this.COLLECTION_ID).doc(sessionID).collection(this.SESSIONS_COLLECTION).doc(raceID);
    return ref.set({classificationRefresh: true}, { merge: true });
}

exports.delteStewardData = async ( db,sessionID, raceID, reportID) => {
    return await db.collection(this.COLLECTION_ID).doc(sessionID).collection(this.SESSIONS_COLLECTION).doc(raceID).collection(this.STEWARDS_COLLECTION).doc(reportID).delete();
}

exports.getStewardData = async ( db,sessionID, raceID) => {
    const ref = await db.collection(this.COLLECTION_ID).doc(sessionID).collection(this.SESSIONS_COLLECTION).doc(raceID).collection(this.STEWARDS_COLLECTION).orderBy("created").get();
    return ref.docs.map( doc => ({id:doc.id, ...doc.data()}));
}

exports.sessionFastestData = doc => {
    let flap = doc.fastestlap && doc.fastestlap.lapTime ? doc.fastestlap.lapTime : Number.MAX_SAFE_INTEGER;

    if( doc.classificationData) {
        doc.classificationData.map( lap => {
            if(  lap.bestLapTime > 0 && flap > lap.bestLapTime) {
                flap = lap.bestLapTime;
            }
            return "";
        });
    }
    const fastest = { 'lapTime' : flap, 's1':Number.MAX_SAFE_INTEGER, 's2':Number.MAX_SAFE_INTEGER, 's3':Number.MAX_SAFE_INTEGER};

    doc.lapData.map( lap => {
        if( lap.bestLapSector1TimeInMS !== 0 && fastest.s1 > lap.bestLapSector1TimeInMS) {
            fastest.s1 = lap.bestLapSector1TimeInMS;
        }
        if( lap.bestLapSector2TimeInMS !== 0 && fastest.s2 > lap.bestLapSector2TimeInMS) {
            fastest.s2 = lap.bestLapSector2TimeInMS;
        }
        if( lap.bestLapSector3TimeInMS !== 0 && fastest.s3 > lap.bestLapSector3TimeInMS) {
            fastest.s3 = lap.bestLapSector3TimeInMS;
        }
        if( lap.bestLapTime !== 0 && fastest.lapTime > lap.bestLapTime) {
            fastest.lapTime = lap.bestLapTime;
        }
        return lap;
    });

    return fastest;
}

exports.lapsForModal = ( driver, data, speedtrapAlldata, maxspeed) => {
    let f = 0;
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    const arrayData = data.docs.map( doc => {
        let row = {id:doc.id, ...doc.data()};
        if( row.invalid === 0) {
            if( s1 === 0 || row.sector1TimeInMS < s1) s1 = row.sector1TimeInMS;
            if( s2 === 0 || row.sector2TimeInMS < s2) s2 = row.sector2TimeInMS;
            if( s3 === 0|| row.sector3TimeInMS < s3) s3 = row.sector3TimeInMS;
            if( f === 0 || row.lapTime < f) f = row.lapTime;
        }
        const st = speedtrapAlldata.find(i => i.vehicleIdx === driver && i.lapNum === row.lap);
        if( st) {
            row['speed'] = st.speed;
        }
        return row;
    }); 

    let objLaps = {
        "laps": arrayData,
        "sector1TimeInMS": s1,
        "sector2TimeInMS": s2,
        "sector3TimeInMS": s3,
        "fastest": f,
        "teoretically": s1+s2+s3
    }

    if( maxspeed) {
        objLaps["speed"] = maxspeed.speed;
    }
    return objLaps;
}

exports.updatePartecipantsMapper = ( db, sessionID, partecipantsMapper) => {
    const ref = db.collection(this.COLLECTION_ID).doc(sessionID);
    ref.set({
        partecipants_refreshed: true,
        partecipants: partecipantsMapper
    }, { merge: true });
}

exports.filterWeatherForecast = weatherForecastSamples => {
    let lastW = {};
    return weatherForecastSamples.filter(  t => {
            const ok =  t.sessionType !== 0 
                        && (lastW.rainPercentage !== t.rainPercentage 
                        || lastW.sessionType !== t.sessionType
                        || lastW.weather !== t.weather
                        || lastW.airTemperature !== t.airTemperature
                        || lastW.trackTemperature !== t.trackTemperature)
            lastW = t;
            return ok
            }
        )
}

exports.mapDriversLabel = (racePartecipants, partecipantsMapper) => {
    // name mapping
    //console.log( typeof( racePartecipants));
    if( !partecipantsMapper) {
        partecipantsMapper = [];
    }
    if( typeof( racePartecipants) === "object") {
        for (let [_,item] of Object.entries(racePartecipants)) {
            let matched = partecipantsMapper.filter( p => {
                return item.networkId && (item.networkId === p.networkId && item.teamId === p.teamId);
            });

            if( (!matched || matched.length === 0) && this.hasDefaultPlayerName(item.name)) {
                matched = partecipantsMapper.filter( p => {
                    return item.raceNumber === p.number && item.teamId === p.teamId;
                });
            }
            if( matched[0]) {
                item.label = matched[0].label;
            } else {
                item.label = item.name;
                if( this.hasDefaultPlayerName( item.label)) {
                    item.label +=  " #" + item.raceNumber;
                }
            }
        }
    } else {
        for (let item of racePartecipants) {
            let matched = partecipantsMapper.filter( p => {
                return item.networkId && (item.networkId === p.networkId && item.teamId === p.teamId);
            });

            if( (!matched || matched.length === 0) && this.hasDefaultPlayerName(item.label)) {
                matched = partecipantsMapper.filter( p => {
                    return item.raceNumber === p.number && item.teamId === p.teamId;
                });
            }
            if( matched[0]) {
                item.label = matched[0].label;
            } else {
                item.label = item.name;
                if( this.hasDefaultPlayerName( item.label)) {
                    item.label +=  " #" + item.raceNumber;
                }
            }
        }
    }
}

exports.pointsForRace = (classificationItem, pointSystem, fastest, sprint) => {
    let points = classificationItem.points || 0;
    if( pointSystem.classification){
        if( classificationItem.position <= pointSystem.classification.length) {
            points = pointSystem.classification[classificationItem.position-1];
        }
    } else {
        points = classificationItem.points || 0;
    }
    if( pointSystem.poleposition) {
        if( (!sprint && classificationItem.gridPosition === 1)
            || ( sprint && sprint.gridPosition === 1)) {
            points += pointSystem.poleposition;
        }
    }
    if( pointSystem.fastestlap 
        && classificationItem.bestLapTime === fastest
        && pointSystem.fastestlapPositionLimit >= classificationItem.position) {
            points += pointSystem.fastestlap;
    }
    if( sprint) {
        points += sprint.points || 0;
    }
    return points;
}


exports.hasDefaultPlayerName = name => {
    const defaultPlayersLbl = ['Player', 'Giocatore', 'Joueur','Spieler'];
    return defaultPlayersLbl.includes(name);
}

exports.driverIdFromLabel = ( drvlbl) => {
    return drvlbl.trim().toLowerCase();
}

exports.calculateClassificationFromCalendar = ( calendar, skipRaces) => {
    let driverClass = {};
    let construtorClass = [];

    Object.values(TEAM_F1).forEach( (_, teamId) => {
        construtorClass[teamId] = { tot:0, teamId: teamId};
    });
    construtorClass[255] = { tot:0, teamId: 255};

    calendar.forEach( item => {
        const skip = skipRaces && skipRaces.includes( item.id);
        if( item.results && !skip) {
            item.results.forEach( res => {
                construtorClass[res.teamId].tot += res.teamPoints;
                const driverID = this.driverIdFromLabel(res.label);
                if( res.oldname) {
                    const olddriverID = this.driverIdFromLabel( res.oldname);
                    let cpy = driverClass[olddriverID];
                    if( cpy) {
                        cpy.label = res.label;
                        cpy.aliases.push( olddriverID);
                        driverClass[driverID] = cpy;
                        delete driverClass[olddriverID];
                    } else {
                       // console.log( res.label);
                    }
                } else if( !driverClass[driverID]) {
                    driverClass[driverID] = {label: res.label, tot:0, teamId: 0, reserve: false, aliases: [], force: null};
                }
                driverClass[driverID].tot += res.points;
                driverClass[driverID].teamId = res.teamId;
                driverClass[driverID].reserve = res.reserve;
                driverClass[driverID].force = res.force;
            });
        }
    });

    construtorClass.sort( (a,b) => {
        return b.tot - a.tot;
    });

    driverClass =  Object.values(driverClass);
    driverClass.sort( (a,b) => {
        if( a.tot === b.tot) {
            return a.reserve;
        }
        return  b.tot - a.tot;
    });
    
    return [driverClass, construtorClass];
}

exports.labelTeamWithDriver = (item) => {
    let lbl = TEAM[item.teamId];
    let color = "";
    if( item.force) {
        switch ( item.force) {
            case FORCE_DRIVER_STATUS.left: {
                lbl = "Left";
                color= "red";
            } break; 
            case FORCE_DRIVER_STATUS.demoted: {
                lbl = "Demoted";
                color= "blue";
            } break; 
            case FORCE_DRIVER_STATUS.promoted: {
                lbl = "Promoted";
                color= "blue";
            } break; 
            case FORCE_DRIVER_STATUS.banned: {
                lbl = "Banned";
                color= "red";
            } break; 
            default:break;
        }
    } 
    
    if( item.reserve) {
        lbl = "Reserve";
        color = "green";
    }
    let rtn = {value: lbl};
    if( color) {
        rtn["color"] = color;
    }
    return rtn;
}