import React from 'react';
import { TEAM_F1 , TEAM } from 'racemapper_shared/enums';

import Form from 'react-bootstrap/Form';

export default function TeamSelect ( props) {  

    return(
        <Form.Select defaultValue={props.selected} size='sm' id={props.selectid}>
            {props.empty && 
            <option></option>
            }
            {TEAM_F1.map( (lbl, id) => {
                return <option value={id} key={`team-${id}`}>{lbl}</option>
            })}
            <option value={255} key={`team-255`}>{TEAM[255]}</option>
            
        </Form.Select>
    );
  }